import {
  HttpRequestAndResponseLog,
  RestApiPaginationResultHttpRequestAndResponseLog,
  RestApiPaginationResultWebhookLog,
  RestApiPaginationResultWebhookSubscription,
  WebhookSubscription,
} from '@flipdish/api-client-typescript';
import moment from 'moment';

import { action } from '../../../actions/utils';
import { notify, notifyError } from '../../../layouts/Notify/actions';
import { developersService } from '../../../services/developers.service';

// Region webhookError
export const RESET_WEBHOOK_ERROR = 'RESET_WEBHOOK_ERROR';
export function resetWebhookError() {
  return async (dispatch: ThunkDispatch) => {
    dispatch(action(RESET_WEBHOOK_ERROR));
  };
}

// Region webhookLogs
export const GET_WEBHOOK_LOGS_REQUEST = 'GET_WEBHOOK_LOGS_REQUEST';
export const GET_WEBHOOK_LOGS_SUCCESS = 'GET_WEBHOOK_LOGS_SUCCESS';
export const GET_WEBHOOK_LOGS_FAILURE = 'GET_WEBHOOK_LOGS_FAILURE';

export type getWebhookLogsRequest = ReturnType<typeof getWebhookLogsRequest>;
export const getWebhookLogsRequest = () => action(GET_WEBHOOK_LOGS_REQUEST);

export type GetWehookLogsSuccess = ReturnType<typeof getWebhookLogsSuccess>;
export const getWebhookLogsSuccess = (data: RestApiPaginationResultWebhookLog) =>
  action(GET_WEBHOOK_LOGS_SUCCESS, data);

export type GetWehookLogsFailure = ReturnType<typeof getWebhookLogsFailure>;
export const getWebhookLogsFailure = (error: Error) => action(GET_WEBHOOK_LOGS_FAILURE, error);

export function getWebhookLogs(
  oauthAppId: string,
  webhookSubscriptionId: number,
  start: string,
  end: string,
  page?: number,
  limit?: number
) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(getWebhookLogsRequest());
      const data = await developersService.getWebhookLogs(
        oauthAppId,
        webhookSubscriptionId,
        start,
        end,
        appId,
        page,
        limit
      );
      dispatch(getWebhookLogsSuccess(data));
    } catch (error) {
      dispatch(getWebhookLogsFailure(error));
    }
  };
}
// End region

// Region webhooks
export const GET_OAUTHAPP_WEBHOOKS_REQUEST = 'GET_OAUTHAPP_WEBHOOKS_REQUEST';
export const GET_OAUTHAPP_WEBHOOKS_SUCCESS = 'GET_OAUTHAPP_WEBHOOKS_SUCCESS';
export const GET_OAUTHAPP_WEBHOOKS_FAILURE = 'GET_OAUTHAPP_WEBHOOKS_FAILURE';

export type GetOAuthAppWevhooksRequest = ReturnType<typeof getOAuthAppWebhooksRequest>;
export const getOAuthAppWebhooksRequest = () => action(GET_OAUTHAPP_WEBHOOKS_REQUEST);

export type GetOAuthAppWebhooksSuccess = ReturnType<typeof getOAuthAppWebhooksSuccess>;
export const getOAuthAppWebhooksSuccess = (data: RestApiPaginationResultWebhookSubscription) =>
  action(GET_OAUTHAPP_WEBHOOKS_SUCCESS, data);

export type GetOAuthAppWebhooksFailure = ReturnType<typeof getOAuthAppWebhooksFailure>;
export const getOAuthAppWebhooksFailure = (error: Error, clientId: string) =>
  action(GET_OAUTHAPP_WEBHOOKS_FAILURE, error, clientId);

export type GetWebhooksParams = {
  page: number;
  limit: number;
};

export function getWebhooks(oauthAppId: string, params: GetWebhooksParams) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(getOAuthAppWebhooksRequest());
      const result = await developersService.getWebhooks(
        oauthAppId,
        appId,
        params.page,
        params.limit
      );
      dispatch(getOAuthAppWebhooksSuccess(result));
    } catch (error) {
      dispatch(getOAuthAppWebhooksFailure(error, appId));
    }
  };
}
// End region

// Region createWebhook
export const CREATE_WEBHOOK = 'CREATE_WEBHOOK';
export const CREATE_WEBHOOK_REQUEST = CREATE_WEBHOOK + '_REQUEST';
export const CREATE_WEBHOOK_SUCCESS = CREATE_WEBHOOK + '_SUCCESS';
export const CREATE_WEBHOOK_FAILURE = CREATE_WEBHOOK + '_FAILURE';

export type CreateWebhookRequest = ReturnType<typeof createWebhookRequest>;
export const createWebhookRequest = () => action(CREATE_WEBHOOK_REQUEST);

export type CreateWebhookSuccess = ReturnType<typeof createWebhookSuccess>;
export const createWebhookSuccess = (data: WebhookSubscription) =>
  action(CREATE_WEBHOOK_SUCCESS, data);

export type CreateWebhookFailure = ReturnType<typeof createWebhookFailure>;
export const createWebhookFailure = (error: Error, clientId: string) =>
  action(CREATE_WEBHOOK_FAILURE, error, clientId);

export function createWebhook(clientId: string, webhook: WebhookSubscription) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    const accountId = getState().account.AccountId;
    try {
      dispatch(createWebhookRequest());
      const id = await developersService.createWebhook(clientId, webhook, appId);
      dispatch(
        createWebhookSuccess({
          Id: id,
          OwnerUserId: accountId,
          ...webhook,
        })
      );
      dispatch(notify({ message: 'Success', variant: 'success' }));
    } catch (error) {
      dispatch(createWebhookFailure(error, clientId));
    }
  };
}
// End region

// Region updateWebhook
export const UPDATE_WEBHOOK_REQUEST = 'UPDATE_WEBHOOK_REQUEST';
export const UPDATE_WEBHOOK_SUCCESS = 'UPDATE_WEBHOOK_SUCCESS';
export const UPDATE_WEBHOOK_FAILURE = 'UPDATE_WEBHOOK_FAILURE';

export type UpdateWebhookRequest = ReturnType<typeof updateWebhookRequest>;
export const updateWebhookRequest = () => action(UPDATE_WEBHOOK_REQUEST);

export type UpdateWebhookSuccess = ReturnType<typeof updateWebhookSuccess>;
export const updateWebhookSuccess = (data: WebhookSubscription) =>
  action(UPDATE_WEBHOOK_SUCCESS, data);

export type UpdateWebhookFailure = ReturnType<typeof updateWebhookFailure>;
export const updateWebhookFailure = (error: Error, clientId: string) =>
  action(UPDATE_WEBHOOK_FAILURE, error, clientId);

export function updateWebhook(clientId: string, webhook: WebhookSubscription) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(updateWebhookRequest());
      await developersService.updateWebhook(clientId, webhook.Id as number, webhook, appId);
      dispatch(updateWebhookSuccess(webhook));
      dispatch(notify({ message: 'Success', variant: 'success' }));
    } catch (error) {
      dispatch(updateWebhookFailure(error, clientId));
    }
  };
}
// End region

// Region deleteWebhook
export const DELETE_WEBHOOK = 'DELETE_WEBHOOK';
export const DELETE_WEBHOOK_REQUEST = DELETE_WEBHOOK + '_REQUEST';
export const DELETE_WEBHOOK_SUCCESS = DELETE_WEBHOOK + '_SUCCESS';
export const DELETE_WEBHOOK_FAILURE = DELETE_WEBHOOK + '_FAILURE';

export type DeleteWebhookRequest = ReturnType<typeof deleteWebhookRequest>;
export const deleteWebhookRequest = () => action(DELETE_WEBHOOK_REQUEST);

export type DeleteWebhookSuccess = ReturnType<typeof deleteWebhookSuccess>;
export const deleteWebhookSuccess = (id: number) => action(DELETE_WEBHOOK_SUCCESS, id);

export type DeleteWebhookFailure = ReturnType<typeof deleteWebhookFailure>;
export const deleteWebhookFailure = (error: Error, clientId: string) =>
  action(DELETE_WEBHOOK_FAILURE, error, clientId);

export function deleteWebhook(clientId: string, webhookId: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(deleteWebhookRequest());
      await developersService.deleteWebhook(clientId, webhookId, appId);
      dispatch(deleteWebhookSuccess(webhookId));
      dispatch(notify({ message: 'Success', variant: 'success' }));
    } catch (error) {
      dispatch(deleteWebhookFailure(error, clientId));
    }
  };
}
// End region

// Region getAllWebhookEventNames
export const GET_ALL_WEBHOOK_EVENTNAMES_REQUEST = 'GET_ALL_WEBHOOK_EVENTNAMES_REQUEST';
export const GET_ALL_WEBHOOK_EVENTNAMES_SUCCESS = 'GET_ALL_WEBHOOK_EVENTNAMES_SUCCESS';
export const GET_ALL_WEBHOOK_EVENTNAMES_FAILURE = 'GET_ALL_WEBHOOK_EVENTNAMES_FAILURE';

export type GetAllWebhookEventNamesRequest = ReturnType<typeof getAllWebhookEventNamesRequest>;
export const getAllWebhookEventNamesRequest = () => action(GET_ALL_WEBHOOK_EVENTNAMES_REQUEST);

export type GetAllWebhookEventNamesSuccess = ReturnType<typeof getAllWebhookEventNamesSuccess>;
export const getAllWebhookEventNamesSuccess = (eventNames: string[]) =>
  action(GET_ALL_WEBHOOK_EVENTNAMES_SUCCESS, eventNames);

export type GetAllWebhookEventNamesFailure = ReturnType<typeof getAllWebhookEventNamesFailure>;
export const getAllWebhookEventNamesFailure = (error: Error, clientId: string) =>
  action(GET_ALL_WEBHOOK_EVENTNAMES_FAILURE, error, clientId);

export function getAllWebhookEventNames() {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(getAllWebhookEventNamesRequest());
      const eventNames = await developersService.getAllWebhookEventNames(appId);
      dispatch(getAllWebhookEventNamesSuccess(eventNames));
    } catch (error) {
      dispatch(getAllWebhookEventNamesFailure(error, appId));
    }
  };
}
// End region

// Region createWebhookEventName
export const CREATE_WEBHOOK_EVENTNAME_REQUEST = 'CREATE_WEBHOOK_EVENTNAME_REQUEST';
export const CREATE_WEBHOOK_EVENTNAME_SUCCESS = 'CREATE_WEBHOOK_EVENTNAME_SUCCESS';
export const CREATE_WEBHOOK_EVENTNAME_FAILURE = 'CREATE_WEBHOOK_EVENTNAME_FAILURE';

export type CreateWebhookEventNameRequest = ReturnType<typeof createWebhookEventNameRequest>;
export const createWebhookEventNameRequest = () => action(CREATE_WEBHOOK_EVENTNAME_REQUEST);

export type CreateWebhookEventNameSuccess = ReturnType<typeof createWebhookEventNameSuccess>;
export const createWebhookEventNameSuccess = (id: number, name: string) =>
  action(CREATE_WEBHOOK_EVENTNAME_SUCCESS, { id, name });

export type CreateWebhookEventNameFailure = ReturnType<typeof createWebhookEventNameFailure>;
export const createWebhookEventNameFailure = (error: Error, clientId: string) =>
  action(CREATE_WEBHOOK_EVENTNAME_FAILURE, error, clientId);

export function createWebhookEventName(clientId: string, webhookId: number, eventName: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(createWebhookEventNameSuccess(webhookId, eventName));
      dispatch(createWebhookEventNameRequest());
      await developersService.createWebhookEventName(clientId, webhookId, eventName, appId);
      dispatch(notify({ message: 'Success', variant: 'success' }));
    } catch (error) {
      dispatch(notify({ message: 'Error', variant: 'error' }));
      dispatch(createWebhookEventNameFailure(error, clientId));
      dispatch(deleteWebhookEventNameSuccess(webhookId, eventName));
    }
  };
}
// End region

// Region deleteWebhookEventName
export const DELETE_WEBHOOK_EVENTNAME_REQUEST = 'DELETE_WEBHOOK_EVENTNAME_REQUEST';
export const DELETE_WEBHOOK_EVENTNAME_SUCCESS = 'DELETE_WEBHOOK_EVENTNAME_SUCCESS';
export const DELETE_WEBHOOK_EVENTNAME_FAILURE = 'DELETE_WEBHOOK_EVENTNAME_FAILURE';

export type DeleteWebhookEventNameRequest = ReturnType<typeof deleteWebhookEventNameRequest>;
export const deleteWebhookEventNameRequest = () => action(DELETE_WEBHOOK_EVENTNAME_REQUEST);

export type DeleteWebhookEventNameSuccess = ReturnType<typeof deleteWebhookEventNameSuccess>;
export const deleteWebhookEventNameSuccess = (id: number, name: string) =>
  action(DELETE_WEBHOOK_EVENTNAME_SUCCESS, { id, name });

export type DeleteWebhookEventNameFailure = ReturnType<typeof deleteWebhookEventNameFailure>;
export const deleteWebhookEventNameFailure = (error: Error, clientId: string) =>
  action(DELETE_WEBHOOK_EVENTNAME_FAILURE, error, clientId);

export function deleteWebhookEventName(clientId: string, webhookId: number, eventName: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(deleteWebhookEventNameRequest());
      dispatch(deleteWebhookEventNameSuccess(webhookId, eventName));
      await developersService.deleteWebhookEventName(clientId, webhookId, eventName, appId);
      dispatch(notify({ message: 'Success', variant: 'success' }));
    } catch (error) {
      dispatch(notify({ message: 'Error', variant: 'error' }));
      dispatch(deleteWebhookEventNameFailure(error, clientId));
      dispatch(createWebhookEventNameSuccess(webhookId, eventName));
    }
  };
}
// End region
