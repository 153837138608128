import React from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useQueryRMSCommonDataHook } from '@fd/customHooks/useQueryRMSCommonDataHook';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';

import EmptyComponent from '@fd/ui/EmptyComponent';

import NotFoundSvg from '../../../assets/images/illust_liveview_coffee_list.svg';
import { gdpr } from '../../../helpers/gdpr';
import { RMSModules } from '../utils/rmsModules';

const StyledIframe = styled('iframe')({
  width: '100%',
  height: '80vh',
  border: 'none',
});

const StyledLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

type RMSIframeProps = {
  module: RMSModules;
  url: string;
  includePropertyIdInUrl?: boolean;
  allowEmptyPropertiesInUrl?: boolean;
  storefrontId?: string;
  propertyIdfromUrl?: string;
} & MappedState;

const RMSIframe = ({
  url,
  module,
  sessionId,
  appId,
  propertyId,
  includePropertyIdInUrl = false,
  allowEmptyPropertiesInUrl = false,
  storefrontId = '',
  propertyIdfromUrl = '',
}: RMSIframeProps) => {
  const isProd = window?.location?.host?.includes('portal.flipdish.com');

  const backOfficeUrl = isProd
    ? 'https://rms.portal.flipdish.com'
    : 'https://rms.portal.flipdishdev.com';

  const {
    isLoading: commonDataLoading,
    error: commonDataError,
    data: commonData,
  } = useQueryRMSCommonDataHook(appId, true);

  const orgHierarchy = commonData?.associations?.orgHierarchy;
  const franchisorId = orgHierarchy?.franchisor?.id;

  if (commonDataError) {
    //@ts-ignore look into storefronts when doing this again
    const isUnAuthorized = commonDataError?.code === 401;

    const message = isUnAuthorized
      ? 'You are not authorized to load data from RMS'
      : 'An error occurred while loading data';

    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}
        data-fd="error-loading-iframe-data"
      >
        <Typography>{message}</Typography>
      </div>
    );
  }

  // we will always have gdpr selection as USA users get it set by default
  // edge case in which user hits url directy for first time loading Portal
  // if enabled for this route then loading and error states
  if (commonDataLoading || !gdpr.hasUserSelection()) {
    return (
      <StyledLoadingContainer data-fd="loading-iframe-data">
        <CircularProgress size={50} />
      </StyledLoadingContainer>
    );
  }

  if (!franchisorId) {
    //TODO: make this better
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}
        data-fd="no-franchisor-id"
      >
        No Franchisor ID found for appId {appId}!
      </div>
    );
  }

  if (!propertyId && includePropertyIdInUrl && !allowEmptyPropertiesInUrl) {
    return <EmptyComponent title="No_property_selected" icon={NotFoundSvg} noLink />;
  }

  const rumContext = datadogRum.getInternalContext();

  const rumSessionId = rumContext?.session_id || '';

  const queryParams = {
    franchisorId,
    sessionId,
    embedded: true.toString(),
  };

  if (rumSessionId) {
    // used for linking RUM sessions
    queryParams['rumSessionId'] = rumSessionId;
  }

  if ((propertyId && includePropertyIdInUrl) || propertyIdfromUrl) {
    queryParams['propertyId'] = propertyIdfromUrl || propertyId;
  }

  if (storefrontId) {
    queryParams['storefrontId'] = storefrontId;
  }

  const queryString = new URLSearchParams(queryParams).toString();

  const rmsUrl = `${backOfficeUrl}/${url}?${queryString}`;

  return (
    <StyledIframe
      id={`rms-iframe-${module}`}
      data-fd={`rms-iframe-${module}`}
      referrerPolicy="no-referrer"
      src={rmsUrl}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    sessionId: state.account?.SessionId || '',
    appId: state.currentApp?.AppId || '',
    propertyId: state.rms?.selectedProperty?.id,
  };
};

export default connect(mapStateToProps)(RMSIframe);
