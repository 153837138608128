import { useEffect } from 'react';

import Cookies from 'js-cookie';

import { accountActions } from '../actions/account.actions';
import { Amplitude } from '../services/amplitude/useTracking';
import { datadogRUM } from '../services/datadogRUM';
import { googleAnalytics } from '../services/google_analytics';
import { store } from './store';

export type CookiesPreferences = {
  advertising: boolean;
  strictly: boolean;
  performance: boolean;
  functional: boolean;
};

/**
 * Initializes scripts [googleAnalytics]
 * @param preferences
 */
function initialize(preferences: CookiesPreferences) {
  googleAnalytics.initialize(!preferences.advertising);
  datadogRUM.initialize(!preferences.performance);
  Amplitude.initialize(!preferences.performance);
  // facebook pixel tracking consent management
  //@ts-ignore
  if (window.fbq) {
    if (preferences.advertising) {
      //@ts-ignore
      window.fbq('consent', 'grant');
    } else {
      //@ts-ignore
      window.fbq('consent', 'revoke');
      Cookies.remove('_fbp');
    }
  }
}

function cookieDomain(location: Location): string {
  if (location.host.includes('portal.flipdish.com')) {
    return '.portal.flipdish.com'; // production
  }

  if (location.host.includes('portal.flipdishdev.com')) {
    return '.portal.flipdishdev.com'; // team
  }

  // account for localhost and other local hostnames
  return location.hostname;
}

function expirationDate(): Date {
  const date = new Date();
  date.setMonth(date.getMonth() + 6); // Expires in 6 months
  return date;
}

/**
 * Saves user selection to cookie and starts initializing scripts
 * @param preferences
 */
const setUserSelection = (preferences: CookiesPreferences, shouldLoad = true) => {
  const expires = expirationDate();
  const domain = cookieDomain(window.location);

  const cookieConsent = Object.keys(preferences)
    .filter((x) => !!preferences[x])
    .join(',');
  // Resolve this issue by updating the attributes of the cookie:
  // Specify SameSite=None and Secure if the cookie should be sent in cross-site requests. This enables third-party use.
  // Specify SameSite=Strict or SameSite=Lax if the cookie should not be sent in cross-site requests
  document.cookie = `cookieconsent=${cookieConsent}; expires=${expires.toUTCString()}; path=/; SameSite=Lax; domain=${domain}`;
  document.cookie = `cookieconsentRenewed=true; expires=${expires.toUTCString()}; path=/; SameSite=Lax; domain=${domain}`;

  if (shouldLoad) {
    initialize(preferences);
  }
  store.dispatch(accountActions.setGdprSelection(preferences));
};

/**
 * Checks if it already has cookie with user selection
 */
const hasUserSelection = (): boolean => {
  const isProd = window?.location?.host?.includes('portal.flipdish.com');
  const hasConsentCookie = document.cookie.indexOf('cookieconsent=') !== -1;
  const hasRenewedCookie = document.cookie.indexOf('cookieconsentRenewed=') !== -1;
  if (!isProd) {
    return hasConsentCookie;
  }
  return hasConsentCookie && hasRenewedCookie;
};

const isGDPREnabled = (): string | undefined => {
  return getDefaultCookieValue('gdpr_enabled');
};

const setGDPREnabled = (val: boolean) => {
  document.cookie = `gdpr_enabled=${val.toString()}`;
};

const getDefaultCookieValue = (key: string): string | undefined => {
  const cookieValue = document.cookie
    .split('; ')
    ?.find((row) => row.startsWith(key))
    ?.split('=')[1];

  return cookieValue;
};

const getUserSelectionOrDefault = (): CookiesPreferences => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
  const cookieValue = getDefaultCookieValue('cookieconsent');
  const preferences = cookieValue?.split(',') || [];

  return {
    strictly: true,
    advertising: preferences.includes('advertising'),
    performance: preferences.includes('performance'),
    functional: preferences.includes('functional'),
  };
};

function useInitGdpr() {
  useEffect(() => {
    if (gdpr.hasUserSelection()) {
      const prefs = gdpr.getUserSelectionOrDefault();
      gdpr.initialize(prefs);
      store.dispatch(accountActions.setGdprSelection(prefs));
    }
  }, []);
}

export const getAcceptAllState = (): CookiesPreferences => ({
  advertising: true,
  strictly: true,
  performance: true,
  functional: true,
});

export const gdpr = {
  getAcceptAllState,
  isGDPREnabled,
  setGDPREnabled,
  setUserSelection,
  hasUserSelection,
  getUserSelectionOrDefault,
  initialize,
  useInitGdpr,
};
