import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { displayName, gravatarUrl, initials } from '../helpers/account';
import type { AccountState } from '../reducers/account.reducer';

const styles = () => {
  return createStyles({
    avatar: {
      borderRadius: '10%',
    },
  });
};

type Props = WithStyles<typeof styles> & {
  account: AccountState;
};

const InitialsAvatar = (props: Props) => {
  const { account, classes } = props;
  const imageUrl = gravatarUrl(account);

  return (
    <Avatar variant="square" alt={displayName(account)} src={imageUrl} className={classes.avatar}>
      {initials(account)}
    </Avatar>
  );
};

function mapStateToProps(state: AppState) {
  const { account } = state;
  return { account: account };
}

const EnhancedComponent = compose(
  withStyles(styles, {
    name: 'ProfileMenu',
  }),
  connect(mapStateToProps)
)(InitialsAvatar);

export { EnhancedComponent as InitialsAvatar };
