import React from 'react';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { Button } from '@fd/ui/Button';
import { HORIZONTAL_SPACE_CLASSNAME } from '@fd/ui/Layout';
import Spacer from '@fd/ui/Spacer';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../layouts/Notify/actions';
import {
  EntitlementOverrideRequest,
  EntitlmentFeatureSource,
} from '../../../overrides/@flipdish/subscriptions-api-client-typescript/api';
import { upsertEntitlementOverrides } from '../../../services/entitlements.service';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
    '&:focus': {
      backgroundColor: '#eaf2ff',
    },
  },
  paddingBottom: {
    paddingBottom: theme.spacing(1.8),
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

type EntitlementsListItemProps = {
  appId: string;
  isLast: boolean;
  source: EntitlmentFeatureSource;
  featureCode: string;
  refreshEntitlements: () => void;
} & MappedDispatch;

const EntitlementsListItem = ({
  appId,
  isLast,
  source,
  refreshEntitlements,
  featureCode,
  closeNotifySaving,
  notifyError,
  notifySaved,
  notifySaving,
}: EntitlementsListItemProps) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { mutateAsync, isPending: isUpdatingEntitlements } = useMutation({
    mutationFn: (request: EntitlementOverrideRequest) => {
      notifySaving();
      return upsertEntitlementOverrides(appId, request);
    },
    onSuccess: () => {
      closeNotifySaving();
      notifySaved();
      refreshEntitlements();
    },
    onError: (error) => {
      closeNotifySaving();
      notifyError({ message: 'Something_went_wrong', translate: true });
    },
  });

  const getTitle = () => {
    switch (source.sourceType) {
      case EntitlmentFeatureSource.SourceTypeEnum.Subscription:
        return `${source.quantity} x ${source.subscriptionId} (${source.productName})`;
      case EntitlmentFeatureSource.SourceTypeEnum.Override:
        return (
          <>
            {source.quantity} x <Translate id="Entitlements_override" />
          </>
        );
      case EntitlmentFeatureSource.SourceTypeEnum.Expiring:
        return (
          <>
            {source.quantity} x <Translate id="Entitlements_expiring" />
          </>
        );
      default:
        return 'Unknown source';
    }
  };

  const handleClick = () => {
    if (source.sourceType === EntitlmentFeatureSource.SourceTypeEnum.Subscription) {
      history.push(`/${appId}/billing/subscriptions/${source.subscriptionId}`);
    }
  };

  const handleRemoveOverrideClick = async () => {
    await mutateAsync({
      appId,
      featureCode: featureCode,
      quantity: 0,
    });
  };

  const clickable = source.sourceType === EntitlmentFeatureSource.SourceTypeEnum.Subscription;

  return (
    <ListItem
      onClick={handleClick}
      disableGutters
      divider={!isLast}
      alignItems="center"
      button={clickable}
      className={`${clickable ? classes.root : ''} ${HORIZONTAL_SPACE_CLASSNAME} ${
        isMobile || (!isMobile && !isLast) ? classes.paddingBottom : ''
      }`}
      component={Box as any}
      hover="false"
      data-fd={`row-${featureCode}-${source.sourceType}`}
    >
      <Typography variant="body1">{getTitle()}</Typography>
      {clickable && (
        <ListItemSecondaryAction>
          <Spacer size={8}>
            <KeyboardArrowRight data-fd={`${featureCode}-right-arrow`} color="action" />
          </Spacer>
        </ListItemSecondaryAction>
      )}
      {source.sourceType === EntitlmentFeatureSource.SourceTypeEnum.Override && (
        <ListItemSecondaryAction>
          <Spacer size={8}>
            <Button
              fdKey="override-button"
              variant="contained"
              size="small"
              color="error"
              onClick={handleRemoveOverrideClick}
              disabled={isUpdatingEntitlements}
            >
              <Translate id="Remove_override" />
            </Button>
          </Spacer>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving()),
  notifySaved: () => dispatch(notifySaved()),
  closeNotifySaving: () => dispatch(closeNotifySaving()),
});

export default connect(undefined, mapDispatchToProps)(EntitlementsListItem);
