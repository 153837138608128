import React, { useEffect } from 'react';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import MuiPickersUtilsProvider from '../../../../ui/MuiPickersUtilsProvider';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../../../WithRouteSearchParams';
import { setFilter } from '../actions';

type Props = MappedDispatch & WithRouteSearchParamsProps<string>;

const DateFilter: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { search, setSearch } = props;

  useEffect(() => {
    if (search) {
      props.setFilter(moment(search).toDate());
    }
  }, [search]);

  function handleChangeSelection(date: moment.Moment) {
    if (date && date.isValid()) {
      setSearch(date.format('YYYY-MM-DD'));
    }
  }

  return (
    <Translate>
      {(translate) => (
        <MuiPickersUtilsProvider>
          <DesktopDatePicker
            value={moment(search)}
            onChange={handleChangeSelection}
            disableFuture
            label={translate('Date')}
            slotProps={{
              textField: {
                fullWidth: true,
                inputProps: {
                  'data-fd': 'date_filter',
                  id: 'date_filter',
                  InputLabelProps: {
                    htmlFor: 'date_filter',
                    shrink: true,
                  },
                },
              },
              popper: { style: { zIndex: 9999 } },
            }}
            format={moment.localeData().longDateFormat('L')}
          />
        </MuiPickersUtilsProvider>
      )}
    </Translate>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  setFilter: (date?: Date) => {
    dispatch(setFilter({ date }));
  },
});

export default compose<InnerProps, {}>(
  withRouteSearchParams({
    name: 'date',
  }),
  connect(null, mapDispatchToProps)
)(DateFilter);
