import { type Org } from '@flipdish/orgmanagement';

import { SET_CURRENT_ORG, SET_SELECTED_PROPERTY } from './rms.actions';

type ProperyState = { selectedProperty?: any; currentOrg?: Org };

const initialState: ProperyState = {
  selectedProperty: undefined,
  currentOrg: undefined,
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.payload,
      };
    case SET_CURRENT_ORG:
      return {
        ...state,
        currentOrg: action.payload as Org,
      };
    default:
      return state;
  }
};

export default propertyReducer;
