import {
  Configuration,
  CreateProperty,
  CreatePropertyForOrg201Response,
  GetPropertiesByOrgId200Response,
  PropertiesApi,
  UpdateProperty,
} from '@flipdish/orgmanagement';

import { getApiEndPoint } from '../../helpers/apibase';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const baseURL = getApiEndPoint();

const configuration = new Configuration({
  basePath: baseURL,
  useDefaultUserAgent: true,
});

const defaultOptions = {
  withCredentials: true,
};

const propertiesApi = new PropertiesApi(configuration);

// #region getAllPropertiesForOrg
export const getAllPropertiesForOrgKey = 'getAllPropertiesForOrg';
export async function getAllPropertiesForOrg(
  orgId: string
): Promise<{ data: GetPropertiesByOrgId200Response }> {
  try {
    return await propertiesApi.getPropertiesByOrgId({ orgId }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region createPropertyForOrg
export const createPropertyForOrgKey = 'createPropertyForOrg';
export async function createPropertyForOrg(
  orgId: string,
  property: CreateProperty
): Promise<{ data: CreatePropertyForOrg201Response }> {
  try {
    return await propertiesApi.createPropertyForOrg(
      { orgId, createProperty: property },
      defaultOptions
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getPropertyById
export const getPropertyByIdKey = 'getPropertyById';
export async function getPropertyById(orgId: string, propertyId: string): Promise<{ data: any }> {
  try {
    return await propertiesApi.getPropertyById({ orgId, propertyId }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region updatePropertyById
export const updatePropertyByIdKey = 'updatePropertyById';
export async function updatePropertyById(
  orgId: string,
  propertyId: string,
  property: UpdateProperty
): Promise<{ data: any }> {
  try {
    return await propertiesApi.updatePropertyById(
      { orgId, propertyId, updateProperty: property },
      defaultOptions
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

const propertiesService = {
  getAllPropertiesForOrg,
  createPropertyForOrg,
  updatePropertyById,
  getPropertyById,
};

export default propertiesService;
