import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { ReactComponent as FlipdishLogo } from '../../assets/images/fdl-medium.svg';
import { gdpr } from '../../helpers/gdpr';
import Button from '../../ui/Button/Button';
import CookieModal from './CookieModal';
import CookiePolicyLink from './CookiePolicyLink';

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    color: theme.palette.text.secondary,
  },
  paperWidthSm: {
    // width 480 is pixel perfect with figma
    width: 480,
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      minWidth: 'calc(100% - 40px)',
      padding: 0,
    },
  },
  dialogActions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  logo: {
    margin: theme.spacing(2),
  },
}));

const CookieSettings: React.FC<React.PropsWithChildren<MappedState>> = (props: MappedState) => {
  const { accountIsLoggedIn } = props;
  const classes = useStyles();

  const handleAcceptAll = () => {
    const newState = gdpr.getAcceptAllState();
    gdpr.setUserSelection(newState);
    setIsOpenModal(false);
  };

  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [isOpenManageCookiesModal, setIsOpenManageCookiesModal] = React.useState<boolean>(false);

  useEffect(() => {
    const isOpenModalValueByDefault = !gdpr.hasUserSelection() && accountIsLoggedIn;
    setIsOpenModal(isOpenModalValueByDefault);
  }, [accountIsLoggedIn]);

  const handleDone = () => {
    setIsOpenManageCookiesModal(false);
  };

  const handleManage = () => {
    setIsOpenModal(false);
    setIsOpenManageCookiesModal(true);
  };

  const dialogClasses = {
    paperWidthSm: classes.paperWidthSm,
  };

  return (
    <>
      <CookieModal isOpenModal={isOpenManageCookiesModal} handleDone={handleDone} />

      <Dialog
        aria-describedby="cookie-dialog-description"
        aria-labelledby="cookie-dialog-title"
        classes={dialogClasses}
        fullWidth={true}
        open={isOpenModal}
      >
        <DialogTitle>
          <Box textAlign="center">
            <FlipdishLogo aria-labelledby="Flipdish logo" className={classes.logo} />
            <Typography variant="h5">
              <Translate id="Your_cookie_settings" />
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box textAlign="center">
            <Typography className={classes.body} variant="body2">
              <Translate id="Cookie_Modal_Body1" />
            </Typography>
            <Box py={2}>
              <CookiePolicyLink underlinedLink={false} id="Cookie_Modal_Body2" />
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Box className={classes.dialogActions}>
            <Button
              onClick={handleManage}
              color="primary"
              variant="contained"
              className={classes.button}
              fdKey="cookie_policy_manage"
            >
              <Translate id="Manage" />
            </Button>

            <Button
              onClick={handleAcceptAll}
              color="primary"
              variant="contained"
              className={classes.button}
              fdKey="cookie_policy_save"
              data-fd="cookie_policy_save"
            >
              <Translate id="Accept_All" />
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToProps>>;
const mapStateToProps = () => {
  return (state: AppState) => {
    const { account } = state;
    return {
      accountIsLoggedIn: account.authorized,
    };
  };
};

export default connect(mapStateToProps)(CookieSettings);
