import { SalesChannelType } from '@fd/customHooks/useQueryRMSSalesChannels';

export const getIsFlipdishChannel = (channel) =>
  [
    SalesChannelType.FLIPDISH_KIOSK,
    SalesChannelType.FLIPDISH_MOBILE_APP,
    SalesChannelType.FLIPDISH_WEB_APP,
  ].includes((channel as SalesChannelType) || '');

export const salesChannelTypes = [
  { value: SalesChannelType.POS, label: 'POS' },
  { value: SalesChannelType.FLIPDISH_KIOSK, label: 'Flipdish Kiosk' },
  { value: SalesChannelType.FLIPDISH_MOBILE_APP, label: 'Flipdish Mobile App' },
  { value: SalesChannelType.FLIPDISH_WEB_APP, label: 'Flipdish Web App' },
  { value: SalesChannelType.UBER_EATS, label: 'Uber Eats' },
  { value: SalesChannelType.JUST_EATS, label: 'Just Eats' },
  { value: SalesChannelType.DELIVEROO, label: 'Deliveroo' },
];

export const getBackToSalesChannelListUrl = ({ appId, location, propertyId = '' }) => {
  if (location.pathname.includes('website')) {
    return `/${appId}/sales-channels/website?tab=sales-channels`;
  } else if (location.pathname.includes('properties')) {
    return `/${appId}/properties/${propertyId}?tab=sales-channels`;
  }
  return `/${appId}/sales-channels/point-of-sale?tab=sales-channels`;
};
