import React from 'react';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import appStoreIcon from '../../assets/images/Appstore.svg';
import PageLayout, { HORIZONTAL_SPACE_CLASSNAME } from '../../ui/Layout';
import PaperContainer from '../../ui/Layout/PaperContainer';
import ListItemLink from '../../ui/List/ListItemLink';
import Spacer from '../../ui/Spacer';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
    '&:focus': {
      backgroundColor: '#eaf2ff',
    },
  },
});

interface IDispatchProps {
  dispatch: (input: any) => any;
}

type Props = MappedProps & IDispatchProps;
const DeveloperTools = (props: Props) => {
  const { appId } = props;
  const classes = useStyles();
  return (
    <PageLayout
      toParent={`/${appId}/home`}
      documentTitle="developer_tools"
      title={<Translate id="developer_tools" />}
    >
      <PaperContainer fluid>
        <List component="nav">
          <ListItemLink
            button
            fdKey="OAuth_Apps_Link"
            className={`${classes.root} ${HORIZONTAL_SPACE_CLASSNAME}`}
            to={`/${appId}/developers/apps`}
            alignItems="center"
          >
            <ListItemAvatar>
              <Spacer size={8}>
                <SettingsOutlined color="action" />
              </Spacer>
            </ListItemAvatar>
            <ListItemText primary={<Translate id={'Oauth_apps_link'} />} />

            <ListItemSecondaryAction>
              <Spacer size={8}>
                <KeyboardArrowRight color="action" />
              </Spacer>
            </ListItemSecondaryAction>
          </ListItemLink>
          <Divider variant="inset" />
          <ListItemLink
            button
            fdKey="App_Store_Apps_Link"
            className={classes.root}
            to={`/${appId}/appstore/developers`}
            alignItems="center"
          >
            <ListItemAvatar>
              <Spacer size={8}>
                <img src={appStoreIcon} />
              </Spacer>
            </ListItemAvatar>
            <ListItemText primary={<Translate id={'App_store_apps'} />} />

            <ListItemSecondaryAction>
              <Spacer size={8}>
                <KeyboardArrowRight color="action" />
              </Spacer>
            </ListItemSecondaryAction>
          </ListItemLink>
        </List>
      </PaperContainer>
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    appId: state.currentApp.AppId,
  };
};

export default connect(mapStateToProps)(DeveloperTools);
