import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import FkSelect from '@fd/ui/FkSelect/FkSelect';

import { EFieldType } from '../../../models/EFieldType';
import { FormikInputField } from '../FormikInputField';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    marginBottom: theme.spacing(2),
  },
}));

const isInScope = (ruleScopes: any, formScope?: string) => {
  return !ruleScopes || ruleScopes?.split(',').includes(formScope);
};

const getDynamicValidation = (dynamicField, formScope) => {
  const { Rules } = dynamicField;
  return (value) => {
    const requiredRule = Rules.Required;
    if (requiredRule?.Value && isInScope(requiredRule?.Scopes, formScope) && !value) {
      return requiredRule?.Message;
    }

    const maxLengthRule = Rules.MaxLength;
    if (
      maxLengthRule?.Value &&
      isInScope(maxLengthRule?.Scopes, formScope) &&
      value?.length > maxLengthRule?.Value
    ) {
      return maxLengthRule?.Message;
    }

    const minLengthRule = Rules.MinLength;
    if (
      minLengthRule?.Value &&
      isInScope(minLengthRule?.Scopes, formScope) &&
      value?.length < minLengthRule?.Value
    ) {
      return minLengthRule?.Message;
    }

    const patternRule = Rules?.Pattern;
    if (
      patternRule?.Value &&
      isInScope(patternRule?.Scopes, formScope) &&
      !new RegExp(patternRule?.Value).test(value)
    ) {
      return patternRule?.Message;
    }
  };
};

export type DynamicInputFieldsProps = {
  form: any;
  formScope?: string;
  disabled?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  handleInputChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const getPlaceHolder = (dynamicField: { Placeholder?: string; Label?: string }) => {
  if (dynamicField.Placeholder !== dynamicField.Label) {
    return dynamicField.Placeholder;
  }
  return '';
};

export const DynamicInputFields = ({
  form,
  formScope = 'Store',
  disabled = false,
  menuPlacement = 'bottom',
  handleInputChange,
}: DynamicInputFieldsProps) => {
  const classes = useStyles();

  return form.map((dynamicField, index) => {
    const lastField = form.length - 1 === index;
    const isNextFieldSelect = !lastField && Array.isArray(form[index + 1]?.Rules?.Predefined);
    const isSelectField = Array.isArray(dynamicField?.Rules?.Predefined);

    return (
      <Grid item xs={12} key={index} className={isNextFieldSelect ? '' : classes.row}>
        {isSelectField ? (
          <FkSelect
            disabled={disabled}
            fdKey={`input-form-${dynamicField.Identifier}`}
            removeTopPadding={true}
            noErrorTranslate={true}
            fieldName={dynamicField.Identifier}
            fieldType={EFieldType.Text}
            name={dynamicField.Identifier}
            validation={getDynamicValidation(dynamicField, formScope)}
            options={dynamicField.Rules.Predefined.map((option) => ({
              value: option.Value,
              label: option.Display,
            }))}
            menuPlacement={menuPlacement}
            textFieldProps={(shrink, value) => ({
              style: { paddingTop: '0.5em' },
              FormHelperTextProps: {
                style: {
                  alignSelf: 'flex-end',
                },
              },
              label: dynamicField.Label,
              InputLabelProps: {
                style: { paddingTop: '0.9em' },
                shrink: shrink || value ? true : false,
              },
            })}
            variant="standard"
            onChange={(values) =>
              handleInputChange &&
              handleInputChange({
                target: { value: values },
              } as React.ChangeEvent<HTMLInputElement>)
            }
          />
        ) : (
          <FormikInputField
            disabled={disabled}
            key={index}
            fdKey={`input-form-${dynamicField.Identifier}`}
            name={dynamicField.Identifier}
            label={dynamicField.Label}
            placeholder={getPlaceHolder(dynamicField)}
            validate={getDynamicValidation(dynamicField, formScope)}
          />
        )}
      </Grid>
    );
  });
};
