import {
  OnboardingApiFactory,
  OnboardingConfigUpdate,
  OnboardingItemUpdate,
} from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../../helpers/apibase';
import { createApi } from '../../helpers/utilities';
import { KNOWN_ERRORS, mapServerError } from '../../services/utils/serverErrorMapper';

const tutorialApi = createApi(OnboardingApiFactory);

const baseUrl = getApiEndPoint();
const extraApi = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

const tutorialStoreKey = 'tutorialStore';
const getTutorialKey = 'getTutorial';

// #region getTutorial
async function getTutorial(appId: string, storeId: number, milestoneId?: number) {
  try {
    const result = await tutorialApi.onboardingGetOnboardingItems(appId, storeId, milestoneId);

    const tutorialObject = { Items: result.Data };
    return tutorialObject;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region updateTutorialItem
async function updateTutorialItem(
  appId: string,
  storeId: number,
  onboardingItemId: number,
  status: OnboardingItemUpdate
) {
  try {
    const onboardingStatus = { Status: status };
    await extraApi.post(
      `/api/v1.0/clients/${appId}/onboarding/stores/${storeId}/items/${onboardingItemId}`,
      onboardingStatus
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getOnboardingConfig
export async function getOnboardingConfig(appId: string) {
  try {
    const result = await tutorialApi.onboardingGetOnboardingConfig(appId);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region updateOnboardingConfig
export async function updateOnboardingConfig(
  appId: string,
  { IsEnabled, IsWelcomeScreenEnabled, ConfigType }: OnboardingConfigUpdate
) {
  try {
    await extraApi.post(`/api/v1.0/clients/${appId}/onboarding/config`, {
      IsEnabled,
      IsWelcomeScreenEnabled,
      ConfigType,
    });
  } catch (e) {
    if (KNOWN_ERRORS.includes(e?.message)) {
      return;
    }
    const err = await mapServerError(e);
    throw err;
  }
}

export async function updateOnboardingConfigById({
  appId,
  configId,
  config,
}: {
  appId: string;
  configId: number;
  config: OnboardingConfigUpdate;
}) {
  try {
    await extraApi.post(`/api/v1.0/clients/${appId}/onboarding/config/${configId}`, config);
  } catch (e) {
    if (KNOWN_ERRORS.includes(e?.message)) {
      return;
    }
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

export const tutorialService = {
  getOnboardingConfig,
  getTutorial,
  getTutorialKey,
  tutorialStoreKey,
  updateOnboardingConfig,
  updateOnboardingConfigById,
  updateTutorialItem,
};
