import React, { useCallback, useEffect, useState } from 'react';

import type { PropertyCountryCodeEnum } from '@flipdish/orgmanagement';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import type { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { Field } from 'formik';
import { debounce } from 'lodash';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { App } from '../../../overrides/@flipdish/api-client-typescript/api';
import { addressService } from '../../../services/address.service';
import { flagService } from '../../../services/flagService';
import GridContainer from '../../../ui/Layout/GridContainer';
import { CountrySelector } from '../../DynamicAddress/Components/CountrySelector/CountrySelector';
import { DynamicAddressWithCallback } from './DynamicAddressWithCallback';

const maxWidth = '718px';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: maxWidth,
  },
  item: {
    padding: '16px 0px',
  },
  mapItem: {
    minHeight: '240px',
  },
  textField: {
    height: '40px',
    margin: '5px',
    padding: '0px 16px 0px 0px',
  },
  grey: {
    color: 'rgb(0, 0, 0, 0.6)',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  gridItem: {
    padding: `${theme.spacing(1.5)}!important`,
  },
}));

export type Props = {
  addressFields: any;
  countryCodeInitialState: PropertyCountryCodeEnum | string;
  updateAddressFields: (values: any) => void;
} & MappedProps;

const PropertyAddressForm = (props: Props) => {
  const {
    addressFields,
    countryCodeInitialState,
    canEdit,
    translate,
    internationalisedAddressConfig,
    updateAddressFields,
    language,
  } = props;

  const classes = useStyles();

  const [countryCode, setCountryCode] = useState('');
  const useDynamicAddressFields =
    internationalisedAddressConfig?.affectedCountries?.includes(countryCode);

  const {
    data: addressData,
    isPending,
    isFetching,
    error,
  } = useQuery({
    queryKey: [addressService.getFormByCountryQueryKey, countryCode],
    queryFn: () => addressService.getFormByCountry(countryCode, language),
    enabled: useDynamicAddressFields,
    retry: false,
  });

  const { data: countries, isPending: loadingCountries } = useQuery({
    queryKey: [addressService.getCountriesQueryKey],
    queryFn: () => addressService.getCountries('en'),
  });

  useEffect(() => {
    if (countryCodeInitialState) {
      setCountryCode(countryCodeInitialState);
    }
  }, [countryCodeInitialState]);

  const handleCountrySelectorChange = (country) => {
    setCountryCode(country);
  };

  const handleAddressChange = useCallback(
    debounce((values) => {
      updateAddressFields(values);
    }, 500),
    [updateAddressFields]
  );

  const renderAddressSection = () => {
    const isError = error && error?.name !== 'NotFound';

    let addressForm;
    if (countryCode) {
      addressForm = !useDynamicAddressFields ? (
        <Typography>No dynamic fields for the selected country.</Typography>
      ) : (
        <DynamicAddressWithCallback
          form={addressData?.FormData}
          initialValues={addressFields}
          loading={isPending || isFetching || loadingCountries}
          error={isError ?? undefined}
          translate={translate}
          onChange={handleAddressChange}
          disabled={!canEdit}
        />
      );
    }

    return (
      <>
        <Field name="countryCode">
          {({ field, form }) => {
            const { errors, touched } = form;
            const showError = errors.countryCode && touched.countryCode;
            return (
              <>
                <CountrySelector
                  loading={loadingCountries}
                  translate={translate}
                  disabled={!canEdit}
                  countries={
                    countries?.map((country) => ({
                      label: country?.Label,
                      value: country?.Value,
                    })) || []
                  }
                  countryCode={field.value}
                  handleChange={(country) => {
                    form.setFieldValue('countryCode', country);
                    handleCountrySelectorChange(country);
                  }}
                />
                {showError && (
                  <Grid container justifyContent="flex-end">
                    <Typography color="error" variant="caption">
                      {errors.countryCode}
                    </Typography>
                  </Grid>
                )}
              </>
            );
          }}
        </Field>
        {addressForm}
      </>
    );
  };

  return (
    <GridContainer direction="row" justifyContent="space-between" className={classes.item}>
      <Grid item xs={12} sm={5} className={classes.gridItem}>
        <InputLabel htmlFor="address-dropdown">
          <Translate id="Property_address" />
        </InputLabel>
      </Grid>
      <Grid item xs={12} sm={7} className={classes.gridItem}>
        {renderAddressSection()}
      </Grid>
    </GridContainer>
  );
};

type MappedProps = ReturnType<typeof mapStateProps>;
const mapStateProps = (state: AppState) => {
  const canEdit = state.permissions.some((p) => p === App.AppResourceSetEnum.EditStores.toString());
  const internationalisedAddressConfig = flagService.getSplitValueConfig(
    state,
    'internationalisedAddress'
  )?.config;
  return {
    canEdit,
    internationalisedAddressConfig,
    language: state.account?.Language,
    translate: getTranslate(state.locale),
  };
};

export default connect(mapStateProps)(PropertyAddressForm);
