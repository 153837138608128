import React from 'react';

import { AppConfigUpdateModel } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { type FormikProps, Form, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';

import PaperContainer from '../../../ui/Layout/PaperContainer';
import LoadingButton from '../../../ui/LoadingButton';
import BrandNameField from './Fields/BrandNameField';
import LogoField from './Fields/LogoField';
import SelectLanguagesField from './Fields/SelectLanguagesField';
import SmsRestaurantNameField from './Fields/SmsRestaurantNameField';
import StoreTypeField from './Fields/StoreTypeField';
import WebAndAppsButtonColourField from './Fields/WebAndAppsButtonColourField';

const CATEGORIES: { [key: string]: AppConfigUpdateModel.ApplicationCategoryEnum } = {
  '0': AppConfigUpdateModel.ApplicationCategoryEnum.Restaurant,
  '1': AppConfigUpdateModel.ApplicationCategoryEnum.Cafe,
  '2': AppConfigUpdateModel.ApplicationCategoryEnum.Convenience,
};
export type FormValues = {
  name: string;
  languages: any;
  type: AppConfigUpdateModel.ApplicationCategoryEnum | undefined;
  webAndAppsButtonColour: string;
  IsPanaceaEnabled?: boolean;
  SMSRestaurantName: string;
};
export const getDefaultFormState = ({
  applicationCategory,
  brandName,
  isPanaceaEnabled,
  languages,
  smsRestaurantName,
  webAndAppsButtonColour,
}: {
  applicationCategory?: AppConfigUpdateModel.ApplicationCategoryEnum;
  brandName?: string;
  isPanaceaEnabled?: boolean;
  languages?: any;
  smsRestaurantName?: string;
  webAndAppsButtonColour?: string;
}): FormValues => {
  return {
    name: brandName ?? '',
    languages:
      (languages &&
        languages
          .map((item) => ({
            value: item.LanguageId || '',
            label: item.Name || '',
            displayOrder: item.DisplayOrder || 0,
          }))
          .sort((a, b) => a.displayOrder - b.displayOrder)) ||
      [],
    type:
      (applicationCategory !== undefined && CATEGORIES[applicationCategory]) ||
      AppConfigUpdateModel.ApplicationCategoryEnum.Restaurant,
    webAndAppsButtonColour: webAndAppsButtonColour ? `#${webAndAppsButtonColour}` : '#000000',
    IsPanaceaEnabled: isPanaceaEnabled,
    SMSRestaurantName: smsRestaurantName ?? '',
  };
};
type InnerProps = {};
type OuterProps = {
  applicationCategory?: AppConfigUpdateModel.ApplicationCategoryEnum;
  brandName?: string;
  isPanaceaEnabled?: boolean;
  languages?: any;
  smsRestaurantName?: string;
  submit: (values: FormValues) => Promise<void> | void;
  webAndAppsButtonColour?: string;
  saveButtonText?: TranslationId;
  formType?: 'create' | 'update';
};
type Props = InnerProps & OuterProps;
type StyleProps = {
  showSMSSettings: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  container: {
    paddingTop: (props) => (props.showSMSSettings ? 30 : 0),
    paddingRight: 0,
    paddingBottom: 24,
    paddingLeft: 24,
    marginBottom: 30,
  },
  formHeaderContainer: {
    marginBottom: 10,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
  formHeader: {
    paddingTop: 16,
    paddingBottom: 18,
  },
  field: {
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
  gridItem: {
    padding: theme.spacing(1),
  },
}));

const GeneralSettingsForm = ({
  isSubmitting,
  handleSubmit,
  saveButtonText,
  formType = 'update',
}: Props & FormikProps<FormValues>) => {
  const classes = useStyles({ showSMSSettings: formType === 'update' });
  return (
    <Form>
      <PaperContainer fluid>
        <Grid container className={classes.container}>
          {formType === 'update' && (
            <>
              <Grid item xs={12} className={classes.formHeaderContainer}>
                <Typography variant="h5" className={classes.formHeader}>
                  <Translate id="SMS_sender_name" />
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.field}>
                <SmsRestaurantNameField />
              </Grid>
            </>
          )}

          <Grid item xs={12} className={classes.formHeaderContainer} style={{ marginTop: '30px' }}>
            <Typography variant="h5" className={classes.formHeader}>
              <Translate id="Brand" />
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.field}>
            <BrandNameField />
          </Grid>

          <Grid item xs={12} className={classes.field}>
            <LogoField displayBlankLogo={formType === 'create'} />
          </Grid>

          <Grid item xs={12} className={classes.field}>
            <WebAndAppsButtonColourField />
          </Grid>

          <Grid item xs={12} className={classes.field}>
            <SelectLanguagesField />
          </Grid>

          <Grid item xs={12} className={classes.field}>
            <StoreTypeField />
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            alignContent="center"
            m={-1}
            width={`calc(100% + 16px)`}
          >
            <Grid item className={classes.gridItem}>
              <LoadingButton
                fdKey="Add_SmsRestaurantName"
                color="primary"
                variant="contained"
                onClick={() => handleSubmit()}
                loading={isSubmitting}
                style={{ marginTop: 16, marginRight: 16 }}
              >
                <Translate id={saveButtonText ?? 'Save'} />
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </PaperContainer>
    </Form>
  );
};
export default withFormik<Props, FormValues>({
  displayName: 'GeneralSettingsForm',
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    return getDefaultFormState(props);
  },
  handleSubmit: async (values, { props: { submit }, setSubmitting }) => {
    try {
      await submit(values);
    } catch (e) {
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  },
})(GeneralSettingsForm);
