import { TranslateFunction } from '../../overrides/react-localize-redux';

const PROPERTY_NAME_MAX_LENGTH = 100;
const PROPERTY_EMAIL_MAX_LENGTH = 100;
const PROPERTY_PHONE_MAX_LENGTH = 15;

export const validatePropertyForm = (values: any, translate: TranslateFunction) => {
  const errors: any = {};

  if (!values.name) {
    errors.name = translate('Required');
  } else if (values.name.length > PROPERTY_NAME_MAX_LENGTH) {
    errors.name = translate('Max_fieldname_length_exceeded', {
      fieldName: 'Property Name',
      max: PROPERTY_NAME_MAX_LENGTH,
    });
  }

  if (!values.countryCode || values.countryCode === '') {
    errors.countryCode = translate('Required');
  }

  if (!values.emailAddress) {
    errors.emailAddress = translate('Required');
  } else if (values.emailAddress.length > PROPERTY_EMAIL_MAX_LENGTH) {
    errors.emailAddress = translate('Max_fieldname_length_exceeded', {
      fieldName: 'Email',
      max: PROPERTY_EMAIL_MAX_LENGTH,
    });
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailAddress)) {
    errors.emailAddress = translate('Invalid_email');
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = translate('Required');
  } else if (values.phoneNumber.length > PROPERTY_PHONE_MAX_LENGTH) {
    errors.phoneNumber = translate('Max_fieldname_length_exceeded', {
      fieldName: 'Phone Number',
      max: PROPERTY_PHONE_MAX_LENGTH,
    });
  }

  return errors;
};
