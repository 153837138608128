import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';
import LazyComponent from '@fd/ui/LazyComponent';
import Tabs from '@fd/ui/Tabs';

import { lazyWithRetry } from '../../helpers/utilities';
import CreateAndEditProperty from './components/CreateAndEditProperty';
import { getPropertyById } from './properties.services';

const PropertyDevicesList = lazyWithRetry(() => import('./components/PropertyDevicesList'));
const SalesChannelList = lazyWithRetry(() => import('../RMS/SalesChannels/SalesChannelList'));

const PropertyDetails = ({ currentApp, currentOrg, translate }: MappedState) => {
  const history = useHistory();

  const orgId = currentOrg?.orgId || '';

  const { propertyId } = useParams<{ propertyId: string }>();

  const { data: getPropertyData } = useQuery<any>({
    queryKey: ['getPropertyById', orgId, propertyId],
    queryFn: () => getPropertyById(orgId, propertyId),
    enabled: !!propertyId,
  });

  const propertyData = getPropertyData?.data?.data || {};

  const handleChannelClick = (storefront) => {
    history.push(`/${currentApp?.AppId}/properties/${propertyId}/sales-channel/${storefront.id}`);
  };

  const tabItems = [
    {
      label: translate('Devices') as string,
      component: (
        <LazyComponent>
          <PropertyDevicesList />
        </LazyComponent>
      ),
      queryString: 'devices',
    },
    {
      label: translate('Sales_Channels') as string,
      component: (
        <LazyComponent>
          <SalesChannelList onChannelClick={handleChannelClick} hideFilter />
        </LazyComponent>
      ),
      queryString: 'sales-channels',
    },
    {
      label: translate('Settings') as string,
      component: (
        <LazyComponent>
          <CreateAndEditProperty propertyId={propertyId} fetchedPropertyData={propertyData} />
        </LazyComponent>
      ),
      queryString: 'Settings',
    },
  ];
  return (
    <PageLayout
      strictToParent
      toParent={`/${currentApp.AppId}/properties`}
      documentTitle={'Property'}
      title={propertyData.name}
    >
      <Tabs tabItems={tabItems} />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => ({
  translate: getTranslate(state?.locale),
  currentApp: state.currentApp,
  currentOrg: state.rms.currentOrg,
});
export default connect(mapStateToProps)(PropertyDetails);
