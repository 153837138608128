import React, { useEffect } from 'react';

import { App, Order, Reject } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { useQuery } from '@tanstack/react-query';
import round from 'lodash/round';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { compose } from 'recompose';

import * as ordersActions from '../../../actions/order.action';
import { notifyError, NotifyProps } from '../../../layouts/Notify/actions';
import { getRefundStatus } from '../../../services/order.service';
import OrderFulFillmentStatus from '../components/OrderFulfillmentStatus';
import RefundButton from './RefundButton';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      [theme.breakpoints.down('sm')]: { justifyContent: 'space-between' },
    },
  });
};

export const showRefund = (order: Order) => {
  const total = order.Amount ? order.Amount : 0;
  const refundedAmount = order.RefundedAmount ? order.RefundedAmount : 0;
  const amountAfterRefund = round(total - refundedAmount, 2);
  if (amountAfterRefund >= 0) {
    return true;
  }
};

type Props = MappedState & MappedDispatch & WithStyles<typeof styles>;

const AcceptedOrderActions = (props: Props) => {
  const { classes, order, notifyError, refundOrder } = props;

  const { data, isError, isPending } = useQuery({
    queryKey: [order.OrderId, 'isRefundable'],
    queryFn: () => getRefundStatus(order.OrderId as number),
    enabled: !!order.OrderId,
  });

  useEffect(() => {
    isError && notifyError({ message: 'Error_please_try_again_later', translate: true });
  }, [isError]);

  return (
    <div className={classes.container}>
      <OrderFulFillmentStatus orderId={order.OrderId} />
      <Permissions allowed={[App.AppResourceSetEnum.UpdateOrdersRefund]}>
        {showRefund(order) && (
          <RefundButton
            allowRefund={data?.PaymentRefundable}
            handleRefundOrder={refundOrder}
            isLoading={isPending}
            amount={order.Amount}
          />
        )}
      </Permissions>
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    order: state.orders.order,
    orderId: state.orders.ordersIds,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  refundOrder: (refundAmount: number, notifyCustomerRefund: boolean, cancelOrder: boolean) => {
    if (cancelOrder) {
      dispatch(
        ordersActions.rejectOrder(
          Reject.RejectReasonEnum.UnknownReason,
          undefined,
          notifyCustomerRefund
        )
      );
    } else {
      dispatch(ordersActions.refundOrder(refundAmount, notifyCustomerRefund));
    }
  },
});

const EnhancedComponent = compose<Props, any>(
  withStyles(styles, {
    name: 'AcceptedOrderActions',
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(AcceptedOrderActions);

export default EnhancedComponent;
