import axios from 'axios';

import { mapServerError } from '../../services/utils/serverErrorMapper';

const isProd = window.location?.host?.includes('portal.flipdish.com');
const rmsBaseUrl = isProd
  ? 'https://rms-core-api.portal.flipdish.com/api/core'
  : 'https://dev-rms-core.portal.flipdishdev.com/api/core';

const rmsApi = axios.create({
  baseURL: rmsBaseUrl,
  withCredentials: true,
});
type RMSExhangeResponse = {
  data: {
    token: string;
  };
};

// #region getRMSTokenFromFDAuthorization
export const getRMSTokenFromFDAuthorizationKey = 'getRMSTokenFromFDAuthorization';
export async function getRMSTokenFromFDAuthorization(): Promise<RMSExhangeResponse> {
  try {
    return await rmsApi.get('/system/security/authenticateCookie');
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getRMSPriceBands
export const getRMSPriceBandsKey = 'getRMSPriceBands';
export async function getRMSPriceBands(franchisorId: string, token: string): Promise<any> {
  try {
    return await rmsApi.get(`/organizations/${franchisorId}/lookups/priceBands`, {
      headers: {
        'x-token': token,
      },
    });
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getRMSPriceBands
export const getRMSMenusKey = 'getRMSMenus';
export async function getRMSMenus(franchisorId: string, token: string): Promise<any> {
  try {
    return await rmsApi.get(`/organizations/${franchisorId}/menuManagement/latest`, {
      headers: {
        'x-token': token,
      },
    });
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getAssociations
type OrgHierarchy = {
  franchisor: Franchisor;
  franchisees: Array<Franchisee>;
  properties: Array<Property>;
  brand: any;
};
interface FieldAssociations {
  flipdish: {
    orgId: string;
  };
}

interface Franchisor {
  code: string;
  country: string;
  displayResellerBranding: boolean;
  emailAddress: string;
  id: string;
  isBlocked: boolean;
  isEnabled: boolean;
  logoImageUrl: string;
  name: string;
  fieldAssociations: FieldAssociations;
}

interface Franchisee {
  code: string;
  franchisorId: string;
  id: string;
  name: string;
}

interface Property {
  address: string;
  code: string;
  emailAddress: string;
  franchiseeId: string;
  franchisorId: string;
  id: string;
  isBlocked: boolean;
  isEnabled: boolean;
  name: string;
  phoneNumber: string;
  timeZone: string;
  type: string;
  fieldAssociations: FieldAssociations;
  subscriptions: string[];
}

type AssociationResponse = {
  associationType: string;
  internalFieldValue: any;
  orgHierarchy: OrgHierarchy;
};

export const getAssociationsKey = 'getAssociations';
export async function getAssociations(
  appId: string,
  token: string
): Promise<{ data: AssociationResponse }> {
  try {
    if (!token) {
      throw new Error('No token provided');
    }

    return await rmsApi.get(`/associations/orgHierarchy/flipdish/appId/${appId}`, {
      headers: {
        'x-token': token,
      },
    });
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getAssociations
export const getStorefrontsKey = 'getStorefronts';
// TODO: Define a response type when I see what that is/ if they have a client library
export async function getStorefronts(values: {
  token?: string;
  franchisorId?: string;
  franchiseeId?: string;
  propertyId?: string;
}): Promise<any> {
  const { franchiseeId, franchisorId, propertyId, token } = values;
  try {
    if (!franchiseeId) {
      throw new Error('No franchiseeId provided');
    } else if (!franchisorId) {
      throw new Error('No franchisorId provided');
    } else if (!propertyId) {
      throw new Error('No propertyId provided');
    } else if (!token) {
      throw new Error('No token provided');
    }
    return await rmsApi.get(
      `/organizations/${franchisorId}/${franchiseeId}/${propertyId}/storefronts`,
      {
        headers: {
          'x-token': token,
        },
      }
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

// get storeId's associated with a saleschannel
export async function getStoreIdAssociations(
  salesChannelId: string
): Promise<{ data: { flipdish: { storeId: number } } }> {
  try {
    return await rmsApi.get(`/organizations/storefronts/${salesChannelId}/associations`);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

// #endregion
