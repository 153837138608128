import React from 'react';

import { gdpr } from '../../helpers/gdpr';

const NoCookieSettings: React.FC<React.PropsWithChildren> = () => {
  React.useEffect(() => {
    gdpr.setUserSelection({
      strictly: true,
      performance: true,
      advertising: true,
      functional: true,
    });
  }, []);

  return <span />;
};

export default NoCookieSettings;
