import React, { useEffect, useMemo, useRef, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useQuery } from '@tanstack/react-query';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';
import { checkVisibility } from 'react-redux-permissions/dist/core';
import {
  type RouteComponentProps,
  matchPath,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router';
import { compose, setDisplayName } from 'recompose';

import { accountActions } from '../actions/account.actions';
import { appsActions } from '../actions/apps.actions';
import { oneMinute } from '../components/common/timeUtils';
import Loading from '../components/Loading';
import {
  getOnboardingConfigs,
  getOnboardingConfigsQueryKey,
  getOnboardingItems,
  getOnboardingItemsQueryKey,
} from '../components/OnboardingV2/onboarding.service';
import organisationService from '../components/RMS/organisation.services';
import { isAcceptInviteFlowInProgress } from '../components/Teammates/teammateutils';
import { usePageTracking } from '../custom-hooks/usePageTracking';
import { gdpr } from '../helpers/gdpr';
import { store } from '../helpers/store';
import { getAccount, getIsFirstSetup } from '../selectors/account.selector';
import { getIsOnboarding } from '../selectors/app.selector';
import { flagService } from '../services';
import { useTracking } from '../services/amplitude/useTracking';
import { appsService } from '../services/apps.service';
import { refreshJwtToken } from '../services/auth.service';
import { sendDatadogRumData } from '../services/datadogRUM';
import { initialise as initaliseDataDogLogging } from '../services/loggerService';
import retryAsync from '../services/utils/retryAsync';
import { accountRoutesConst } from './account.routes';
import routes from './index.routes';

const DEFAULT_MIN_LOADING_TIMEOUT = 1300;

//#region helpers

const isPublicRoute = memoizeOne((pathname: string) =>
  routes.publicRoutes
    .filter((r) => !('to' in r))
    .some((r) => matchPath(pathname, { path: r.path }) !== null)
);
const isFirstSetupRoute = memoizeOne((pathname: string) =>
  routes.firstSetupRoutes
    .filter((r) => !('to' in r))
    .some((r) => matchPath(pathname, { path: r.path }) !== null)
);
const isFirstSetupV2Route = memoizeOne((pathname: string) =>
  routes.firstSetupRoutesV2
    .filter((r) => !('to' in r))
    .some((r) => matchPath(pathname, { path: r.path }) !== null)
);

const isAcceptInviteInProgress = isAcceptInviteFlowInProgress();

const createRoute = (
  props: RoutePropsExtended | RedirectPropsExtended,
  appPermissions: AppState['permissions']
) => {
  if ('to' in props) {
    // only Redirect has `to` prop required
    const { name, group, key = '', ...rest } = props;
    return <Redirect {...rest} key={`${name}.${key}`} />;
  }

  if ('permissions' in props) {
    const { permissions, name, group, key = '', ...rest } = props;
    if (checkVisibility(appPermissions, permissions, [])) {
      return <Route {...rest} key={`${name}.${key}`} />;
    }
    return null;
  }

  const { name, group, key = '', ...rest } = props;
  return <Route {...rest} key={`${name}.${key}`} />;
};
//#endregion

type Props = RouteComponentProps & MappedState & MappedDispatch;
const AuthRouter: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    isFirstSetup,
    isOnboarding,
    account,
    currentApp,
    registeredRoutes,
    location,
    history,
    permissions,
    isCallOrgCreateAppFFEnabled,
    isFirstSetupV2Config,
    refreshFDAuthorizationToken,
    isSplitActuallyReady,
  } = props;

  const isFirstSetupV2FFEnabled =
    isFirstSetupV2Config?.tester && account.Email?.includes(isFirstSetupV2Config?.tester);

  const { identifyUser } = useTracking(account);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);

  //#region onboarding v2
  const { data: onboardingConfig, isFetched: onboardConfigsFetched } = useQuery({
    queryKey: [getOnboardingConfigsQueryKey, currentApp.AppId],
    queryFn: async () => {
      const response = await getOnboardingConfigs(currentApp.AppId ?? '');
      const onboardingConfig = response?.Data.find(
        (cfg) => cfg.ConfigType === 'OnboardingWizard' && cfg.IsEnabled
      );
      return onboardingConfig;
    },
    enabled: !!currentApp.AppId && !!isFirstSetupV2FFEnabled,
  });

  useEffect(() => {
    if (refreshFDAuthorizationToken) {
      void refreshJwtToken();

      const tokenRefreshInterval = setInterval(() => {
        void refreshJwtToken();
      }, 5 * oneMinute);

      return () => clearInterval(tokenRefreshInterval);
    }
  }, [refreshFDAuthorizationToken]);

  useEffect(() => {
    if (onboardConfigsFetched && !onboardingConfig?.IsEnabled) {
      setInitialized(true);
    }
  }, [onboardConfigsFetched, onboardingConfig]);

  const { data: onboardingV2Steps, isFetched: isOnboardingV2StepsFetched } = useQuery({
    queryKey: [getOnboardingItemsQueryKey, currentApp.AppId],
    queryFn: async () => {
      const response = await getOnboardingItems(
        currentApp.AppId ?? '',
        onboardingConfig?.WebActivationWizardMilestoneId
      );
      return response?.Data?.RootItems?.[0]?.Items?.[0]?.Items?.filter(
        (step) => step?.Status !== 'Completed'
      );
    },
    enabled: !!onboardingConfig?.IsEnabled,
  });

  useEffect(() => {
    if (isOnboardingV2StepsFetched) {
      setInitialized(true);
    }
  }, [isOnboardingV2StepsFetched]);

  const isFirstSetupV2 = !!onboardingV2Steps?.length && isFirstSetupV2FFEnabled;

  //#endregion

  //#region initialize
  const startLoadingTime = useRef<number>();
  const pathBefore = useRef<string>();
  useEffect(() => {
    startLoadingTime.current = Date.now();

    (function setPathBefore() {
      const { pathname } = location;
      // now any route matches private /:appId/ :(
      if (
        pathname !== '/' &&
        !isPublicRoute(pathname) &&
        !isFirstSetupRoute(pathname) &&
        !isFirstSetupV2Route(pathname)
      ) {
        pathBefore.current = pathname;
      }
    })();

    props.getAccountDetails().catch(() => {
      // not login
      setTimeout(
        () => {
          setInitialized(true);
        },
        DEFAULT_MIN_LOADING_TIMEOUT - (Date.now() - startLoadingTime.current!)
      );
    });
  }, []);

  const isNewSignUp = () => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const code = params.get('code');
    return !!code;
  };

  useEffect(() => {
    // Prevents briefly showing portal home screen before onboarding screens display
    if (isNewSignUp() && account.authorized && !onboardConfigsFetched) {
      setInitialized(false);
      setLoading(true);
    }
  }, [account, onboardConfigsFetched]);

  const createOrgAndBrand = async () => {
    let currentOrgId = localStorage.getItem('fd-currentOrgId');

    if (!currentOrgId && account.Email) {
      const response = await organisationService.createOrg({
        isSelfServe: true,
        org: {
          emailAddress: account.Email,
          countryCode: 'IE', // Temp - this gets updated by the user in the onboarding MFE
          name: account.Email, // Temp - this gets updated by the user in the onboarding MFE
        },
      });

      currentOrgId = response.data.data?.orgId || '';
      // set the orgId in local storage to prevent duplicate org creation - org creation not limited to one per user!
      localStorage.setItem('fd-currentOrgId', currentOrgId);
    }

    const brandsResponse = await retryAsync({
      fn: () => organisationService.getBrandsForOrg(currentOrgId ?? ''),
      shouldRetry: (res) => {
        if (res?.data && res.data.data?.length) {
          return false;
        }
        return true;
      },
    });

    if (brandsResponse?.data && brandsResponse.data.data?.length) {
      await retryAsync({
        fn: () => props.setCurrentApp(brandsResponse?.data.data?.[0].brandId),
        shouldRetry: (apps) => {
          if (apps?.AppId) {
            return false;
          }
          return true;
        },
      });
    }
  };

  const createApp = async () => {
    const response = await appsService.createApp(account.Email);
    props.setCurrentApp(response.name);
  };

  const getAppId = () => {
    // AppId is in path either by:
    // 1. Put there by the user / followed a link
    // 2. ensureAppIdInUrlPath got it from localstorage and put it in path
    let tryAppId: string | undefined;
    if (pathBefore.current !== undefined) {
      const match = matchPath<{ appId: 'string' }>(pathBefore.current, { path: '/:appId' });
      if (match && match.params.appId) {
        tryAppId = match.params.appId;
      }
    }
    if (!tryAppId) {
      // WARNING!!!! THIS WILL MATCH ANY STRING
      const { pathname } = location;
      if (
        !isPublicRoute(pathname) &&
        !isFirstSetupRoute(pathname) &&
        !isFirstSetupV2Route(pathname)
      ) {
        const match = matchPath<{ appId: 'string' }>(pathname, {
          path: '/:appId',
        });
        if (match && match.params.appId) {
          tryAppId = match.params.appId;
        }
      }
    }
    return tryAppId || 'flipdish-global';
  };

  useEffect(() => {
    if (account.authorized && account.AccountId) {
      (async () => {
        if (!currentApp.AppId) {
          // This will return FD-global app for FD staff always
          // non fd but existing client will get one or more apps back based on their account cookie
          // new signup will get no apps back
          const app = await props.setCurrentApp(getAppId());

          // new clients
          if (
            !app?.AppId &&
            isFirstSetupV2FFEnabled &&
            !isAcceptInviteInProgress &&
            isNewSignUp()
          ) {
            if (isCallOrgCreateAppFFEnabled) {
              await createOrgAndBrand();
            } else {
              await createApp();
            }
          }
        }

        if (isFirstSetupV2FFEnabled) {
          if (onboardingV2Steps?.length === 0 && pathBefore.current) {
            const path = pathBefore.current;
            pathBefore.current = undefined;
            if (path !== location.pathname) {
              history.replace(path);
            }
          }
        }
        // TODO: remove else block after onboardingV2 goes live
        else {
          if ((account.SignupSteps || []).length === 0 && pathBefore.current) {
            const path = pathBefore.current;
            pathBefore.current = undefined;
            if (path !== location.pathname) {
              history.replace(path);
            }
          }
        }
        // TODO: remove this block when onboardinfg V2 is released
        if (isSplitActuallyReady && !isFirstSetupV2FFEnabled) {
          setTimeout(
            () => {
              setInitialized(true);
            },
            DEFAULT_MIN_LOADING_TIMEOUT - (Date.now() - startLoadingTime.current!)
          );
        }
      })();
    }
  }, [account, onboardingV2Steps, currentApp, isFirstSetupV2FFEnabled, isSplitActuallyReady]);
  //#endregion

  //#region page events trackers
  usePageTracking();
  //#endregion

  //#region GDPR -init on first load if not on create_password page
  gdpr.useInitGdpr();
  //#endregion

  //#region Split IO -init on first load to retrieve feature flags from Split
  useEffect(() => {
    const isLoggedIn = !!account?.Email;
    const TRAFFIC_TYPE = flagService.TRAFFIC_TYPE;
    const splitParams = {
      appId: currentApp.AppId,
      isFlipdishStaff: permissions.includes('FlipdishStaff'),
      userId: account.AccountId || account.DeviceId,
      languageId: account.Language,
      trafficType: isLoggedIn
        ? TRAFFIC_TYPE.USER_TRAFFIC_TYPE
        : TRAFFIC_TYPE.ANONYMOUS_USER_TRAFFIC_TYPE,
    };

    flagService.initialiseSplit(store, splitParams);
    sendDatadogRumData(account);
    identifyUser();
  }, [account?.AccountId, currentApp?.AppId, permissions]);
  //#endregion

  //#region DataDog -init on first load to allow logging of errors
  useEffect(() => {
    initaliseDataDogLogging();
  }, []);
  //#endregion

  useEffect(() => {
    if (loading && isAcceptInviteInProgress && account.authorized) {
      setLoading(false);
    }
  }, [loading, isAcceptInviteInProgress, account.authorized]);

  const routes = useMemo(() => {
    if (loading && !isAcceptInviteInProgress) {
      return null;
    }

    if (!account.authorized) {
      datadogRum.setUserProperty('setup_status', 'not_authenticated');
      datadogRum.setGlobalContextProperty('setup_status', 'not_authenticated');
      return registeredRoutes
        .filter((r) => r.group === 'public' || r.group === 'publicAndPrivate')
        .map((r) => createRoute(r, permissions));
    }

    if (isFirstSetupV2) {
      datadogRum.setUserProperty('setup_status', 'first_time_setup_V2');
      datadogRum.setGlobalContextProperty('setup_status', 'first_time_setup_V2');
      return registeredRoutes
        .filter((r) => r.group === 'first_time_setup_V2' || r.path === accountRoutesConst.Logout)
        .map((r) => createRoute(r, permissions));
    }

    if (isFirstSetup && isSplitActuallyReady && !isFirstSetupV2FFEnabled) {
      datadogRum.setUserProperty('setup_status', 'first_time_setup');
      datadogRum.setGlobalContextProperty('setup_status', 'first_time_setup');
      return registeredRoutes
        .filter((r) => r.group === 'first_time_setup' || r.path === accountRoutesConst.Logout)
        .map((r) => createRoute(r, permissions));
    }

    if (isOnboarding) {
      datadogRum.setUserProperty('setup_status', 'onboarding_setup');
      datadogRum.setGlobalContextProperty('setup_status', 'onboarding_setup');
      return registeredRoutes
        .filter((r) => r.group === 'onboarding_setup')
        .map((r) => createRoute(r, permissions));
    }

    datadogRum.setUserProperty('setup_status', 'logged_in');
    datadogRum.setGlobalContextProperty('setup_status', 'logged_in');

    return registeredRoutes
      .filter((r) => r.group === 'private' || r.group === 'publicAndPrivate')
      .map((r) => createRoute(r, permissions));
  }, [
    loading,
    account,
    isFirstSetup,
    isOnboarding,
    registeredRoutes,
    permissions,
    isFirstSetupV2,
    isSplitActuallyReady,
    isFirstSetupV2FFEnabled,
  ]);

  if (loading) {
    return (
      <Loading
        fullscreen
        animate={!initialized}
        onAnimationEnd={() => {
          // When setInitialized(true) onAnimationEnd sets loading false
          setLoading(false);
        }}
      />
    );
  }

  return <Switch>{routes}</Switch>;
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const isFirstSetupV2Config: Record<'tester', string | undefined> =
    flagService.getSplitValueConfig(state, 'firstSetupV2')?.config;

  return {
    account: getAccount(state),
    currentApp: state.currentApp,
    isFirstSetup: getIsFirstSetup(state),
    isOnboarding: getIsOnboarding(state),
    isFirstSetupV2Config,
    refreshFDAuthorizationToken: flagService.isFlagOn(state, 'refreshFDAuthorizationToken'),
    isCallOrgCreateAppFFEnabled: flagService.isFlagOn(state, 'callOrgCreateApp'),
    permissions: state.permissions,
    registeredRoutes: state.routing.routes,
    // TODO: remove when onboarding V2 goes live
    isSplitActuallyReady: !!Object.entries(state.splitio?.treatments || {})?.length,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getAccountDetails: () => dispatch(accountActions.getAccountDetails(true)),
  setCurrentApp: (appId: string | undefined) => dispatch(appsActions.setCurrentApp(appId)),
});

export default compose<Props, {}>(
  setDisplayName('AuthRouter'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AuthRouter);
