import React from 'react';

import MapOutlined from '@mui/icons-material/MapOutlined';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps } from 'react-router-dom';

import { NavigationList } from '@fd/ui/molecules/NavigationList';
import { NavigationListItem } from '@fd/ui/molecules/NavigationList/NavigationList';
import { PageHeader } from '@fd/ui/molecules/PageHeader';

import Kitchen from '../../../assets/images/kitchen.jpg';
import { useQuerySalesChannelsHook } from '../../../custom-hooks/useQuerySalesChannelsHook';
import { channelsService } from '../../../services/channels.service';
import PageLayout from '../../../ui/Layout';

const useStyles = makeStyles((theme: Theme) => ({
  navigationList: {
    marginTop: theme.spacing(4),
  },
  pageContent: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

export const ChannelSettings = ({ channelParam, appId }: MappedState) => {
  const classes = useStyles();

  const { data: channelData } = useQuerySalesChannelsHook(
    () => channelsService.getChannel(parseInt(channelParam), appId || ''),
    'channel',
    channelParam !== undefined && appId !== undefined,
    true
  );

  const navigationListItems: NavigationListItem[] = [
    {
      icon: <MapOutlined style={{ color: 'rgba(0, 0, 0, 0.58)' }} />,
      key: 'StoreMapping',
      primaryText: <Translate id="Store_mapping" />,
      url: `${channelParam}/store-mapping`,
    },
  ];

  return (
    <PageLayout
      documentTitle="General_settings"
      title={<Translate id={`${channelData?.Data.TranslationKey}_External` as TranslationId} />}
    >
      <div className={classes.pageContent}>
        <PageHeader
          heading={
            <Translate id={`${channelData?.Data.TranslationKey}_External` as TranslationId} />
          }
          image={Kitchen}
          description="Description"
        />
        <div className={classes.navigationList} data-fd="navigation-list">
          <NavigationList items={navigationListItems} />
        </div>
      </div>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, routeProps: RouteComponentProps<{ channel: any }>) => {
  return {
    channelParam: routeProps.match.params.channel,
    appId: state.currentApp.AppId,
  };
};

export default connect(mapStateToProps)(ChannelSettings);
