import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import {
  SalesChannelType,
  useQueryRMSSalesChannels,
} from '@fd/customHooks/useQueryRMSSalesChannels';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined';
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import ZoomInMapOutlinedIcon from '@mui/icons-material/ZoomInMapOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import EmptyComponent from '@fd/ui/EmptyComponent';

import { toKebabCase } from '../../../helpers/strings';
import { getTranslate, Translate } from '../../../overrides/react-localize-redux';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import ListItemLinkButton from '../../../ui/List/ListItemLinkButton';
import { RMSModules } from '../utils/rmsModules';
import { getBackToSalesChannelListUrl } from './utils';

const Content = ({ isLoading, error, salesChannel, translate, baseUrl }) => {
  const salesChannelType = salesChannel?.type || '';
  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <EmptyComponent title="Something_went_wrong" />;
  }

  if (!salesChannel) {
    return <EmptyComponent title="Sales_Channel_Not_Found" noLink />;
  }

  return (
    <PaperContainer fluid>
      <List aria-label={translate('Sales_Channel_Settings') as string}>
        <ListItemLinkButton
          title="Basic_Configuration"
          subtitle="Configure_sales_channel_basic_configuration"
          link={`${baseUrl}/${toKebabCase(RMSModules.BASIC_CONFIGURATION)}`}
          iconComponent={<SettingsOutlinedIcon />}
        />
        <ListItemLinkButton
          title="Operating_Configuration"
          subtitle="Configure_operating_configuration"
          link={`${baseUrl}/${toKebabCase(RMSModules.OPERATING_CONFIGURATION)}`}
          iconComponent={<SettingsApplicationsOutlinedIcon />}
        />
        {salesChannelType === SalesChannelType.POS && (
          <ListItemLinkButton
            title="Payout_Configuration"
            subtitle="Configure_payout_configuration"
            link={`${baseUrl}/${toKebabCase(RMSModules.PAYOUT_CONFIGURATION)}`}
            iconComponent={<CreditCardOutlinedIcon />}
          />
        )}
        <ListItemLinkButton
          title="Opening_Hours"
          subtitle="Configure_sales_channel_opening_hours"
          link={`${baseUrl}/${toKebabCase(RMSModules.OPENING_HOURS)}`}
          iconComponent={<AccessTimeOutlinedIcon />}
        />
        <ListItemLinkButton
          title="Opening_Hours_Overrides"
          subtitle="Configure_sales_channel_opening_hours_overrides"
          link={`${baseUrl}/${toKebabCase(RMSModules.OPENING_HOURS_OVERRIDES)}`}
          iconComponent={<AccessTimeFilledOutlinedIcon />}
        />
        <ListItemLinkButton
          title="Delivery_Charges"
          subtitle="Configure_delivery_charges"
          link={`${baseUrl}/${toKebabCase(RMSModules.DELIVERY_CHARGES)}`}
          iconComponent={<MopedOutlinedIcon />}
        />
        {salesChannelType === SalesChannelType.POS && (
          <ListItemLinkButton
            title="Tip_Configuration"
            subtitle="Configure_tip_configuration"
            link={`${baseUrl}/${toKebabCase(RMSModules.TIP_CONFIGURATION)}`}
            iconComponent={<AttachMoneyOutlinedIcon />}
          />
        )}
        {[
          SalesChannelType.UBER_EATS,
          SalesChannelType.JUST_EATS,
          SalesChannelType.DELIVEROO,
          SalesChannelType.FLIPDISH_KIOSK,
          SalesChannelType.FLIPDISH_MOBILE_APP,
          SalesChannelType.FLIPDISH_WEB_APP,
          SalesChannelType.EXTERNAL_APP,
        ].includes(salesChannelType) && (
          <ListItemLinkButton
            title="Order_Ingest"
            subtitle="Configure_order_ingest"
            link={`${baseUrl}/${toKebabCase(RMSModules.ORDER_INGEST)}`}
            iconComponent={<ZoomInMapOutlinedIcon />}
          />
        )}
        <ListItemLinkButton
          title="Timings_Configuration"
          subtitle="Configure_timings_configuration"
          link={`${baseUrl}/${toKebabCase(RMSModules.TIMINGS_CONFIGURATION)}`}
          iconComponent={<MoreTimeOutlinedIcon />}
          isLast={
            ![SalesChannelType.POS, SalesChannelType.WEB_APP, SalesChannelType.MOBILE_APP].includes(
              salesChannelType
            )
          }
        />
        {salesChannelType === SalesChannelType.POS && (
          <ListItemLinkButton
            title="Over_Phone_Payment"
            subtitle="Configure_over_phone_payment"
            link={`${baseUrl}/${toKebabCase(RMSModules.OVER_PHONE_PAYMENT)}`}
            iconComponent={<LocalPhoneOutlinedIcon />}
            isLast={true}
          />
        )}
        {salesChannelType === SalesChannelType.WEB_APP && (
          <ListItemLinkButton
            title="Web_App_Configuration"
            subtitle="Configure_web_app_configuration"
            link={`${baseUrl}/${toKebabCase(RMSModules.WEB_APP_CONFIGURATION)}`}
            iconComponent={<WebOutlinedIcon />}
            isLast={true}
          />
        )}
        {salesChannelType === SalesChannelType.MOBILE_APP && (
          <ListItemLinkButton
            title="Mobile_App_Configuration"
            subtitle="Configure_mobile_app_configuration"
            link={`${baseUrl}/${toKebabCase(RMSModules.MOBILE_APP_CONFIGURATION)}`}
            iconComponent={<PhoneIphoneOutlinedIcon />}
            isLast={true}
          />
        )}
      </List>
    </PaperContainer>
  );
};
const SalesChannelSettings = (props: MappedState) => {
  const { currentApp, translate } = props;
  const history = useHistory();
  const location = useLocation();

  const params = useParams<{ channelId: string; propertyId: string }>();
  const channelId = params.channelId;
  const propertyId = params.propertyId;
  const isOrgApiPropertyId = !!propertyId?.startsWith('p');

  const {
    data: commonData,
    isLoading: isLoadingCommonData,
    error: commonDataError,
  } = useQueryRMSCommonDataHook(currentApp?.AppId || '', isOrgApiPropertyId);

  const rmsPropertyId =
    (isOrgApiPropertyId
      ? commonData?.associations?.orgHierarchy?.properties?.find(
          (property) => property.code === propertyId
        )?.id
      : propertyId) || propertyId;
  const getBaseUrl = () => {
    if (location.pathname.includes('website')) {
      return `/${currentApp?.AppId}/sales-channels/website/property/${propertyId}/sales-channel/${channelId}`;
    } else if (location.pathname.includes('properties')) {
      return `/${currentApp?.AppId}/properties/${propertyId}/sales-channel/${channelId}`;
    }
    return `/${currentApp?.AppId}/sales-channels/point-of-sale/property/${propertyId}/sales-channel/${channelId}`;
  };

  const { data, isLoading, error } = useQueryRMSSalesChannels(
    currentApp?.AppId || '',
    rmsPropertyId || ''
  );
  const salesChannel = data?.find((salesChannel) => salesChannel?.id === channelId) || null;
  const salesChannelName = salesChannel?.name || '';

  return (
    <PageLayout
      documentTitle="Sales_channels"
      toParent={() => {
        history.push(
          getBackToSalesChannelListUrl({ appId: currentApp?.AppId, location, propertyId })
        );
      }}
      title={
        <>
          <Translate id="Sales_Channel_Settings" /> {salesChannelName && ` - ${salesChannelName}`}
        </>
      }
    >
      <Content
        isLoading={isLoading || isLoadingCommonData}
        error={error || commonDataError}
        salesChannel={salesChannel}
        translate={translate}
        baseUrl={getBaseUrl()}
      />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
    currentApp: state.currentApp,
  };
};

export default connect(mapStateToProps)(SalesChannelSettings);
