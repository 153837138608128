import React, { useEffect } from 'react';

import { DynamicFormField } from '@flipdish/api-client-typescript';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FormikProvider, useFormik } from 'formik';
import { TranslateFunction } from 'react-localize-redux';

import { MessageBanner, SkeletonLoader } from '@fd/ui/atoms';
import { DynamicInputFields } from '@fd/ui/molecules/DynamicInputFields/DynamicInputFields';

export type DynamicAddressFormProps = {
  form?: DynamicFormField[];
  initialValues?: { [key: string]: any };
  loading?: boolean;
  error?: boolean;
  translate: TranslateFunction;
  disabled?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  onChange: (values: { [key: string]: any }) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  dynamicFieldSkeleton: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  banner: {
    borderRadius: '5px',
    backgroundColor: 'rgb(202, 0, 48)',
  },
  bannerContent: {
    color: 'rgb(255, 255, 255)',
  },
  form: {
    width: '100%',
  },
}));

export const DynamicAddressWithCallback = ({
  form,
  initialValues,
  loading = false,
  error = false,
  translate,
  disabled = false,
  onChange,
}: DynamicAddressFormProps) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: initialValues || {},
    enableReinitialize: true,
    onSubmit: (values) => {
      onChange(values);
    },
  });

  useEffect(() => {
    onChange(formik.values);
  }, [formik.values, onChange]);

  const renderFormBody = () => {
    if (error) {
      return (
        <MessageBanner
          titleClassName={classes.bannerContent}
          className={classes.banner}
          title={translate('User_creation_failed') as string}
          icon={<ErrorOutlineIcon className={classes.bannerContent} />}
          fdKey="dynamic-form-error"
        />
      );
    }

    if (loading) {
      return (
        <SkeletonLoader
          fdKey="skeleton-form"
          rows={[
            { height: '40px', width: '100%', className: classes.dynamicFieldSkeleton },
            { height: '40px', width: '100%', className: classes.dynamicFieldSkeleton },
            { height: '40px', width: '100%', className: classes.dynamicFieldSkeleton },
          ]}
        />
      );
    }

    if (!form) {
      return null;
    }

    return (
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <DynamicInputFields
            form={form}
            disabled={disabled}
            handleInputChange={formik.handleChange}
          />
        </form>
      </FormikProvider>
    );
  };

  return renderFormBody();
};
