import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import Search from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { useHistory } from 'react-router';
import { compose } from 'recompose';

import { Typography } from '@fd/ui/atoms';
import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import ListItemLinkButton from '@fd/ui/List/ListItemLinkButton';
import LoadingListItem from '@fd/ui/List/LoadingListItem';

import WebsiteSettingsIcon from '../../assets/images/website-edit.svg';
import WindowInfiniteScroll from '../../ui/WindowInfiniteScroll';
import FeatureUsage from '../Billing/Paywall/components/FeatureUsage';
import KioskListItem from './Components/KioskListItem';
import NoKiosks from './Components/NoKiosks';
import { getKiosks } from './Kiosks.actions';
import { getAddNewKioskUrl } from './Kiosks.selectors';
import { kioskServices } from './Kiosks.services';

const useStyles = makeStyles({
  kioskHeaders: {
    marginBottom: 24,
  },
  kioskSubHeading: {
    marginTop: 24,
    marginBottom: 16,
  },
  kioskLicenseUsage: {
    marginTop: 24,
  },
});
const kioskDeviceEntitlementCode = 'kiosk.device';

const Filter = () => {
  return (
    <Translate>
      {(translate) => (
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
              </InputAdornment>
            ),
          }}
          fullWidth
          variant="outlined"
          placeholder={translate('Search_for_device_type_name_serial_or_store').toString()}
        />
      )}
    </Translate>
  );
};
type Props = MappedDispatch & MappedProps;
const KioskList = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { getKioskList, isLoading, isKiosksEmpty, kiosks, totalRecords, appId, newKioskUrl } =
    props;

  const loadMore = async (page: number) => {
    try {
      getKioskList(page, 20);
    } catch (error) {
      console.error(error);
    }
  };

  const getKioskEntitlementUsage = async () => {
    const result = await kioskServices.getKioskDeviceEntitlements(appId as string);
    return {
      curentUsage: result.UsedQuantity ?? 0,
      entitlementQuantity: result.EntitledQuantity ?? 0,
    };
  };

  const navigateToAddKiosk = () => {
    history.push(newKioskUrl!);
  };

  return (
    <PageLayout
      title="Kiosks"
      documentTitle="Kiosks"
      auditLogsFilter={{ type: 'EventType', value: 'hydra.*' }}
      showActionButton
      showAddIcon
      actionBtnTitle="Add_kiosk"
      userPermissions={App.AppResourceSetEnum.UpdateHydraConfigManage.toString()}
      onClick={navigateToAddKiosk}
    >
      <Card>
        <ListItemLinkButton
          borderBottom="none"
          title="Kiosk_home_screen_customisation"
          subtitle="Add_images_to_the_Kiosk_home_screen"
          link={`/${appId}/kiosks/homescreen_customisation`}
          icon={WebsiteSettingsIcon}
        />
      </Card>
      <Permissions allowed={['FlipdishStaff']}>
        <Grid container spacing={2} className={classes.kioskLicenseUsage}>
          <Grid item xs={4}>
            <FeatureUsage
              entitlementCode={kioskDeviceEntitlementCode}
              getUsage={getKioskEntitlementUsage}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" component="p">
              *License usage is visible by Flipdish Staff Only
            </Typography>
          </Grid>
        </Grid>
      </Permissions>
      <Typography variant="h5" className={classes.kioskSubHeading}>
        <Translate id="Your_Linked_Kiosks" />
      </Typography>
      {!isKiosksEmpty ? (
        <Hidden xsUp>
          <Grid item xs={12} md={6} className={classes.kioskHeaders}>
            <Filter />
          </Grid>
        </Hidden>
      ) : null}
      {!isKiosksEmpty ? (
        <PaperContainer fluid>
          <WindowInfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            lock={isLoading}
            loader={isLoading ? LoadingListItem : undefined}
            hasMore={kiosks.length < totalRecords}
            initialLoad={true}
          >
            <List component="nav">
              {kiosks.map((kiosk, index) => {
                return (
                  <KioskListItem
                    isLast={index + 1 === kiosks.length}
                    key={`${kiosk.DeviceId}${index}`}
                    data-fd={kiosk.DeviceId}
                    kiosk={kiosk}
                    kioskId={kiosk.DeviceId}
                  />
                );
              })}
            </List>
          </WindowInfiniteScroll>
        </PaperContainer>
      ) : (
        <NoKiosks />
      )}
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { currentApp, kiosks } = state;

  return {
    kiosks: kiosks.kiosks,
    totalRecords: kiosks.totalRecords,
    isLoading: kiosks.isLoading,
    isKiosksEmpty: kiosks.isKiosksEmpty,
    appId: currentApp?.AppId,
    newKioskUrl: getAddNewKioskUrl(state),
  };
}

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    getKioskList: (page: number, limit: number) => {
      dispatch(getKiosks(page, limit));
    },
  };
}

export default compose<Props, {}>(connect(mapStateToProps, mapDispatchToProps))(KioskList);
