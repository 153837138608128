import React, { useEffect } from 'react';

import { type Org } from '@flipdish/orgmanagement';
import List from '@mui/material/List';
import { useQuery } from '@tanstack/react-query';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EmptyComponent from '@fd/ui/EmptyComponent';
import ErrorComponent from '@fd/ui/ErrorComponent';
import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import ListItemLinkButton from '@fd/ui/List/ListItemLinkButton';
import LoadingListItem from '@fd/ui/List/LoadingListItem';

import EmptyIcon from '../../../assets/images/empty_store_list.svg';
import { getOrgByBrandId } from '../../RMS/organisation.services';
import { setCurrentOrg } from '../../RMS/rms.actions';
import { getBrandsForOrg, getBrandsForOrgKey } from './brands.services';

const BrandLogoComponent = (props: { url?: string; brandName: string }) => {
  return <img src={props.url} width="40px" height="40px" alt={props.brandName} />;
};

type ContentProps = {
  ariaLabel: string;
  isLoadingOrg: boolean;
  orgId?: string;
  showEmpty: boolean;
  currentBrandId?: string;
};

const Content = ({ ariaLabel, isLoadingOrg, orgId, currentBrandId, showEmpty }: ContentProps) => {
  const {
    data: brandsResponse,
    isLoading: isLoadingBrands,
    error: brandsForOrgError,
  } = useQuery({
    queryKey: [getBrandsForOrgKey, orgId],
    queryFn: () => getBrandsForOrg(orgId ?? ''),
    enabled: !!orgId,
    retry: 1,
  });

  if (isLoadingBrands || isLoadingOrg) {
    return <LoadingListItem />;
  }
  if (brandsForOrgError) {
    return <ErrorComponent title="Something_went_wrong" />;
  }
  if (showEmpty) {
    return (
      <EmptyComponent
        title="No_orgs_found_for"
        titleData={{ searchTerm: currentBrandId ?? '' }}
        icon={EmptyIcon}
        noLink
      />
    );
  }
  if (!brandsResponse?.data.data?.length) {
    return (
      <EmptyComponent
        title="No_brands_found"
        titleData={{ orgId: orgId ?? '' }}
        icon={EmptyIcon}
        noLink
      />
    );
  }
  return (
    <List aria-label={ariaLabel}>
      {brandsResponse?.data?.data?.map((brand, idx) => (
        <ListItemLinkButton
          key={brand.brandId}
          customTitle={brand.name}
          link={`/${brand.brandId}/settings/brand`}
          iconComponent={<BrandLogoComponent url={brand.logoImageUrl} brandName={brand.name} />}
          iconMargin="8px 16px 8px 0"
          isLast={idx === (brandsResponse?.data?.data?.length || 0) - 1}
        />
      ))}
    </List>
  );
};

type Props = MappedProps & MappedDispatch;
const Brands = ({ currentBrandId, currentOrg, translate, setCurrentOrg }: Props) => {
  const { data: orgData, isLoading: isLoadingOrg } = useQuery({
    queryKey: [getOrgByBrandId, currentBrandId],
    queryFn: () => getOrgByBrandId(currentBrandId || ''),
    enabled: !currentOrg?.orgId && !!currentBrandId,
    retry: 1,
  });

  const orgId = orgData?.data?.data?.[0]?.orgId || currentOrg?.orgId;

  useEffect(() => {
    orgData?.data?.data?.[0] && setCurrentOrg(orgData?.data?.data?.[0]);
  }, [orgData]);

  const history = useHistory();

  return (
    <PageLayout
      actionBtnTitle={'Add_Brand'}
      documentTitle="Brands"
      showActionButton
      showAddIcon
      title={<Translate id="Brands" />}
      userPermissions={'Owner'}
      onClick={() => {
        history.push(`/${currentBrandId}/settings/brands/add`);
      }}
    >
      <PaperContainer fluid>
        <Content
          ariaLabel={translate('Brands') as string}
          currentBrandId={currentBrandId}
          isLoadingOrg={isLoadingOrg}
          orgId={orgId}
          showEmpty={!!currentBrandId && !orgId && !orgData?.data?.data?.length}
        />
      </PaperContainer>
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  currentBrandId: state.currentApp.AppId,
  currentOrg: state.rms.currentOrg,
  translate: getTranslate(state.locale),
});

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  setCurrentOrg: (org: Org) => dispatch(setCurrentOrg(org)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
