import { useEffect } from 'react';

import type { KeyPressType } from '@fd/customHooks/useKeyPress';
import type { Org } from '@flipdish/orgmanagement';

export function useKeyboardNavigation(
  orgs: Org[],
  refs: Array<React.RefObject<HTMLDivElement>>,
  keyPressed: KeyPressType,
  currentFocusedAppIndex: number,
  setCurrentFocusedAppIndex: (val: number) => void,
  toggleOrgDrawer: () => void
) {
  useEffect(() => {
    if (orgs.length) {
      // up arrow
      if (keyPressed.key === 'ArrowUp') {
        if (currentFocusedAppIndex === -1 || currentFocusedAppIndex === 0) {
          const lastElement = refs[refs.length - 1];
          setCurrentFocusedAppIndex(refs.length - 1);
          if (lastElement.current) {
            lastElement.current.focus();
          }
        } else {
          const newFocusedItem = refs[currentFocusedAppIndex - 1];
          setCurrentFocusedAppIndex(currentFocusedAppIndex - 1);
          if (newFocusedItem.current) {
            newFocusedItem.current.focus();
          }
        }
      }

      // down arrow
      if (keyPressed.key === 'ArrowDown') {
        if (currentFocusedAppIndex < refs.length - 1) {
          const newFocusedItem = refs[currentFocusedAppIndex + 1];
          setCurrentFocusedAppIndex(currentFocusedAppIndex + 1);
          if (newFocusedItem.current) {
            newFocusedItem.current.focus();
          }
        } else {
          setCurrentFocusedAppIndex(0);
          if (refs[0].current) {
            refs[0].current.focus();
          }
        }
      }
    }

    // ESC Key
    if (keyPressed.key === 'Escape') {
      toggleOrgDrawer();
    }
  }, [keyPressed]);
}
