import React, { useState } from 'react';

import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { formikValidate } from '../../../../helpers/validation';
import FileUpload from '../../../../ui/FileUpload';
import FormItemLayout from '../../../../ui/Forms/FormItemLayout';
import FormItem, { RenderProps } from '../../components/FormItem';

const fileReader = new FileReader();

const validateLogo = (value: File, setLogo: (data: string | null) => void) =>
  new Promise((resolve) => {
    const isNotValidExtension = formikValidate.extension(value, ['png', 'jpeg', 'jpg']);

    if (isNotValidExtension) {
      resolve(isNotValidExtension);
    }

    if (value) {
      fileReader.readAsDataURL(value);
      fileReader.onload = () => {
        if (fileReader.result) {
          setLogo(fileReader.result as string);
        }
      };
      fileReader.onerror = () => {
        setLogo(null);
        resolve(<Translate id="Error_please_try_again_later" />);
      };
    }
    //@ts-ignore
    resolve();
  });

type InnerProps = MappedProps;
type OuterProps = { displayBlankLogo: boolean };
type Props = InnerProps & OuterProps;
const LogoField = ({ displayBlankLogo, LogoImageUrl }: Props) => {
  const [logo, setLogo] = useState<null | string>(displayBlankLogo ? '' : LogoImageUrl || '');

  return (
    <FormItemLayout label={<Translate id="Logo" />}>
      <FormItem
        name="logo"
        // @ts-ignore
        validate={(value: File) => validateLogo(value, setLogo)}
      >
        {({ field: { name }, form: { setFieldValue } }: RenderProps) => (
          <div
            style={{
              width: 114,
              paddingTop: 17,
            }}
          >
            <FileUpload
              fdKey="logo"
              url={logo}
              onChange={(data?: File) => {
                setFieldValue(name, data);
                if (!data) {
                  setLogo(null);
                }
              }}
            />
          </div>
        )}
      </FormItem>
    </FormItemLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  LogoImageUrl: state.currentApp.LogoImageUrl,
});
export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(LogoField);
