import React from 'react';

import * as FlipdishApi from '@flipdish/api-client-typescript';
import { Channel } from '@flipdish/api-client-typescript';
import BankIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import Add from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import FinanceIcon from '@mui/icons-material/AttachMoneyOutlined';
import BarChartIcon from '@mui/icons-material/BarChartOutlined';
import CodeIcon from '@mui/icons-material/Code';
import DeveloperIcon from '@mui/icons-material/CodeOutlined';
import CreditCard from '@mui/icons-material/CreditCard';
import MenusIcon from '@mui/icons-material/FastfoodOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import AuditLogsIcon from '@mui/icons-material/ListOutlined';
import LocalTaxi from '@mui/icons-material/LocalTaxi';
import VouchersIcon from '@mui/icons-material/LoyaltyOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import TeammatesIcon from '@mui/icons-material/PeopleOutlined';
import PermPhoneMsgOutlined from '@mui/icons-material/PermPhoneMsgOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import WebsiteAndAppIcon from '@mui/icons-material/PhonelinkOutlined';
import DevicesIcon from '@mui/icons-material/PhonelinkSetup';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import OrdersIcon from '@mui/icons-material/ShoppingCartOutlined';
import StoresIcon from '@mui/icons-material/StoreOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import UpdateIcon from '@mui/icons-material/Update';

import appStoreIcon from '../../assets/images/Appstore.svg';
import campaignsIcon from '../../assets/images/campaigns.svg';
import { ReactComponent as CatalogIcon } from '../../assets/images/catalog.svg';
import generalSettingsIcon from '../../assets/images/General_settings.svg';
import customersIcon from '../../assets/images/ico_customers.svg';
import invoiceIcon from '../../assets/images/Invoice.svg';
import ledgerIcon from '../../assets/images/Ledger.svg';
import payoutsIcon from '../../assets/images/Payouts.svg';
import { ReactComponent as ProductIcon } from '../../assets/images/Products.svg';
import salesChannelsIcon from '../../assets/images/sales-channels-icon.svg';
import EntitlementsIcon from '../../assets/images/StickyNote.svg';
import { KioskIcon } from '../../components/Kiosks/Components/KioskNestedMenuIcon';
import { RMSSubscriptions } from '../../components/RMS/utils/rmsSubscriptions';
import GeneralSettingsIcon from '../../components/Settings/components/GeneralSettingsIcon';
import { store } from '../../helpers/store';
import { MarketingIcon } from '../../icons/MarketingIcon';
import { isSubscriptionsBillingEnabled } from '../../selectors/app.selector';
import { flagService } from '../../services/flagService/flagService';

export type ItemMetaData = {
  hidden?: boolean;
  icon: JSX.Element;
  iconAnimated?: JSX.Element;
  isBeta?: boolean;
  path: string;
  permissions?: AppState['permissions'];
  step?: number;
  title: TranslationId;
  subItems?: Array<{
    title: TranslationId;
    path: string;
    icon?: React.ReactElement;
    hidden?: boolean;
    isBeta?: boolean;
    keepQuery?: boolean;
    permissions?: AppState['permissions'];
  }>;
  subDrawerItems?: Array<{
    title: TranslationId;
    path: string;
    icon?: React.ReactElement;
    hidden?: boolean;
    isBeta?: boolean;
    keepQuery?: boolean;
    permissions?: AppState['permissions'];
  }>;
};

export const menuItemsConfig = (
  currentApp: FlipdishApi.App,
  isProductBasedMenusOn: boolean,
  subscriptions = Array<RMSSubscriptions>()
): ItemMetaData[] => {
  const { AppId: currentAppId, Features, CountryId } = currentApp;
  const state = store.getState();
  const addChannelFlag = flagService.isFlagOn(state, 'addChannel');
  const showMenuEditor = flagService.isFlagOn(state, 'showMenuEditor');
  const showPropertiesScreen = flagService.isFlagOn(state, 'showPropertiesScreen');
  const showBrandList = flagService.isFlagOn(state, 'showBrandList');
  const showSalesChannelGroupList = flagService.isFlagOn(state, 'showSalesChannelGroups');

  const showBilling =
    isSubscriptionsBillingEnabled(state as AppState) && currentAppId !== 'flipdish-global';
  const showPayGreen = flagService.isFlagOn(store.getState(), 'paygreenFeatures');

  const paygreenCountries = ['FR', 'BE'];
  const hasAddOns = subscriptions.length > 0;
  const links: ItemMetaData[] = [
    {
      title: 'Home',
      path: `/${currentAppId}/home`,
      icon: <HomeIcon />,
    },
    {
      title: 'Customers',
      path: `/${currentAppId}/customers`,
      icon: <img src={customersIcon} alt="Customers" />,
      isBeta: false,
      permissions: ['ViewCustomerStatistics'],
    },
    {
      title: 'Orders',
      path: `/${currentAppId}/orders`,
      icon: <OrdersIcon />,
      permissions: ['ViewOrders'],
    },
    {
      title: 'Sales',
      path: `/${currentAppId}/sales`,
      icon: <ReceiptIcon />,
      permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
      hidden: !subscriptions.includes(RMSSubscriptions.BASE),
    },
    {
      title: 'Properties',
      path: `/${currentAppId}/properties`,
      icon: <StoresIcon />,
      permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
      hidden: !showPropertiesScreen,
    },
    {
      title: 'Menus',
      path: `/${currentAppId}/menus`,
      icon: <MenusIcon />,
      hidden: showMenuEditor,
      permissions: ['ViewMenu'],
    },
    {
      title: 'Menu_Editor',
      path: `/${currentAppId}/menu-editor`,
      icon: <MenusIcon />,
      hidden: !showMenuEditor,
      permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
    },
    {
      title: 'Catalog',
      path: '',
      icon: <CatalogIcon />,
      isBeta: true,
      hidden: !isProductBasedMenusOn,
      subItems: [
        {
          title: 'Products',
          path: `/${currentAppId}/products`,
          hidden: !isProductBasedMenusOn,
          icon: <ProductIcon color="rgb(108, 107, 107)" />,
          isBeta: true,
        },
        {
          title: 'Modifier_Groups',
          path: `/${currentAppId}/modifier-groups`,
          hidden: !isProductBasedMenusOn,
          icon: <ProductIcon color="rgb(108, 107, 107)" />,
          isBeta: true,
        },
      ],
    },
    {
      title: 'Marketing',
      path: '',
      icon: <MarketingIcon />,
      subItems: [
        {
          title: 'Push_Notifications',
          path: `/${currentAppId}/push_notifications`,
          icon: <NotificationsIcon />,
        },
        {
          title: 'Flipdish_Phone_Marketing',
          path: `/${currentAppId}/telephony/dashboards/global/flipdish_phone_marketing_fpm_report`,
          icon: <PermPhoneMsgOutlined />,
        },
      ],
    },
    {
      title: 'Sales_channels',
      path: `/${currentAppId}/sales-channels`,
      icon: <img src={salesChannelsIcon} />,
      permissions: ['ViewSalesChannels', 'EditSalesChannels', 'ViewHydraConfig'],
      subItems: [
        {
          title: 'Add_Channel',
          path: `/${currentAppId}/sales-channels/add-channel`,
          icon: <Add />,
          hidden: !addChannelFlag,
          permissions: ['Owner'],
        },
        {
          title: 'Point_of_Sale',
          path: `/${currentAppId}/sales-channels/point-of-sale`,
          icon: <TvOutlinedIcon />,
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
          hidden: !subscriptions.includes(RMSSubscriptions.BASE),
        },
        {
          title: 'Mobile_apps',
          path: `/${currentAppId}/sales-channels/mobile-apps`,
          icon: <PhoneIphoneIcon />,
          permissions: ['ViewSalesChannels', 'EditSalesChannels'],
        },
        {
          title: 'Website',
          path: `/${currentAppId}/sales-channels/website`,
          icon: <WebsiteAndAppIcon />,
          permissions: ['EditSalesChannels'],
        },
        {
          title: 'Kiosks',
          path: `/${currentAppId}/kiosks`,
          icon: <KioskIcon />,
          permissions: ['ViewHydraConfig'],
        },
      ],
    },

    {
      title: 'Reports',
      path: `/${currentAppId}/reports/dashboards`,
      icon: <BarChartIcon />,
    },
    {
      title: 'Stores',
      path: `/${currentAppId}/storegroups`,
      icon: <StoresIcon />,
    },
    {
      title: 'Vouchers',
      path: `/${currentAppId}/vouchers`,
      icon: <VouchersIcon />,
      permissions: ['ViewVouchers'],
    },
    {
      title: 'Campaigns',
      path: `/${currentAppId}/campaigns`,
      icon: <img src={campaignsIcon} />,
      permissions: ['ViewCampaignsConfigurations'],
      isBeta: false,
      hidden: Features?.includes('disable-campaign'),
    },
    {
      title: 'Terminals',
      path: `/${currentAppId}/terminals`,
      icon: <DevicesIcon />,
      permissions: ['ViewHydraConfig'],
    },
    {
      title: 'App_Store',
      path: `/${currentAppId}/appstore`,
      icon: <img src={appStoreIcon} />,
      permissions: ['Owner'],
    },
    {
      title: 'Settings',
      path: '',
      icon: <GeneralSettingsIcon />,
      permissions: ['Owner', 'ManagedOwner'],
      subDrawerItems: [
        {
          title: 'Organization',
          path: `/${currentAppId}/settings/organisation`,
          icon: <RoomPreferencesOutlinedIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.BASE),
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'Brand',
          path: `/${currentAppId}/settings/brands`,
          icon: <img src={generalSettingsIcon} />,
          hidden: !showBrandList,
        },
        {
          title: 'Brand',
          path: `/${currentAppId}/settings/brand`,
          icon: <img src={generalSettingsIcon} />,
          hidden: showBrandList,
        },
        {
          title: 'Teammates',
          path: `/${currentAppId}/teammates`,
          icon: <TeammatesIcon />,
          permissions: ['EditTeammates', 'ViewTeammates'],
        },
        {
          title: 'Metafields',
          path: `/${currentAppId}/settings/metafields`,
          icon: <CodeIcon />,
          hidden: !isProductBasedMenusOn,
        },
        {
          title: 'Sales_channels',
          path: `/${currentAppId}/settings/sales-channels`,
          icon: <img src={generalSettingsIcon} />,
          permissions: ['ViewSalesChannels', 'EditSalesChannels'],
        },
        {
          title: 'Sales_channel_groups',
          path: `/${currentAppId}/settings/sales-channel-groups`,
          icon: <img src={generalSettingsIcon} />,
          permissions: ['ViewSalesChannels', 'EditSalesChannels'],
          hidden: !showSalesChannelGroupList,
        },
        {
          title: 'Audit_logs',
          path: `/${currentAppId}/logs`,
          icon: <AuditLogsIcon />,
          permissions: [
            'ViewAppAuditLogs',
            'ViewBankAccountAuditLogs',
            'ViewCampaignsAuditLogs',
            'ViewCustomerAuditLogs',
            'ViewFeeConfigurationsAuditLogs',
            'ViewHydraAuditLogs',
            'ViewMenuAuditLogs',
            'ViewOrdersAuditLogs',
            'ViewPrinterAuditLogs',
            'ViewPushNotificationAuditLogs',
            'ViewStoreAuditLogs',
            'ViewTeammatesAuditLogs',
            'ViewUserEventsAuditLogs',
            'ViewVouchersAuditLogs',
            'ViewApmAuditLogs',
          ],
        },
      ],
    },
    {
      title: 'Finance',
      path: `/${currentAppId}/finance`,
      icon: <FinanceIcon />,
      permissions: ['ViewBankAccounts', 'FlipdishStaff'],
      subItems: [
        {
          title: 'Payouts',
          path: `/${currentAppId}/finance/payouts`,
          icon: <img src={payoutsIcon} />,
          isBeta: true,
        },
        {
          title: 'Invoices',
          path: `/${currentAppId}/finance/invoices`,
          icon: <img src={invoiceIcon} />,
          hidden: Features?.includes('invoices-menu-item') ? false : true,
        },
        {
          title: 'Bank_accounts',
          path: `/${currentAppId}/finance/bank-accounts`,
          icon: <BankIcon />,
        },
        {
          title: 'Restaurant_vouchers',
          path: `/${currentAppId}/finance/restaurant-vouchers`,
          icon: <CreditCard />,
          permissions: ['Owner', 'StoreOwner', 'ManagedOwner', 'FinanceManger'],
          hidden: !showPayGreen || !paygreenCountries.includes(CountryId || ''),
        },
        {
          title: 'Ledger',
          path: `/${currentAppId}/finance/ledger`,
          icon: <img src={ledgerIcon} />,
          hidden: Features?.includes('ledger-menu-item') ? false : true,
        },
      ],
    },
    {
      title: 'Billing_and_Invoices',
      path: `/${currentAppId}/billing`,
      icon: <CreditCard />,
      permissions: [
        'Owner',
        'StoreOwner',
        'StoreManager',
        'ManagedOwner',
        'FinanceManger',
        'FlipdishStaff',
      ],
      hidden: !showBilling,
      subItems: [
        {
          title: 'Subscriptions',
          path: `/${currentAppId}/billing/subscriptions`,
          icon: <UpdateIcon />,
        },
        {
          title: 'Invoices',
          path: `/${currentAppId}/billing/invoices`,
          icon: <img src={invoiceIcon} />,
        },
      ],
    },
    {
      title: 'Add_ons',
      path: 'Add_ons',
      icon: <KeyboardArrowDown />,
      iconAnimated: <KeyboardArrowUp />,
      hidden: !hasAddOns,
      subItems: [
        {
          title: 'Accounts',
          path: `/${currentAppId}/add-ons/accounts`,
          icon: <AccountBalanceWalletOutlinedIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.ACCOUNTS),
          isBeta: true,
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'Documents',
          path: `/${currentAppId}/add-ons/documents`,
          icon: <PlagiarismOutlinedIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.DOCUMENTS),
          isBeta: true,
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'FoodSafety',
          path: `/${currentAppId}/add-ons/food-safety`,
          icon: <PlagiarismOutlinedIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.FOOD_SAFETY),
          isBeta: true,
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'Inventory',
          path: `/${currentAppId}/add-ons/inventory`,
          icon: <AssignmentIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.INVENTORY),
          isBeta: true,
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'Payroll',
          path: `/${currentAppId}/add-ons/payroll`,
          icon: <GroupsOutlinedIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.PAYROLL),
          isBeta: true,
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
      ],
    },
    {
      title: 'More',
      path: 'More',
      icon: <KeyboardArrowDown />,
      iconAnimated: <KeyboardArrowUp />,
      subItems: [
        {
          title: 'Entitlements',
          path: `/${currentAppId}/entitlements`,
          icon: <img src={EntitlementsIcon} />,
          permissions: ['FlipdishStaff', 'ShowHiddenFeatures'],
        },
      ],
    },
  ];

  return links;
};

export const menuItemsConfigMobile = (currentAppId: string | undefined): ItemMetaData[] => {
  return [
    {
      title: 'My_account',
      path: `/${currentAppId}/profile`,
      icon: <PersonIcon />,
    },
    {
      title: 'developer_tools',
      path: `/${currentAppId}/developers`,
      icon: <DeveloperIcon />,
    },
  ];
};

export const parseChannelsToSideMenuItems = (channels: Channel[], currentApp?: string) => {
  if (!currentApp) return [];

  const menuItems: ItemMetaData[] = [];
  channels?.forEach((assignedChannel) =>
    menuItems.push({
      title: `${assignedChannel.TranslationKey}_External` as TranslationId,
      path: `/${currentApp}/sales-channels/${assignedChannel.ChannelId}`,
      icon: <LocalTaxi />, // figure out a way to make this fully dynamic
      permissions: ['Owner'],
    })
  );

  return menuItems;
};
