import React from 'react';

import { SalesChannelType } from '@fd/customHooks/useQueryRMSSalesChannels';
import type { CreateSalesChannel } from '@flipdish/orgmanagement';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import { notifyError, NotifyProps, notifySaved } from '../../../layouts/Notify/actions';
import { getStorefrontsKey } from '../rms.services';
import SalesChannelService from '../salesChannels.services';
import SalesChannelForm from './SalesChannelForm';
import { getBackToSalesChannelListUrl } from './utils';

type AddSalesChannelProps = MappedState & MappedDispatch;
const AddSalesChannel = (props: AddSalesChannelProps) => {
  const { appId, translate, orgId, dispatchNotifyError, dispatchNotifySaved } = props;
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (variables: { propertyId: string; salesChannel: CreateSalesChannel }) =>
      SalesChannelService.createSalesChannel({
        orgId: orgId,
        propertyId: variables?.propertyId,
        salesChannel: variables?.salesChannel,
      }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [getStorefrontsKey] });
      dispatchNotifySaved();
      history.push(getBackToSalesChannelListUrl({ appId, location }));
    },

    onError: (error) => {
      dispatchNotifyError({ message: error?.message || 'Something_went_wrong' });
    },
  });

  let defaultSalesChannelType = SalesChannelType.POS;
  if (location.pathname.includes('website')) {
    defaultSalesChannelType = SalesChannelType.FLIPDISH_WEB_APP;
  }

  return (
    <PageLayout
      documentTitle={'Add_new_sales_channel'}
      title={translate('Add_new_sales_channel')}
      toParent={'../../'}
    >
      <PaperContainer>
        <SalesChannelForm
          translate={translate}
          appId={appId}
          handleSave={mutateAsync}
          defaultSalesChannelType={defaultSalesChannelType}
        />
      </PaperContainer>
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaved: () => dispatch(notifySaved()),
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => ({
  appId: state.currentApp?.AppId,
  translate: getTranslate(state.locale),
  orgId: state.rms?.currentOrg?.orgId,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSalesChannel);
