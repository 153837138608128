import * as React from 'react';

import type { Property } from '@flipdish/orgmanagement';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { compose, setDisplayName } from 'recompose';

import MapCard from '../../StoreGroups/components/MapCard';

export const Caption = ({ propertyData }) => {
  const address = propertyData?.address;
  return (
    <div>
      <span data-fd="property_card_address_caption">{address}</span>
    </div>
  );
};

// Memoized function to generate map query parameters for the property location
const mapProps = memoize((propertyData) => {
  if (propertyData && propertyData.coordinates) {
    const { latitude, longitude } = propertyData.coordinates;
    return `zoom=9&center=${latitude},${longitude}&markers=${latitude},${longitude}`;
  }
  return undefined;
});

type Props = {
  propertyData: Property;
};

const PropertyCard: React.FC<Props> = ({ propertyData }) => {
  const match = useRouteMatch('/:appId/properties');
  const { propertyId, name } = propertyData;

  return (
    <MapCard
      fdKey={`property_${propertyId}`}
      title={name || ''}
      href={`${match?.url}/${propertyId}`}
      mapQueryProps={mapProps(propertyData)}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Caption propertyData={propertyData} />
      </div>
    </MapCard>
  );
};

export default compose<Props, Props>(setDisplayName('PropertyCard'), connect())(PropertyCard);
