import { Menu, MenusApiFactory, MenuTaxDetails } from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../helpers/apibase';
import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const menusApi = createApi(MenusApiFactory);

const baseUrl = getApiEndPoint();
const extraApi = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

async function getMenus(appId: string) {
  try {
    const incomingMessage = await menusApi.getMenusByAppId(appId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function getMenu(menuId: number) {
  try {
    const incomingMessage = await menusApi.getMenuById(menuId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

function getMenuStores(menuId: number) {
  return menusApi
    .getMenuStoreNames(menuId)
    .then((response) => {
      return Promise.resolve(response as unknown as { StoreNames: string[] });
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getMenuCheckpoints(menuId: number) {
  return menusApi
    .getMenusCheckpoints(menuId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getMenuTaxDetails(menuId: number) {
  return menusApi
    .getMenuTaxDetails(menuId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function createMenu(appId: string) {
  return menusApi
    .createNewMenuForApp(appId, { DisplaySectionLinks: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function uploadMenu(appId: string, menu: FormData) {
  try {
    await extraApi.post(`/api/v1.0/${appId}/menus/xlsx`, menu);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

function deleteMenu(menuId: number) {
  return menusApi
    .deleteMenu(menuId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function duplicateMenu(menuId: number, newName: string) {
  return menusApi
    .createDraftMenuFromExistingMenu(menuId, newName)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function menusShowHideBulkItems(menuId: number, isAvailable: boolean, menuElements) {
  return menusApi
    .menusShowHideBulkItems(menuId, isAvailable, menuElements)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getBulkShowHideList(menuId: number, isAvailable: boolean) {
  return menusApi
    .menusGetMenuBulkShowHide(menuId, isAvailable)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function renameMenu(menuId: number, name: string) {
  try {
    await menusApi.setMenuName(menuId, name);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

function restoreMenuCheckpoint(menuId: number, checkpointId: number) {
  return menusApi
    .restoreAMenuCheckpoint(menuId, checkpointId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function lockMenu(menuId: number, locked: boolean) {
  return extraApi
    .post(`/api/v1.0/menus/${menuId}/lock`, locked, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function toggleMenuSectionBehaviour(menu: Menu) {
  const newMenu = { ...menu };
  newMenu.MenuSectionBehaviour =
    menu.MenuSectionBehaviour === Menu.MenuSectionBehaviourEnum.ExpandMultiple
      ? Menu.MenuSectionBehaviourEnum.ExpandSingle
      : Menu.MenuSectionBehaviourEnum.ExpandMultiple;

  return menusApi
    .updateMenu(menu.MenuId || 0, newMenu)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function includeMenuTax(menuId: number, includeTax: MenuTaxDetails.TaxTypeEnum) {
  return menusApi
    .menusUpdateTaxType(
      menuId,
      includeTax === MenuTaxDetails.TaxTypeEnum.IncludedInBasePrice
        ? 'IncludedInBasePrice'
        : 'ExcludedFromBasePrice'
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function showMenuTax(menuId: number, showTax: boolean) {
  return menusApi
    .menusSetDisplayOnMenuTax(menuId, showTax)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function addMenuTax(menuId: number, name: string, rate: number) {
  return menusApi
    .menusUpsertTaxRate(menuId, { Name: name, Rate: rate })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function updateMenuTax(menuId: number, taxRateId: number, name: string, rate: number) {
  return menusApi
    .menusUpsertTaxRate(menuId, { TaxRateId: taxRateId, Name: name, Rate: rate })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function deleteMenuTax(menuId: number, taxId: number) {
  return menusApi
    .menusDeleteTaxRate(menuId, taxId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function downloadMenuJson(menuUrl: string) {
  try {
    const { data } = await axios.get(menuUrl);
    return data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function addCatalogItemToSection(menuId: number, menuSectionId: number, item: any) {
  try {
    await extraApi.post(
      `/api/v1.0/menus/${menuId}/sections/${menuSectionId}/sectionitems/add-catalogitems`,
      {
        MenuCatalogItems: [item],
      }
    );
  } catch (e) {
    console.log(e);
  }
}

export const menusService = {
  addCatalogItemToSection,
  addMenuTax,
  createMenu,
  deleteMenu,
  deleteMenuTax,
  downloadMenuJson,
  duplicateMenu,
  getBulkShowHideList,
  getMenu,
  getMenuCheckpoints,
  getMenus,
  getMenuStores,
  getMenuTaxDetails,
  includeMenuTax,
  lockMenu,
  menusShowHideBulkItems,
  renameMenu,
  restoreMenuCheckpoint,
  showMenuTax,
  toggleMenuSectionBehaviour,
  updateMenuTax,
  uploadMenu,
};
