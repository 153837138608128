import React from 'react';

import List from '@mui/material/List';
import { useQuery } from '@tanstack/react-query';
import { TranslateFunction } from 'react-localize-redux';

import EmptyComponent from '@fd/ui/EmptyComponent';
import ErrorComponent from '@fd/ui/ErrorComponent';
import ListItemLinkButton from '@fd/ui/List/ListItemLinkButton';
import LoadingListItem from '@fd/ui/List/LoadingListItem';

import EmptyIcon from '../../../../assets/images/empty_store_list.svg';
import { storeGroupService } from '../../../../services/storegroup.service';
import { CustomSubTitle } from './CustomSubTitle';

export type SalesChannelGroupListProps = {
  ariaLabel: TranslationId;
  orgId?: string;
  currentBrandId?: string;
  translate: TranslateFunction;
};

const SalesChannelGroupList = ({
  ariaLabel,
  orgId,
  currentBrandId,
  translate,
}: SalesChannelGroupListProps) => {
  const {
    data: salesChannelGroup,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['getSalesChannelGroup', currentBrandId],
    queryFn: () => storeGroupService.getAllForApp({ appId: currentBrandId ?? '' }),
    staleTime: 2 * 60 * 1000,
    enabled: !!currentBrandId,
  });

  if (isLoading) {
    return <LoadingListItem />;
  }
  if (error) {
    return <ErrorComponent title="Something_went_wrong" />;
  }

  if (!salesChannelGroup?.Data?.length) {
    return (
      <EmptyComponent
        title="No_sales_channel_group_found"
        titleData={{ orgId: orgId ?? '' }}
        icon={EmptyIcon}
        noLink
      />
    );
  }

  return (
    <List aria-label={translate(ariaLabel) as string}>
      {salesChannelGroup?.Data?.map((sc, idx) => (
        <ListItemLinkButton
          key={sc.StoreGroupId}
          customTitle={sc.Name}
          link={`/${currentBrandId}/settings/sales-channel-groups/${sc.StoreGroupId}`}
          isLast={idx === (salesChannelGroup?.Data?.length || 0) - 1}
          customSubtitle={
            <CustomSubTitle line1={sc.Currency as unknown as string} line2={sc.TotalStores} />
          }
        />
      ))}
    </List>
  );
};

export default SalesChannelGroupList;
