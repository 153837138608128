import React, { useCallback, useEffect } from 'react';

import { RestaurantVoucherPayGreenConfiguration } from '@flipdish/api-client-typescript';
import List from '@mui/material/List';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import EmptyComponent from '@fd/ui/EmptyComponent';
import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import LoadingListItem from '@fd/ui/List/LoadingListItem';

import { notifyError } from '../../../../layouts/Notify/actions';
import { permissionsSelector } from '../../../../selectors/permissions.selector';
import AddRestaurantVoucherLink from '../components/AddRestaurantVoucherLink';
import RestaurantVouchersListItem from '../components/RestaurantVouchersListItem';
import { restaurantVouchersService } from '../RestaurantVouchers.service';

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'Owner',
    'StoreOwner',
    'ManagedOwner',
    'FinanceManger',
  ]);
  return (state: AppState) => {
    return {
      currentAppId: state.currentApp.AppId,
      hasEditPermission: getPermissionsSelector(state),
    };
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  button: { margin: theme.spacing(1), textAlign: 'center' },
}));

const RestaurantVouchersList = (props: MappedState) => {
  const { currentAppId, hasEditPermission } = props;

  const handleClick = useCallback((restaurantVoucher: RestaurantVoucherPayGreenConfiguration) => {
    if (hasEditPermission && !restaurantVoucher.PayGreenConfigurationId) {
    }
  }, []);

  const { data, isPending, isSuccess, isError } = useQuery({
    queryKey: [restaurantVouchersService.getPayConfig, currentAppId],
    queryFn: () => restaurantVouchersService.getRestaurantVouchers(currentAppId!),
  });
  useEffect(() => {
    if (isError) {
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    }
  }, [isError]);

  return (
    <PageLayout
      caption={<Translate id="Allow_customers_to_pay_with_PayGreen" />}
      documentTitle="Restaurant_vouchers"
      title={<Translate id="Restaurant_vouchers" />}
    >
      <AddRestaurantVoucherLink />

      <PaperContainer fluid>
        <List component="nav">
          {!isPending && isSuccess && data?.length == 0 && (
            <EmptyComponent title="no_restaurant_vouchers_found_heading" />
          )}
          {isPending ? (
            <LoadingListItem />
          ) : (
            data &&
            data.map((restaurantVoucher, index: number) => {
              return (
                <RestaurantVouchersListItem
                  restaurantVoucher={restaurantVoucher}
                  currentAppId={currentAppId}
                  data-fd={restaurantVoucher.PayGreenConfigurationId}
                  isLast={index + 1 === data.length}
                  key={restaurantVoucher.PayGreenConfigurationId}
                  onClick={handleClick}
                />
              );
            })
          )}
        </List>
      </PaperContainer>
    </PageLayout>
  );
};

export default compose<Props, {}>(connect(mapStateToPropsFactory))(RestaurantVouchersList);
