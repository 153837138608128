import { useQueries, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getAllPropertiesForOrg } from '../../Properties/properties.services';
import { getStoreIdAssociations } from '../../RMS/rms.services';
import { getSalesChannelsByOrgAndPropertyId } from './salesChannels.services';

const useStoreToSalesChannelTransformer = () => {
  const orgId = useSelector((state: AppState) => state.rms.currentOrg?.orgId);

  const { data: properties, isLoading: isPropertiesLoading } = useQuery({
    queryKey: [''],
    queryFn: () => getAllPropertiesForOrg(orgId ?? ''),
    enabled: !!orgId,
  });

  const propertyIds = properties?.data.data?.map((p) => p.propertyId);

  const salesChannellQueries = (propertyIds || []).map((id) => ({
    queryKey: ['getSalesChannelsByOrgAndPropertyId', id ?? ''],
    queryFn: () => getSalesChannelsByOrgAndPropertyId(orgId ?? '', id ?? ''),
    enabled: !!properties,
  }));

  const salesChannels = useQueries({ queries: salesChannellQueries });
  const isSalesChannelsLoading = salesChannels.some((salesChannels) => salesChannels.isLoading);

  const salesChannelOptions = salesChannels.reduce<
    Array<{ name?: string; value?: string; scId?: string }>
  >((acc, sc) => {
    const data = sc.data?.data?.data;

    if (data) {
      return acc.concat(data.map((d) => ({ name: d.name, scId: d.salesChannelId })));
    }
    return acc;
  }, []);

  const storeIdAssociationQueries = (salesChannelOptions || []).map((sc) => ({
    queryKey: ['getStoreIdAssociations', sc.scId ?? ''],
    queryFn: async () => {
      const response = await getStoreIdAssociations(sc.scId ?? '');
      return {
        storeId: response.data.flipdish.storeId,
        salesChannelId: sc.scId,
        salesChannelName: sc.name,
      };
    },
    enabled: !!salesChannelOptions.length,
  }));

  const storeIdAssociations = useQueries({ queries: storeIdAssociationQueries });
  const isstoreIdAssociationsLoading = storeIdAssociations.some((sIdAssoc) => sIdAssoc.isLoading);
  const storeIdAssociationsData = storeIdAssociations.map((q) => q.data);

  return {
    isLoading: isPropertiesLoading || isSalesChannelsLoading || isstoreIdAssociationsLoading,
    storeIdAssociations: storeIdAssociationsData,
  };
};

export default useStoreToSalesChannelTransformer;
