import { AccountDetail, SupportedCountry } from '@flipdish/api-client-typescript';

import {
  LoginCreatePasswordFailure,
  LoginCreatePasswordRequest,
  LoginCreatePasswordSuccess,
  LoginSsoRequesSuccess,
  LoginViaEmailFailure,
  LoginViaEmailSuccess,
  LoginWithPinRequesFailure,
  setGdprSelection,
  UpdateFailure,
} from '../actions/account.actions';
import { accountConstants } from '../constants/account.constants';
import { DeviceId, UUID } from '../helpers/utilities';

export interface AccountState extends AccountDetail {
  authorized: boolean;
  DeviceId?: string;
  SessionId?: string;
  error?: Error;
  ssoLoginError?: string;
  auth0LoginError?: string;
  gdpr?: Parameters<typeof setGdprSelection>[0];
  pinSent?: boolean;
  supportedCountries?: SupportedCountry[];
  TokenId?: string;
  updateFailure?: boolean;
  updatePasswordSuccess?: boolean;
  updateSuccess?: boolean;

  // TODO: move me somewhere... anywhere but here
  timeZones?: any;

  // DEPRECATED
  email?: string;
  submitting?: boolean;
}
const defaultState: AccountState = {
  authorized: false,
  DisplayTimesInUserLocalTimeZone: true,
  DeviceId: DeviceId(),
  SessionId: UUID(),
  Email: '',
  gdpr: undefined,
  IsSelfServeUser: false,
  IsVerified: false,
  Language: '',
  Name: '',
  SignupSteps: [],
  supportedCountries: [],
  TimeZoneInfoId: '',
  TokenId: '',
  updateSuccess: false,
};

const defaultError = 'Error_please_try_again_later';

const {
  LOGIN_REQUEST_PIN_REQUEST,
  LOGIN_REQUEST_PIN_SUCCESS,
  LOGIN_REQUEST_PIN_FAILURE,
  LOGIN_WITH_PIN_REQUEST,
  LOGIN_WITH_PIN_SUCCESS,
  LOGIN_WITH_PIN_FAILURE,
  LOGIN_SSO_REQUEST,
  LOGIN_SSO_FAILURE,
  LOGIN_SSO_SUCCESS,
  LOGIN_AUTH0_FAILURE,
  CREATE_PASSWORD_REQUEST,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAILURE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILURE,
  GET_SUPPORTED_COUNTRIES,
} = accountConstants;

export function account(state = defaultState, action): AccountState {
  switch (action.type) {
    case UPDATE_SUCCESS:
      return {
        ...state,
        updateSuccess: true,
      };
    case UPDATE_FAILURE:
      return {
        ...state,
        updateFailure: true,
        error: (action as UpdateFailure).payload,
      };
    case LOGIN_REQUEST_PIN_REQUEST:
      return {
        ...state,
        pinSent: undefined,
        error: undefined,
      };
    case LOGIN_REQUEST_PIN_SUCCESS:
      return {
        authorized: false,
        DeviceId: DeviceId(),
        Email: (action as LoginViaEmailSuccess).payload.email,
        pinSent: (action as LoginViaEmailSuccess).payload.pinSent,
        error: undefined,
        email: (action as LoginViaEmailSuccess).payload.email,
      };
    case LOGIN_REQUEST_PIN_FAILURE:
      return {
        ...state,
        pinSent: undefined,
        error: (action as LoginViaEmailFailure).payload,
      };
    case accountConstants.LOGIN_RESET:
      return {
        DeviceId: DeviceId(),
        authorized: false,
        error: undefined,
      };
    case LOGIN_SSO_REQUEST:
    case LOGIN_WITH_PIN_REQUEST:
      return {
        ...state,
        authorized: false,
        error: undefined,
        ssoLoginError: undefined,
      };
    case LOGIN_SSO_SUCCESS:
      return {
        ...state,
        Email: (action as LoginSsoRequesSuccess).payload,
        authorized: true,
        error: undefined,
        ssoLoginError: undefined,
      };
    case LOGIN_WITH_PIN_SUCCESS:
      return {
        ...state,
        authorized: true,
        error: undefined,
      };
    case LOGIN_SSO_FAILURE:
      return {
        ...state,
        ssoLoginError: 'SSO_FAILURE',
      };
    case LOGIN_AUTH0_FAILURE:
      return {
        ...state,
        auth0LoginError: 'AUTH0_FAILURE',
      };
    case LOGIN_WITH_PIN_FAILURE:
      return {
        ...state,
        error: (action as LoginWithPinRequesFailure).payload,
      };
    case accountConstants.CREATE_PASSWORD_WITH_PIN_SUCCESS:
      return { ...state, error: undefined, updatePasswordSuccess: true };
    case accountConstants.CREATE_PASSWORD_WITH_PIN_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
      };
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        authorized: true,
        SessionId: UUID(),
        updateFailure: false,
        updateSuccess: false,
        updatePasswordSuccess: false,
      };
    case GET_ACCOUNT_FAILURE:
      return {
        ...state,
        authorized: false,
        error: action.payload,
      };
    case accountConstants.LOGOUT_FAILURE:
      return {
        ...defaultState,
      };
    case accountConstants.SETUP_SURVEY:
      return {
        ...state,
        ...action.account,
      };
    case accountConstants.SKIP_SIGNUP_STEP_REQUEST:
    case accountConstants.SKIP_SIGNUP_STEP_SUCCESS:
    case accountConstants.SKIP_SIGNUP_STEP_FAILURE:
    case accountConstants.ANSWER_SIGNUP_QUESTION_REQUEST:
    case accountConstants.ANSWER_SIGNUP_QUESTION_SUCCESS:
    case accountConstants.ANSWER_SIGNUP_QUESTION_FAILURE:
      return {
        ...state,
        ...action.account,
      };
    case accountConstants.RESET_PASSWORD_RESET:
      return {
        DeviceId: DeviceId(),
        authorized: false,
        submitting: false,
        error: undefined,
      };
    case CREATE_PASSWORD_REQUEST:
      return {
        ...state,
        TokenId: (action as LoginCreatePasswordRequest).payload,
        error: undefined,
      };
    case CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        TokenId: (action as LoginCreatePasswordSuccess).payload,
        error: undefined,
      };
    case CREATE_PASSWORD_FAILURE:
      return {
        ...state,
        TokenId: (action as LoginCreatePasswordFailure).meta,
        error: (action as LoginCreatePasswordFailure).payload,
      };
    case accountConstants.CREATE_PASSWORD_RESET:
      return {
        DeviceId: DeviceId(),
        authorized: false,
        submitting: false,
        error: undefined,
      };
    case accountConstants.SET_EMAIL:
      return {
        ...state,
        email: action.data,
      };
    case accountConstants.CLEAR_REDIRECT:
      return { ...state, error: undefined };
    case accountConstants.UPDATE_PASSWORD_REQUEST:
      return { ...state, error: undefined };
    case accountConstants.UPDATE_PASSWORD_SUCCESS:
      return { ...state, error: undefined, updatePasswordSuccess: true };
    case accountConstants.UPDATE_PASSWORD_FAILURE:
      return { ...state, error: action.error };
    case accountConstants.GET_LOCALIZED_TIMEZONES_SUCCESS:
    case accountConstants.GET_LOCALIZED_TIMEZONES_FAILURE:
      return { ...state, timeZones: action.timeZones };
    case GET_SUPPORTED_COUNTRIES:
      return {
        ...state,
        supportedCountries: action.payload,
      };

    case setGdprSelection.toString(): {
      return {
        ...state,
        gdpr: action.payload,
      };
    }

    default:
      return state;
  }
}

function getErrorMessage(error) {
  return (
    (error && error.response && error.response.body && error.response.body.Message) || defaultError
  );
}
