import React, { useState } from 'react';

import { useMenus } from '@fd/customHooks/useMenus';
import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import { SalesChannelTypeEnum } from '@flipdish/orgmanagement';
import Grid from '@mui/material/Grid';
import type { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useQuery } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { TranslateFunction } from 'react-localize-redux';
import { useParams } from 'react-router-dom';

import FkSelect from '@fd/ui/FkSelect/FkSelect';
import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import { LoadingButton } from '@fd/ui/LoadingButton';
import { FormikInputField } from '@fd/ui/molecules/FormikInputField';

import { formikValidate } from '../../../helpers/validation';
import { EFieldType } from '../../../models/EFieldType';
import {
  getRMSMenus,
  getRMSMenusKey,
  getRMSPriceBands,
  getRMSPriceBandsKey,
} from '../rms.services';
import { FIVE_MINUTES } from '../utils/constants';
import { getIsFlipdishChannel, salesChannelTypes } from './utils';

type FormValues = {
  salesChannelName: string;
  salesChannelType: string;
  menu: string;
  fdMenu: string;
  priceBands: string;
};

type FormErrors = {
  salesChannelName?: string;
  salesChannelType?: string;
  menu?: string;
  fdMenu?: string;
  priceBands?: string;
};

const getSalesChannelFormValidationFunction =
  (selectedChannelType: string, isEdit: boolean, isFlipdishChannel: boolean) =>
  (values: FormValues) => {
    const errors: FormErrors = {};

    const salesChannelNameError = formikValidate.required(values.salesChannelName);
    salesChannelNameError && (errors.salesChannelName = salesChannelNameError);

    const salesChannelTypeError = formikValidate.required(values.salesChannelType);
    salesChannelTypeError && (errors.salesChannelType = salesChannelTypeError);

    if (isEdit && selectedChannelType && !isFlipdishChannel) {
      const menuError = formikValidate.required(values.menu);
      menuError && (errors.menu = menuError);
    }
    if (isEdit && isFlipdishChannel) {
      const fdMenuError = formikValidate.required(values.fdMenu);
      fdMenuError && (errors.fdMenu = fdMenuError);
    }
    const priceBands = formikValidate.required(values.priceBands);
    priceBands && (errors.priceBands = priceBands);
    return errors;
  };

type Props = {
  appId: string;
  translate: TranslateFunction;
  handleSave: any;
  isEdit?: boolean;
  defaultSalesChannelType?: string;
};

const SalesChannelForm = (props: Props) => {
  const { appId, translate, handleSave, isEdit = false, defaultSalesChannelType = '' } = props;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [selectedChannelType, setSelectedChannelType] = useState<string | null>(null); // Track selected channel type
  const { propertyId } = useParams<{ propertyId?: string }>();
  const isFlipdishChannel = getIsFlipdishChannel(selectedChannelType);
  const { data: commonData } = useQueryRMSCommonDataHook(appId, true);
  const token = commonData?.authToken?.token;

  const franchisorId = commonData?.associations?.orgHierarchy?.franchisor?.id;

  const { data: priceBandsResponse, isLoading: isLoadingPriceBands } = useQuery({
    queryKey: [getRMSPriceBandsKey, appId, token],
    queryFn: () => getRMSPriceBands(franchisorId || '', token || ''),
    enabled: !!(appId && token && franchisorId),
    retry: 1,
    staleTime: FIVE_MINUTES,
  });

  const priceBands = priceBandsResponse?.data || [];

  const { data: RMSMenus, isLoading: loadingRMSMenus } = useQuery({
    queryKey: [getRMSMenusKey, appId, token],
    queryFn: () => getRMSMenus(franchisorId || '', token || ''),
    enabled: !!(
      isEdit &&
      selectedChannelType &&
      !isFlipdishChannel &&
      appId &&
      token &&
      franchisorId
    ),
    retry: 1,
    staleTime: FIVE_MINUTES,
  });

  const { data: zeusMenus, isLoading: loadingZeusMenus } = useMenus(
    appId,
    isEdit && isFlipdishChannel
  );

  const handleChannelTypeChange = (channelType) => {
    setSelectedChannelType(channelType);
  };
  // org Apis only work with non rms property ids
  const isOrgApiPropertyId = !!propertyId?.startsWith('p');
  const orgApiPropertyId = isOrgApiPropertyId
    ? propertyId
    : commonData?.associations?.orgHierarchy?.properties?.find(
        (property) => property.id === propertyId
      )?.code;

  return (
    <Formik
      initialValues={{
        salesChannelName: '',
        salesChannelType: defaultSalesChannelType,
        menu: '',
        fdMenu: '',
        priceBands: '',
      }}
      validate={getSalesChannelFormValidationFunction(
        selectedChannelType as string,
        isEdit,
        isFlipdishChannel
      )}
      validateOnBlur
      onSubmit={async (values) => {
        const salesChannel = {
          brandId: appId,
          name: values.salesChannelName,
          type: values.salesChannelType as SalesChannelTypeEnum,
          menuId: isFlipdishChannel ? values.fdMenu : values.menu,
          priceBandId: values.priceBands,
        };
        await handleSave({ salesChannel, propertyId: orgApiPropertyId || '' });
      }}
    >
      {({ errors, handleChange, handleBlur, isSubmitting, setFieldValue, values }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormItemLayout
                bottomBorder
                labelId="Sales_channel_name"
                removeRootPaddings
                paddingBottom={'24px'}
              >
                <FormikInputField
                  fdKey="salesChannelName"
                  name="salesChannelName"
                  validate={errors.salesChannelName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  placeholder={translate('Enter_sales_channel_name') as string}
                />
              </FormItemLayout>
            </Grid>

            <Grid item xs={12}>
              <FormItemLayout
                bottomBorder
                labelId="Sales_channel_type"
                removeRootPaddings
                paddingBottom={'24px'}
              >
                <FkSelect
                  fieldType={EFieldType.Text}
                  fdKey="salesChannelType"
                  name="salesChannelType"
                  options={salesChannelTypes.map((option) => ({
                    value: option.value,
                    label: option.label,
                  }))}
                  onChange={(channelType) => {
                    handleChannelTypeChange(channelType);
                    getIsFlipdishChannel(channelType)
                      ? setFieldValue('menu', '', true)
                      : setFieldValue('fdMenu', '', true);
                  }}
                  textFieldProps={() => ({
                    style: { alignItems: 'flex-end' },
                    value: values.menu,
                  })}
                  disabled={isSubmitting}
                  variant="standard"
                />
              </FormItemLayout>
            </Grid>
            {isEdit && !isFlipdishChannel && (
              <Grid item xs={12}>
                <FormItemLayout
                  bottomBorder
                  labelId="Menu"
                  removeRootPaddings
                  paddingBottom={'24px'}
                  showTooltip={!selectedChannelType}
                  tooltipOptions={{
                    fdKey: `select_a_sales_channel_type`,
                    labelText: translate('Select_a_sales_channel_type') as string,
                  }}
                >
                  <FkSelect
                    fieldType={EFieldType.Text}
                    fdKey="rms-menu"
                    name="menu"
                    options={
                      RMSMenus?.data?.map((option) => ({
                        value: option.menuId,
                        label: option.name,
                      })) || []
                    }
                    textFieldProps={() => ({
                      style: { alignItems: 'flex-end' },
                      value: values.menu,
                    })}
                    variant="standard"
                    disabled={isSubmitting || loadingRMSMenus || !selectedChannelType}
                  />
                </FormItemLayout>
              </Grid>
            )}
            {isEdit && isFlipdishChannel && (
              <Grid item xs={12}>
                <FormItemLayout
                  bottomBorder
                  labelId="Menu"
                  removeRootPaddings
                  paddingBottom={'24px'}
                >
                  <FkSelect
                    fieldType={EFieldType.Text}
                    fdKey="fdMenu"
                    name="fdMenu"
                    options={
                      zeusMenus?.map((option) => ({
                        value: option.MenuId || '',
                        label: `${option.Name} - ${option.MenuId}`,
                      })) || []
                    }
                    textFieldProps={() => ({
                      style: { alignItems: 'flex-end' },
                    })}
                    variant="standard"
                    disabled={isSubmitting || loadingZeusMenus || !selectedChannelType}
                  />
                </FormItemLayout>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormItemLayout
                bottomBorder
                labelId="Price_bands"
                removeRootPaddings
                paddingBottom={'24px'}
              >
                <FkSelect
                  fdKey="priceBands"
                  name="priceBands"
                  options={
                    priceBands?.map((option) => ({
                      value: option.id,
                      label: option.name,
                    })) || []
                  }
                  variant="standard"
                  placeholder={translate('Select_a_price_band') as string}
                  fieldType={EFieldType.Text}
                  disabled={isSubmitting || isLoadingPriceBands}
                  textFieldProps={() => ({
                    style: { alignItems: 'flex-end' },
                  })}
                />
              </FormItemLayout>
            </Grid>

            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
              <LoadingButton
                fdKey="submit-sales-channel"
                type="submit"
                variant="contained"
                color="primary"
                fullWidth={isMobile}
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {translate('Add_sales_channel')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default SalesChannelForm;
