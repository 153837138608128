import React from 'react';

import { type StoreGroupBase, StoreGroup } from '@flipdish/api-client-typescript';
import { type FieldProps, Field } from 'formik';
import { Translate } from 'react-localize-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import Select from '@fd/ui/Select/Select';

const currencyData: Array<{ label: string; value: StoreGroup.CurrencyEnum }> = ((
  passedEnum
): Array<{ label: string; value: StoreGroup.CurrencyEnum }> => {
  const data: Array<{ label: string; value: StoreGroup.CurrencyEnum }> = [];
  for (const x in passedEnum) {
    data.push({ label: x, value: x as unknown as StoreGroupBase.CurrencyEnum });
  }
  return data;
})(StoreGroup.CurrencyEnum);

const CurrencySelector = () => {
  return (
    <FormItemLayout label={<Translate id="Currency" />}>
      <Field name="currency">
        {({ field, form }: FieldProps) => {
          return (
            <Select
              dataFd="sales-channels-currency-select"
              value={field.value}
              options={currencyData}
              variant="standard"
              onChange={(currency: { label: string; value: string }) => {
                form.setFieldValue(field.name, currency);
              }}
              TextFieldProps={{
                style: { paddingRight: 16 },
              }}
            />
          );
        }}
      </Field>
    </FormItemLayout>
  );
};

export default CurrencySelector;
