export default async function retryAsync<T>({
  fn,
  shouldRetry,
  maxRetries = 5,
  retryDelayBase = 1000,
}: {
  fn: () => Promise<T>;
  shouldRetry: (result: T | null) => boolean;
  maxRetries?: number;
  retryDelayBase?: number;
}): Promise<T | null> {
  for (let i = 0; i < maxRetries; i++) {
    const retryDelay = (i + 1) * retryDelayBase;
    await new Promise((resolve) => setTimeout(resolve, retryDelay));
    try {
      const result = await fn();
      if (result && !shouldRetry(result)) {
        return result;
      }
    } catch (error) {
      console.error(`Attempt ${i + 1} failed:`, error);
    }
  }
  return null;
}
