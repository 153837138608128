const getLocationPathname = (value) => {
  const pathArray = value.split('/');

  if (pathArray[2] === 'kiosks') {
    return `/${pathArray[1]}/${pathArray[2]}`;
  } else if (pathArray[3] === 'website') {
    return `/${pathArray[1]}/${pathArray[2]}/website`;
  } else if (pathArray[3] === 'bank-accounts') {
    return `/${pathArray[1]}/${pathArray[2]}/${pathArray[3]}`;
  } else if (value[3] === 'payouts') {
    return `/${pathArray[1]}/${pathArray[2]}/${pathArray[3]}`;
  } else return;
};

export { getLocationPathname };
