import React, { useCallback, useMemo } from 'react';

import { CircularProgress } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  RestApiPaginationResultStoreGroupExtended,
  StoreGroupBase,
} from 'overrides/@flipdish/api-client-typescript/api';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';

import { notifyError, NotifyProps, notifySaved } from '../../../layouts/Notify/actions';
import { storeGroupService } from '../../../services/storegroup.service';
import SalesChannelGroupForm, { type FormValues } from './components/SalesChannelGroupForm';
import useStoreToSalesChannelTransformer from './useStoreToSalesChannelTransformer';

type Props = MappedState & MappedDispatch;

const SalesChannelGroupDetails = ({
  currentBrandId,
  dispatchNotifySaved,
  dispatchNotifyError,
}: Props) => {
  const { salesChannelGroupId } = useParams<{ salesChannelGroupId: string }>();

  const queryClient = useQueryClient();
  const cachedSalesChannelsGroups =
    queryClient.getQueryData<RestApiPaginationResultStoreGroupExtended>([
      'getSalesChannelGroup',
      currentBrandId,
    ]);

  const { data: salesChannelsGroups, isLoading: isSalesChannelGroupsLoading } = useQuery({
    queryKey: ['getSalesChannelGroup', currentBrandId],
    queryFn: () => storeGroupService.getAllForApp({ appId: currentBrandId ?? '' }),
    enabled: !cachedSalesChannelsGroups,
  });

  const salesChannelGroupsData = cachedSalesChannelsGroups || salesChannelsGroups;

  const data = useMemo(() => {
    return salesChannelGroupsData?.Data.find((d) => d.StoreGroupId === Number(salesChannelGroupId));
  }, [salesChannelGroupsData, salesChannelGroupId]);

  const storeGroupStores = useMemo(() => {
    return (
      data?.StoreHeaders?.map((storeHeader) => ({
        name: storeHeader.Name,
        storeId: storeHeader.StoreId,
      })) ?? []
    );
  }, [data]);

  const { isLoading: isSalesChannelsLoading, storeIdAssociations } =
    useStoreToSalesChannelTransformer();

  const options = useMemo(() => {
    return storeIdAssociations
      .filter((association) =>
        storeGroupStores.find((store) => store?.storeId === association?.storeId)
      )
      .map((association) => ({
        label: association?.salesChannelName,
        value: association?.storeId,
        scId: association?.salesChannelId,
      }));
  }, [storeIdAssociations, storeGroupStores]);

  const { mutateAsync } = useMutation({
    mutationFn: (data: { storeGroupId: number; storeGroup: StoreGroupBase }) => {
      return storeGroupService.update(data.storeGroupId, data.storeGroup);
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries({ queryKey: ['getSalesChannelGroup', currentBrandId] });

      const previousData = queryClient.getQueryData(['getSalesChannelGroup', currentBrandId]);

      queryClient.setQueryData(['getSalesChannelGroup', currentBrandId], (oldData: any) => {
        return {
          ...oldData,
          Data: oldData.Data.map((item: any) =>
            item.StoreGroupId === newData.storeGroupId ? { ...item, ...newData.storeGroup } : item
          ),
        };
      });

      return { previousData };
    },
    onSuccess: () => {
      dispatchNotifySaved();
    },

    onError: (e, newData, context) => {
      queryClient.setQueryData(['getSalesChannelGroup', currentBrandId], context?.previousData);
      dispatchNotifyError({ message: e.message });
    },
  });

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      await mutateAsync({
        storeGroupId: Number(salesChannelGroupId),
        storeGroup: { Name: formValues.name, Currency: formValues.currency.value },
      });
    },
    [mutateAsync, salesChannelGroupId]
  );

  return (
    <PageLayout
      documentTitle="Sales_channel_groups"
      title={data?.Name}
      userPermissions={'Owner'}
      toParent={`/${currentBrandId}/settings/sales-channel-groups/`}
      strictToParent
    >
      {isSalesChannelGroupsLoading ? (
        <CircularProgress />
      ) : (
        <SalesChannelGroupForm
          isSalesChannelsLoading={isSalesChannelsLoading}
          name={data?.Name ?? ''}
          currency={{ label: data?.Currency, value: data?.Currency }}
          salesChannels={options}
          onSubmit={(formValues) => handleSubmit(formValues)}
        />
      )}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    currentBrandId: state.currentApp.AppId,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesChannelGroupDetails);
