import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import PageLayout from '@fd/ui/Layout';

type Props = MappedState;

const AddSalesChannelGroup = ({ translate }: Props) => {
  return (
    <PageLayout
      documentTitle="Add_sales_channel_group"
      title={translate('Add_sales_channel_group')}
      userPermissions={'Owner'}
    >
      {<div>Form</div>}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
  };
};

export default connect(mapStateToProps)(AddSalesChannelGroup);
