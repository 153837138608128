import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { googleAnalytics } from '../services/google_analytics';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    googleAnalytics.sendPageView(location.pathname);
  }, [location.pathname]);
};
