import {
  Configuration,
  CreateSalesChannel,
  CreateSalesChannelForOrgAndProperty201Response,
  SalesChannelsApi,
} from '@flipdish/orgmanagement';

import { getApiEndPoint } from '../../helpers/apibase';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const baseURL = getApiEndPoint();

const configuration = new Configuration({
  basePath: baseURL,
  useDefaultUserAgent: true,
});

const defaultOptions = {
  withCredentials: true,
};

const salesChannelsApi = new SalesChannelsApi(configuration);

export type CreateSalesChannelProps = {
  orgId: string;
  propertyId: string;
  salesChannel: CreateSalesChannel;
};
// #region createSalesChannel
export async function createSalesChannel({
  orgId,
  propertyId,
  salesChannel,
}: CreateSalesChannelProps): Promise<{ data: CreateSalesChannelForOrgAndProperty201Response }> {
  try {
    return await salesChannelsApi.createSalesChannelForOrgAndProperty(
      { orgId, propertyId, createSalesChannel: salesChannel },
      defaultOptions
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

const SalesChannelService = {
  createSalesChannel,
};

export default SalesChannelService;
