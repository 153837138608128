import React, { useEffect, useRef } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { usePrevious } from '../../../custom-hooks/usePrevious';

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '10px',
  padding: '16px 12px 16px 24px',
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,

  '& > div::before, & > div::after, & > div:hover::before': {
    borderBottom: 'initial !important',
  },
}));

const SearchIconStyled = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

type Props = {
  open: boolean;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
} & MappedProps;

const useRouteSearchParams = (name: string) => {
  const location = useLocation();
  const history = useHistory();

  const setSearch = (query: string) => {
    const params = new URLSearchParams(location.search);
    if (query) {
      params.set(name, query);
    } else {
      params.delete(name);
    }
    history.replace({ search: params.toString() });
  };

  return setSearch;
};

const OrgSelectorSearch = (props: Props) => {
  const { searchQuery, setSearchQuery, open, translate } = props;
  const previousOpen = usePrevious(open);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const setSearch = useRouteSearchParams('orgSearch');

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query);
    setSearch(query);
  };

  const onSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    handleSearchQueryChange(query);
  };

  useEffect(() => {
    if (open && !previousOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open, previousOpen]);

  return (
    <StyledTextField
      inputRef={inputRef}
      type="search"
      fullWidth
      placeholder={`${translate('Search')}...`}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIconStyled />
          </InputAdornment>
        ),
      }}
      inputProps={{ 'data-fd': 'org-selector-search' }}
      onChange={onSearchQueryChange}
      value={searchQuery}
      variant="standard"
    />
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: AppState) => {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
  };
};

export default connect(mapStateToProps)(OrgSelectorSearch);
