import React, { useEffect } from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { compose } from 'recompose';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import OAuthAppRedirectUris from './components/OAuthAppRedirectUris';
import DetailsRoutes from './details.routes';
import DeveloperTools from './DeveloperTools';
import OAuthAppList from './List/OAuthAppList';
import TokenList from './TokenList/TokenList';

type Props = MappedState & MappedDispatch & RouteComponentProps;

const DevelopersRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
    AppName,
    translate,
  } = props;

  useEffect(() => {
    document.title = `${translate('Apps')} | ${AppName} | Flipdish`;
  }, []);

  return (
    <ErrorBoundary identifier="developers">
      <Switch>
        <Route exact path={`${path}`} component={DeveloperTools} />
        <Route exact path={`${path}/apps`} component={OAuthAppList} />
        <Route path={`${path}/:OAuthAppId/redirect_uris`} component={OAuthAppRedirectUris} />
        <Route path={`${path}/:OAuthAppId/authorization_tokens`} component={TokenList} />

        <Route path={`${path}/:OAuthAppId`} component={DetailsRoutes} />
      </Switch>
    </ErrorBoundary>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, ownProps) => {
  const { currentApp, locale } = state;
  return {
    AppName: currentApp.Name,
    translate: getTranslate(locale),
    match: ownProps.match,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const EnhancedComponent = compose<Props, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DevelopersRoutes);

export default EnhancedComponent;
