import React, { useEffect, useState } from 'react';

import { App, StoreHeader } from '@flipdish/api-client-typescript';
import DoneIcon from '@mui/icons-material/Done';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { useInfiniteQuery } from '@tanstack/react-query';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';

import { getTeammates } from '../../actions/teammates.actions';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { loadByAppIdHeaders } from '../../services/store.service';
import { TeammateEvents } from '../../signalr/hub.actions';
import List from './List';
import { Modify } from './Modify';

const styles = (theme: Theme) =>
  createStyles({
    snackbarContent: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      padding: '16px',
      marginBottom: '20px',
      '&>div': {
        padding: '0',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '16px',
        marginRight: '16px',
      },
    },
    snackbarSpan: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      fontWeight: 'normal' as any,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.43',
      letterSpacing: '0.3px',
    },
    snackbarIcon: {
      width: '22px',
      marginRight: '12px',
    },
  });

type Props = WithStyles<typeof styles> & MappedDispatch & MappedState;

const Teammates = (props: Props) => {
  const {
    classes,
    currentApp,
    getTeammates,
    subscribeToTeammateEvents,
    unSubscribeToTeammateEvents,
  } = props;
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [stores, setStores] = useState<StoreHeader[]>([]);
  const [message, setMessage] = useState<string>('');

  const {
    data: getStoreHeaders,
    hasNextPage: hasStoreHeaders,
    fetchNextPage: fetchStoreHeaders,
  } = useInfiniteQuery({
    queryKey: ['loadStoresByAppIdHeaders', currentApp.AppId],
    queryFn: ({ pageParam }) => loadByAppIdHeaders({ appId: currentApp.AppId!, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage && lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    gcTime: 15 * 60 * 1000,
  });

  useEffect(() => {
    if (hasStoreHeaders) {
      fetchStoreHeaders();
    }
    if (getStoreHeaders && getStoreHeaders.pages) {
      const setAllStores = getStoreHeaders.pages.reduce((total, page) => {
        page &&
          total &&
          page.forEach((store) =>
            total.push({
              StoreId: store.StoreId,
              Name: store.Name,
            })
          );
        return total;
      }, []);
      if (setAllStores) {
        setStores(setAllStores);
      }
    }
  }, [getStoreHeaders?.pages]);

  useEffect(() => {
    getTeammates();

    subscribeToTeammateEvents();

    return () => {
      unSubscribeToTeammateEvents();
    };
  }, []);

  const showSuccessSnackbar = (message) => {
    setShowSnackbar(true);
    setMessage(message);
  };
  return (
    <ErrorBoundary identifier="teammates">
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={2000}
      >
        <SnackbarContent
          className={classes.snackbarContent}
          aria-describedby="client-snackbar"
          message={
            <div id="client-snackbar" className={classes.snackbarSpan}>
              <DoneIcon className={classes.snackbarIcon} />
              {message}
            </div>
          }
        />
      </Snackbar>

      <Switch>
        <Route
          key={1}
          path={'/:appId/teammates/new'}
          render={() => (
            <Permissions allowed={[App.AppResourceSetEnum.EditTeammates]}>
              <Modify showSuccessSnackbar={showSuccessSnackbar} stores={stores} />
            </Permissions>
          )}
        />

        <Route
          key={2}
          path={'/:appId/teammates/:teammateId'}
          render={() => (
            <Permissions allowed={[App.AppResourceSetEnum.EditTeammates]}>
              <Modify showSuccessSnackbar={showSuccessSnackbar} stores={stores} />
            </Permissions>
          )}
        />
        <Route key={0} path={'/:appId/teammates'} component={List} />
      </Switch>
    </ErrorBoundary>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getTeammates: () => {
    dispatch(getTeammates());
  },
  subscribeToTeammateEvents: () => {
    dispatch(TeammateEvents.Subscribe.InviteSent);
    dispatch(TeammateEvents.Subscribe.InviteAccepted);
    dispatch(TeammateEvents.Subscribe.Updated);
    dispatch(TeammateEvents.Subscribe.Deleted);
  },
  unSubscribeToTeammateEvents: () => {
    dispatch(TeammateEvents.Unsubscribe.InviteSent);
    dispatch(TeammateEvents.Unsubscribe.InviteAccepted);
    dispatch(TeammateEvents.Unsubscribe.Updated);
    dispatch(TeammateEvents.Unsubscribe.Deleted);
  },
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    currentApp,
  };
};

const EnhancedComponent = compose<Props, {}>(
  withStyles(styles, {
    name: 'Teammates',
    withTheme: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(Teammates);

export { EnhancedComponent as Teammates };
