import React from 'react';

import { Translate } from 'react-localize-redux';

export const CustomSubTitle = ({ line1, line2 }: { line1: string; line2?: number }) => {
  let secondaryLine;
  if (line2 !== undefined) {
    if (line2 > 1) {
      secondaryLine = <Translate id="num_sales_channels_selected" data={{ number: line2 }} />;
    } else {
      secondaryLine = <Translate id="one_sales_channel_selected" />;
    }
  } else {
    secondaryLine = undefined;
  }
  return (
    <span>
      <span>
        <Translate
          id="Currency_with_symbol"
          data={{
            symbol: line1,
          }}
        />
      </span>
      <br />
      <span>{secondaryLine}</span>
    </span>
  );
};
