import React, { useState } from 'react';

import type { Property } from '@flipdish/orgmanagement';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Dialog } from '@fd/ui/molecules';

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  copyDescription: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

type CreateNewPropertyDialogProps = {
  open: boolean;
  onCancel: () => void;
  onSave: (property: Partial<Property>) => void;
  propertiesData?: Property[];
};

type Props = CreateNewPropertyDialogProps & MappedProps;

const CreateNewPropertyDialog = (props: Props) => {
  const { brandId, onCancel, onSave, open, propertiesData = [], translate } = props;
  const classes = useStyles();
  const history = useHistory();

  const [handleValue, setHandleValue] = useState<string>('');

  const [propertyData, setPropertyData] = useState<Property>({});

  const handleSave = () => {
    if (handleValue === 'copy') {
      onSave(propertyData);
    } else if (handleValue === 'new') {
      history.push(`/${brandId}/properties/create`);
      onCancel();
    }
  };

  const handleCopyExistingProperty = (propertyId) => {
    const selectedProperty = propertiesData.find((p) => p.propertyId === propertyId);
    if (selectedProperty) {
      const baseName = selectedProperty.name;
      const existingCopies = propertiesData.filter(
        (p) => (p.name ?? '').startsWith(`copy `) && (p.name ?? '').includes(`- ${baseName}`)
      );
      const copyNumber =
        existingCopies.length > 0
          ? Math.max(
              ...existingCopies.map((p) => {
                const match = p.name?.match(/copy (\d+) -/);
                return match ? parseInt(match[1], 10) : 0;
              })
            ) + 1
          : 1;
      const newPropertyName = `copy ${copyNumber} - ${baseName}`;
      setPropertyData({
        ...selectedProperty,
        name: newPropertyName,
      });
    }
  };

  const onChangeCopyControl = (event) => {
    setHandleValue(event.target.value);
  };

  return (
    <Dialog
      open={open}
      title="Create New Property"
      actions={[
        <Button
          className={classes.cancelButton}
          key="cancel-button"
          onClick={onCancel}
          variant="text"
        >
          <Translate id="Cancel" />
        </Button>,
        <Button key="create-button" onClick={handleSave}>
          <Translate id="Create" />
        </Button>,
      ]}
    >
      <RadioGroup
        aria-label={translate('Create_new_store') as string}
        name="CreateNewStoreOptions"
        value={handleValue}
        onChange={onChangeCopyControl}
      >
        <FormControlLabel
          control={<Radio color="primary" data-fd="create_new_radio" />}
          label={translate('Create_new') as string}
          value="new"
        />
        <FormControlLabel
          control={<Radio color="primary" data-fd="copy_store_radio" />}
          label={translate('Copy_data_from') as string}
          value="copy"
        />
      </RadioGroup>

      {handleValue === 'copy' && propertiesData.length > 0 && (
        <Select
          variant="standard"
          className={classes.copyDescription}
          fullWidth
          onChange={(e) => handleCopyExistingProperty(e.target.value)}
        >
          {propertiesData.map((p) => (
            <MenuItem key={p.propertyId} value={p.propertyId}>
              {p.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </Dialog>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
  brandId: state.currentApp.AppId,
});

export default connect(mapStateToProps)(CreateNewPropertyDialog);
