import React, { useEffect, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

type Message = { message: string; key: number };

const useSnackBar = (
  loading: boolean,
  loadingText: string,
  loadedText: string,
  failureIndicator: boolean,
  failureText: string,
  queue: Message[],
  processQueue: () => void,
  isFirstRender: boolean
) => {
  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    if (loading) {
      queue.push({
        message: loadingText,
        key: new Date().getTime(),
      });
    } else if (failureIndicator) {
      queue.push({
        message: failureText,
        key: new Date().getTime(),
      });
    } else {
      queue.push({
        message: loadedText,
        key: new Date().getTime(),
      });
    }
    processQueue();
  }, [loading]);
};

const SnackBar: React.FC<React.PropsWithChildren<MappedState>> = (props: MappedState) => {
  const {
    translate,
    acceptOrderLoading,
    rejectOrderLoading,
    acceptOrderFailed,
    rejectOrderFailed,
  } = props;

  const isFirstRender = useRef(true);
  const [messageInfo, setMessageInfo] = useState<Message>({
    message: '',
    key: new Date().getTime(),
  });
  const [open, setOpen] = useState(false);
  const queue: Message[] = [];

  const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const processQueue = () => {
    if (queue.length > 0) {
      const message = queue.shift();
      if (message) {
        setMessageInfo(message);
      }

      setOpen(true);
    }
  };

  useSnackBar(
    acceptOrderLoading,
    translate('Accepting').toString(),
    translate('Order-accepted').toString(),
    acceptOrderFailed,
    translate('Order-accepted-failed').toString(),
    queue,
    processQueue,
    isFirstRender.current
  );

  useSnackBar(
    rejectOrderLoading,
    translate('Rejecting').toString(),
    translate('Order-rejected').toString(),
    rejectOrderFailed,
    translate('Order-rejected-failed').toString(),
    queue,
    processQueue,
    isFirstRender.current
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, []);

  return (
    <Snackbar
      key={messageInfo.key}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: processQueue }}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{messageInfo.message}</span>}
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { orders } = state;
  return {
    rejectOrderLoading: orders.rejectOrderLoading,
    acceptOrderLoading: orders.acceptOrderLoading,
    acceptOrderFailed: orders.acceptOrderFailed,
    rejectOrderFailed: orders.rejectOrderFailed,
    translate: getTranslate(state.locale),
  };
};

export default connect(mapStateToProps)(SnackBar);
