import {
  BrandsApi,
  Configuration,
  CreateOrg201Response,
  GetAllOrgs200Response,
  GetBrandsByOrgId200Response,
  Org,
  OrgsApi,
  OrgsApiGetAllOrgsRequest,
} from '@flipdish/orgmanagement';
import axios from 'axios';
import qs from 'qs';

import { getApiEndPoint } from '../../helpers/apibase';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const baseURL = getApiEndPoint();

const configuration = new Configuration({
  basePath: baseURL,
  useDefaultUserAgent: true,
});

const defaultOptions = {
  withCredentials: true,
};

const orgsApi = new OrgsApi(configuration);
const brandsApi = new BrandsApi(configuration);

const extraApi = axios.create({
  baseURL,
  withCredentials: true,
});

// #region getBrandsForOrg
export const getBrandsForOrgKey = 'getBrandsForOrg';
export async function getBrandsForOrg(
  orgId: string
): Promise<{ data: GetBrandsByOrgId200Response }> {
  try {
    return await brandsApi.getBrandsByOrgId({ orgId }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getBrands
export const getOrgByBrandIdKey = 'getOrgByBrandId';
export async function getOrgByBrandId(brandId: string): Promise<{ data: GetAllOrgs200Response }> {
  try {
    return await orgsApi.getAllOrgs({ brandId }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getOrg
export const getOrgKey = 'getOrgById';
export async function getOrgById(orgId: string): Promise<{ data: CreateOrg201Response }> {
  try {
    return await orgsApi.getOrgById({ orgId }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getOrgs
export const getOrgsKey = 'getOrgs';
export async function getOrgs(
  req: OrgsApiGetAllOrgsRequest = {}
): Promise<{ data: GetAllOrgs200Response }> {
  try {
    return await orgsApi.getAllOrgs(req, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region createOrg
export const createOrgKey = 'createOrg';
export async function createOrg({
  org,
  isSelfServe,
  withDefaultBrand,
}: {
  org: Org;
  isSelfServe?: boolean;
  withDefaultBrand?: boolean;
}): Promise<{ data: CreateOrg201Response }> {
  try {
    const query = qs.stringify({ isSelfServe, withDefaultBrand });
    return await extraApi.post(`/orgManagement/orgs/?${query}`, org);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

export const uploadImageKey = 'uploadImage';
export async function uploadImage(orgId: string, data: FormData) {
  try {
    const response = await extraApi.post<{ imageUrl: string }>(
      `/orgManagement/orgs/${orgId}/images`,
      data
    );
    return response.data.imageUrl;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
const organisationService = {
  getBrandsForOrg,
  getOrgByBrandId,
  getOrgById,
  getOrgs,
  createOrg,
  uploadImage,
};

export default organisationService;
