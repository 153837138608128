import React, { useLayoutEffect } from 'react';

import { useQueryRMSCommonDataHook } from '@fd/customHooks/useQueryRMSCommonDataHook';
import { parse as parseQueryString } from 'qs';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PropertyFilterCore } from '@fd/ui/Filter/StoreFilter';

import { setSelectedProperty } from '../rms.actions';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';

const PropertyFilter = (
  props: MappedState &
    MappedDispatch & {
      includeAllPropertiesOption?: boolean;
      allowEmptyPropertiesInUrl?: boolean;
      subscription: RMSSubscriptions;
    }
) => {
  const {
    appId,
    translate,
    selectedProperty,
    dispatchSetSelectedProperty,
    includeAllPropertiesOption = false,
    allowEmptyPropertiesInUrl = false,
    subscription,
    currentOrg,
  } = props;
  const { isLoading, data, error } = useQueryRMSCommonDataHook(appId || '', true);
  const location = useLocation();
  // TODO: use selected brandId
  const properties =
    data?.associations?.orgHierarchy?.properties?.filter((property) =>
      property?.subscriptions?.includes(subscription)
    ) || [];

  const getPropertyValues = (propertyId: string) => {
    const property = properties.find((prop) => prop?.id === propertyId);
    return { name: property?.name || '', code: property?.code || '' };
  };

  useLayoutEffect(() => {
    if (
      selectedProperty?.id &&
      properties?.some((property) => property?.id === selectedProperty?.id)
    ) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('property', selectedProperty?.id);
      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({}, '', newUrl);
    }
  }, []);

  useLayoutEffect(() => {
    const params = location.search
      ? parseQueryString(location.search, { ignoreQueryPrefix: true })
      : {};
    const propertyParam = params.property || '';
    if (propertyParam && properties.length && !selectedProperty?.id) {
      const searchPropertyIsValid = properties.some((property) => property?.id === propertyParam);
      if (searchPropertyIsValid) {
        dispatchSetSelectedProperty({
          id: params?.property as string,
          ...getPropertyValues(params?.property as string),
        });
      }
    } else if (
      !properties?.some((property) => property?.id === selectedProperty?.id) &&
      properties?.length &&
      !allowEmptyPropertiesInUrl
    ) {
      dispatchSetSelectedProperty({
        id: properties[0]?.id,
        name: properties[0]?.name,
        code: properties[0]?.code,
      });
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('property', properties[0]?.id);
      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({}, '', newUrl);
    }
  }, [location.search, properties, selectedProperty?.id, currentOrg?.orgId]);

  const handleChange = (selectedStoreOption) => {
    const selectedValue = selectedStoreOption?.values?.value;
    const propertyId = selectedValue === 'all' ? undefined : selectedValue;
    dispatchSetSelectedProperty({ id: propertyId, ...getPropertyValues(propertyId) });
  };

  let propertyOptions = properties.map((property) => ({
    value: property?.id,
    label: property?.name,
  })) || [{ label: translate('No_options'), value: 'all' }];

  const selectedPropertyId = propertyOptions.find(
    (property) => property.value === selectedProperty?.id
  );

  if (includeAllPropertiesOption) {
    propertyOptions = [
      { label: translate('All_Properties') as string, value: 'all' },
      ...propertyOptions,
    ];
  }
  if (error) {
    propertyOptions = [
      { label: translate('Error_please_try_again_later') as string, value: 'all' },
    ];
  }
  return (
    <PropertyFilterCore
      disableIfOneStore={!!error}
      isClearable={includeAllPropertiesOption || allowEmptyPropertiesInUrl}
      useLoadingSkeleton={isLoading}
      preSelectedStores={selectedPropertyId ? [selectedPropertyId] : []}
      label={includeAllPropertiesOption ? 'Properties' : 'Property'}
      isCustomLoading={isLoading}
      customStoreOptions={propertyOptions}
      setSelectAction={handleChange}
      placeholder={includeAllPropertiesOption ? 'All_Properties' : 'Select_a_property'}
      useCustomStoreOptions
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale, currentApp } = state;
  return {
    translate: getTranslate(locale),
    appId: currentApp.AppId,
    selectedProperty: state.rms?.selectedProperty,
    currentOrg: state.rms?.currentOrg,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchSetSelectedProperty: (property) => dispatch(setSelectedProperty(property)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyFilter);
