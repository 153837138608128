import React from 'react';

import { type RouteComponentProps, Route, Switch } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import { lazyWithRetry } from '../../helpers/utilities';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';

const Marketing = lazyWithRetry(() => import('./Marketing'));
type Props = RouteComponentProps;

const MarketingMicroFrontendRoutes: React.FC<Props> = (props: Props) => {
  const {
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="marketing-mf">
      <Switch>
        <Route
          path={`${path}`}
          render={() => (
            <LazyComponent>
              <Marketing />
            </LazyComponent>
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default MarketingMicroFrontendRoutes;
