import { useQuery } from '@tanstack/react-query';

import { getStorefronts, getStorefrontsKey } from '../components/RMS/rms.services';
import { FIVE_MINUTES } from '../components/RMS/utils/constants';
import useQueryRMSCommonDataHook from './useQueryRMSCommonDataHook';

export enum SalesChannelType {
  POS = 'POS',
  UBER_EATS = 'UberEats',
  DELIVEROO = 'Deliveroo',
  JUST_EATS = 'JustEats',
  FLIPDISH_WEB_APP = 'FlipdishWebApp',
  FLIPDISH_KIOSK = 'FlipdishKIOSK',
  FLIPDISH_MOBILE_APP = 'FlipdishMobileApp',
  EXTERNAL_APP = 'ExternalApp',
  WEB_APP = 'WebApp',
  KIOSK = 'Kiosk',
  MOBILE_APP = 'MobileApp',
}

export const useQueryRMSSalesChannels = (
  appId: string,
  propertyId: string,
  filterByType: SalesChannelType[] = []
) => {
  const {
    isLoading: commonDataLoading,
    error: commonDataError,
    data: commonData,
  } = useQueryRMSCommonDataHook(appId, true);

  const token = commonData?.authToken?.token;
  const orgHierarchy = commonData?.associations?.orgHierarchy;
  const franchisorId = orgHierarchy?.franchisor?.id;
  const franchiseeId = orgHierarchy?.franchisees?.[0]?.id;

  const {
    isFetching: isLoadingStorefronts,
    data: storefrontsResponse,
    error: storefrontsError,
  } = useQuery({
    queryKey: [getStorefrontsKey, appId, token, franchisorId, franchiseeId, propertyId],
    queryFn: () => getStorefronts({ token, franchisorId, franchiseeId, propertyId }),
    enabled: !!(token && franchisorId && franchiseeId && propertyId),
    retry: 1,
    staleTime: FIVE_MINUTES,
  });

  const storefronts = storefrontsResponse?.data;
  const filteredStorefronts = filterByType?.length
    ? storefronts?.filter(
        (storefront) => filterByType?.length === 0 || filterByType?.includes(storefront.type)
      )
    : storefronts;
  return {
    isLoading: commonDataLoading || isLoadingStorefronts,
    error: commonDataError || storefrontsError,
    data: filteredStorefronts,
  };
};
