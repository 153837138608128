import React from 'react';

import { type AppConfigUpdateModel, type Language } from '@flipdish/api-client-typescript';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import { type NotifyProps, notifyError } from '../../../layouts/Notify/actions';
import retryAsync from '../../../services/utils/retryAsync';
import { GetOnboardingConfigs, getOnboardingConfigs } from '../../OnboardingV2/onboarding.service';
import { uploadImage } from '../../RMS/organisation.services';
import { updateOnboardingConfigById } from '../../Tutorial/tutorial.service';
import {
  setAppConfig,
  setAppConfigLanguages,
  uploadAppConfigLogo,
} from '../../WebsiteAndApp/actions';
import GeneralSettingsForm, { type FormValues } from '../GeneralSettings/GeneralSettingsForm';
import { brandService } from './brands.services';

const findConfig = (configs?: GetOnboardingConfigs['Data']) => {
  if (configs) {
    return configs.find((cfg) => cfg.ConfigType === 'OnboardingWizard' && cfg.IsEnabled);
  }
};

type Props = MappedProps & MappedDispatch;
const AddBrand = ({
  currentBrand,
  currentOrg,
  uploadLogo,
  setLanguages,
  updateConfig,
  notifyError,
}: Props) => {
  const history = useHistory();

  const updateZeusApp = async (
    {
      name,
      languages,
      type,
      webAndAppsButtonColour,
      logo,
      IsPanaceaEnabled,
    }: FormValues & { logo?: File },
    brandId?: string
  ) => {
    const config: AppConfigUpdateModel = {
      Name: name,
      IsPanaceaEnabled,
      ApplicationCategory: type,
      MainColor: webAndAppsButtonColour.slice(1),
    };

    const newLanguages = languages.map((language) => {
      return {
        LanguageId: language.value,
        Name: language.label,
        DisplayOrder: language.displayOrder,
      };
    }) as Language[];

    if (logo) {
      const formData = new FormData();
      formData.append('files[]', logo);
      await uploadLogo(formData, brandId);
    }

    await setLanguages(newLanguages);

    await updateConfig(config, brandId);
  };

  const handleSubmit = async (values: FormValues & { logo?: File }) => {
    try {
      let logoImageUrl = '';
      if (values.logo) {
        const formDataOrg = new FormData();
        formDataOrg.append('file', values.logo);
        logoImageUrl = await uploadImage(currentOrg?.orgId ?? '', formDataOrg);
      }
      const response = await brandService.createBrand({
        orgId: currentOrg?.orgId ?? '',
        brand: { name: values.name, countryCode: currentBrand.CountryId as any, logoImageUrl },
      });

      const brandId = response.data.data?.brandId;

      const onboardingConfigs = await retryAsync({
        fn: () => getOnboardingConfigs(brandId ?? ''),
        shouldRetry: (res) => {
          if (res?.Data && !!findConfig(res?.Data)) {
            return false;
          }
          return true;
        },
      });

      const onboardingConfig = findConfig(onboardingConfigs?.Data);

      if (onboardingConfig) {
        const config = {
          appId: response.data.data?.brandId || '',
          configId: onboardingConfig.OnboardingConfigId,
          config: {
            IsWelcomeScreenEnabled: true,
            IsEnabled: false,
            ConfigType: 'OnboardingWizard' as any,
          },
        };

        // prevent sending user into app/brand singnup flow
        await updateOnboardingConfigById(config);
      }
      await updateZeusApp(values, brandId);

      history.push(`/${brandId}/settings/brands`);
    } catch (e) {
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    }
  };

  return (
    <PageLayout
      documentTitle="Add_Brand"
      title={<Translate id="Add_Brand" />}
      userPermissions={'Owner'}
      toParent={`${currentBrand.AppId}/settings/brands`}
    >
      <PaperContainer fluid>
        <GeneralSettingsForm
          languages={currentBrand.Languages}
          submit={handleSubmit}
          saveButtonText="Add_Brand"
          formType="create"
        />
      </PaperContainer>
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  currentBrand: state.currentApp,
  currentOrg: state.rms.currentOrg,
});

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  uploadLogo: (data: FormData, brandId?: string) => dispatch(uploadAppConfigLogo(data, brandId)),
  updateConfig: (config: AppConfigUpdateModel, brandId?: string) =>
    dispatch(setAppConfig(config, brandId)),
  setLanguages: (languages: Language[]) => dispatch(setAppConfigLanguages(languages)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);
