import React from 'react';

import { Skeleton } from '@mui/material';
import { type FieldProps, Field } from 'formik';
import { Translate } from 'react-localize-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import MultiSelect from '@fd/ui/Select/Select';

type Props = { isLoading: boolean };
const SalesChannelGroupSelector = ({ isLoading }: Props) => {
  return (
    <FormItemLayout label={<Translate id="Sales_channels" />}>
      <Field name="salesChannels">
        {({ field }: FieldProps) => {
          return isLoading ? (
            <div style={{ paddingRight: 16 }} data-fd="salesChannels-loader">
              <Skeleton variant="rounded" width={'100%'} height={44} />
            </div>
          ) : (
            <MultiSelect
              dataFd="sales-channels-select"
              isMulti
              maxChips={5}
              value={field.value}
              isDisabled
              options={[]}
              variant="standard"
              TextFieldProps={{
                style: { paddingRight: 16 },
              }}
            />
          );
        }}
      </Field>
    </FormItemLayout>
  );
};

export default SalesChannelGroupSelector;
