import React from 'react';

import { CreateVoucher, Voucher } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type FormikProps, Form, withFormik } from 'formik';
import moment from 'moment';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { LoadingButton } from '@fd/ui/LoadingButton';
import Spacer from '@fd/ui/Spacer';

import AdvancedOptionsFormItems from '../../components/AdvancedOptionsFormItems';
import AutomaticallyApplyField from '../../components/fields/AutomaticallyApplyField';
import CanBeUsedField from '../../components/fields/CanBeUsedField';
import ChannelField from '../../components/fields/ChannelField';
import CodeField from '../../components/fields/CodeField';
import DescriptionField from '../../components/fields/DescriptionField';
import IsValidField from '../../components/fields/IsValidField';
import StoresSelectField from '../../components/fields/StoresSelectField';
import GridDivider from '../../components/GridDivider';
import { useVoucherForm } from '../../hooks/useVoucherForm';
import { VoucherErrorCodes } from '../../types';
import {
  getCanBeUsed,
  getValidDates,
  getValidPayment,
  getValidType,
} from '../../utils/voucherUtils';
import DiscountAmountField from './DiscountAmountField';

export interface IDiscountProps {
  create: (voucher) => Promise<void>;
  editMode: boolean;
  isLoading: boolean;
  isVoucherChannelEnabled: boolean; // TODO - remove when voucherChannel is fully released
  isVoucherDiscoverableEnabled: boolean; // TODO - remove when voucherDiscoverable is fully released
  isVoucherLimitAvailabilityEnabled: boolean; // TODO - remove when voucherLimitAvailability is fully released
  isVoucherValidPeriodEnabled: boolean; // TODO - remove when voucherValidPeriod is fully released
  translate: TranslateFunction;
  update: (voucher) => Promise<void>;
  voucher: Voucher;
}

export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = (voucher) => {
  const start = voucher && voucher.StartDate ? moment(voucher.StartDate) : moment();
  const expiry =
    voucher && voucher.ExpiryDate ? moment(voucher.ExpiryDate) : moment().add(7, 'days');

  return {
    CanBeUsed: getCanBeUsed(voucher),
    ChannelRestrictions: (voucher && voucher.ChannelRestrictions) || [],
    Code: (voucher && voucher.Code) || '',
    Currency: '',
    Description: (voucher && voucher.Description) || '',
    DiscountAmount: (voucher && voucher.LumpDiscountDetails.DiscountAmount) || 0,
    ExpiryDate: expiry.toDate(),
    IncludeDeliveryFee: voucher ? voucher.IncludeDeliveryFee : false,
    IsAutomaticallyApplied: voucher ? voucher.IsAutomaticallyApplied : false,
    IsDiscoverable: voucher ? voucher.IsDiscoverable : false,
    IsEnabled: voucher ? voucher.IsEnabled : false,
    IsValid: voucher ? voucher.IsValid : false,
    IsValidForDeliveryOrders: voucher ? voucher.IsValidForDeliveryOrders : true,
    IsValidForFirstOrderOnly: voucher ? voucher.IsValidForFirstOrderOnly : false,
    IsValidForOrdersPayedByCash: voucher ? voucher.IsValidForOrdersPayedByCash : true,
    IsValidForOrdersPayedOnline: voucher ? voucher.IsValidForOrdersPayedOnline : true,
    IsValidForPickupOrders: voucher ? voucher.IsValidForPickupOrders : true,
    IsValidOncePerCustomer: voucher ? voucher.IsValidOncePerCustomer : false,
    IsValidOnlyOnce: voucher ? voucher.IsValidOnlyOnce : true,
    MaxRedemptions: voucher ? voucher.MaxRedemptions : undefined,
    StartDate: start.toDate(),
    Stores: (voucher && voucher.Stores) || [],
    TakesPriority: voucher ? voucher.TakesPriority : false,
    ValidDates:
      (voucher && getValidDates(voucher)) || `${start.format('L')} - ${expiry.format('L')}`,
    ValidityPeriods: (voucher && voucher.ValidityPeriods) || [],
    ValidOnOrdersOver: (voucher && voucher.ValidOnOrdersOver) || 0,
    ValidPayment: getValidPayment(voucher),
    ValidType: getValidType(voucher),
    VoucherType: CreateVoucher.VoucherTypeEnum.LumpDiscount,
  };
};

const LumpDiscountForm = (props: FormikProps<FormValues> & IDiscountProps) => {
  const {
    editMode,
    errors,
    isSubmitting,
    isVoucherChannelEnabled, // TODO - remove when voucherChannel is fully released
    isVoucherDiscoverableEnabled, // TODO - remove when voucherDiscoverable is fully released
    isVoucherLimitAvailabilityEnabled, // TODO - remove when voucherLimitAvailability is fully released
    isVoucherValidPeriodEnabled, // TODO - remove when voucherValidPeriod is fully released
    translate,
  } = props;

  useVoucherForm<FormValues>({ errors, isSubmitting, translate });

  return (
    <Form>
      <Grid container>
        <Grid item xs={12}>
          <StoresSelectField />
        </Grid>

        <GridDivider />

        {isVoucherChannelEnabled && (
          <>
            <Grid item xs={12}>
              <ChannelField translate={translate} />
            </Grid>

            <GridDivider />
          </>
        )}

        <Grid item xs={12}>
          <CodeField disabled={editMode} />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <DescriptionField />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <DiscountAmountField />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <IsValidField />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <AutomaticallyApplyField />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <CanBeUsedField translate={translate} />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <AdvancedOptionsFormItems
            automaticallyApply={props.values.IsAutomaticallyApplied}
            isVoucherDiscoverableEnabled={isVoucherDiscoverableEnabled} // TODO - remove when voucherDiscoverable is fully released
            isVoucherLimitAvailabilityEnabled={isVoucherLimitAvailabilityEnabled} // TODO - remove when voucherLimitAvailability is fully released
            isVoucherValidPeriodEnabled={isVoucherValidPeriodEnabled} // TODO - remove when voucherValidPeriod is fully released
            translate={translate}
          />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <Spacer size={16}>
            <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
              <Grid item>
                <LoadingButton
                  type="submit"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    !editMode && props.setFieldValue('IsEnabled', false, false);
                    props.handleSubmit();
                  }}
                  fdKey="btn-voucher-create"
                  disabled={props.isSubmitting || !props.values.Code}
                  loading={props.isLoading}
                >
                  {editMode ? <Translate id="Update" /> : <Translate id="Create" />}
                </LoadingButton>
              </Grid>
              {!editMode && (
                <Grid item>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    onClick={(e) => {
                      props.setFieldValue('IsEnabled', true, false);
                      props.handleSubmit();
                    }}
                    fdKey="btn-voucher-create-enable"
                    variant="contained"
                    disabled={props.isSubmitting || !props.values.Code}
                    loading={props.isLoading}
                  >
                    <Translate id="Create_and_enable" />
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Spacer>
        </Grid>
      </Grid>
    </Form>
  );
};

export default withFormik<IDiscountProps, FormValues>({
  displayName: 'LumpDiscountForm',
  mapPropsToValues: ({ voucher }) => {
    return getDefaultFormState(voucher);
  },
  handleSubmit: (values, formikBag) => {
    const {
      ChannelRestrictions,
      Code,
      Description,
      DiscountAmount,
      ExpiryDate,
      IncludeDeliveryFee,
      IsAutomaticallyApplied,
      IsDiscoverable,
      IsEnabled,
      IsValidForDeliveryOrders,
      IsValidForFirstOrderOnly,
      IsValidForOrdersPayedByCash,
      IsValidForOrdersPayedOnline,
      IsValidForPickupOrders,
      IsValidOncePerCustomer,
      IsValidOnlyOnce,
      MaxRedemptions,
      StartDate,
      Stores,
      TakesPriority,
      ValidityPeriods,
      ValidOnOrdersOver,
      VoucherType,
    } = values;
    const voucher: CreateVoucher = {
      Code,
      Description,
      ExpiryDate: moment(ExpiryDate).endOf('day').format() as unknown as Date,
      IncludeDeliveryFee,
      IsAutomaticallyApplied,
      IsDiscoverable,
      IsEnabled,
      IsValidForDeliveryOrders,
      IsValidForFirstOrderOnly,
      IsValidForOrdersPayedByCash,
      IsValidForOrdersPayedOnline,
      IsValidForPickupOrders,
      IsValidOncePerCustomer,
      IsValidOnlyOnce,
      LumpDiscountDetails: { DiscountAmount },
      MaxRedemptions,
      StartDate: moment(StartDate).startOf('day').format() as unknown as Date,
      Stores,
      TakesPriority: !IsAutomaticallyApplied ? false : TakesPriority,
      ValidityPeriods,
      ValidOnOrdersOver,
      VoucherType,
    };
    if (formikBag.props.isVoucherChannelEnabled) {
      voucher.ChannelRestrictions = ChannelRestrictions;
    }
    const serverFunc = formikBag.props.editMode
      ? formikBag.props.update(voucher)
      : formikBag.props.create(voucher);
    serverFunc
      .then(() => {
        formikBag.resetForm({ values: values }); // reset dirty state
      })
      .catch((err) => {
        formikBag.setSubmitting(false);
        if (err.code === VoucherErrorCodes.VOUCHER_CODE_ALREADY_EXISTS) {
          formikBag.setFieldError('Code', 'Could_not_create_voucher_with_this_code_already_exists');
        }
      });
  },
})(LumpDiscountForm);
