import React from 'react';

import CancelIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { createLoadingSelector } from '../../../selectors/loading.selector';
import { Button } from '../../../ui/Button';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import LoadingButton from '../../../ui/LoadingButton';
import { GET_DNS_STATUS } from '../actions';
import { DNSRecord } from '../reducer';

const loadingSelector = createLoadingSelector([GET_DNS_STATUS]);

const useStyles = makeStyles(() => ({
  tableWrapper: {
    marginBottom: 20,
  },
  mobileCell: {
    margin: '12px 0',
  },
  subHeader: {
    display: 'block',
    marginBottom: 12,
  },
}));
type InnerProps = MappedState;
type OuterProps = {
  getDNSStatus: () => Promise<void>;
  visibleRecords: DNSRecord[];
  showTitle?: boolean;
};
type Props = InnerProps & OuterProps;
const DnsRecords = (props: Props) => {
  const { translate, getDNSStatus, loading, visibleRecords, showTitle = true } = props;
  const classes = useStyles();

  return (
    <>
      {showTitle && (
        <Typography variant="subtitle2" style={{ marginBottom: 8 }}>
          {translate('DNS_records')}
        </Typography>
      )}
      <Typography variant="caption" className={classes.subHeader}>
        {translate('DNS_description', {
          helpdesk_url: translate('connect_your_own_domain') as string,
        })}{' '}
        <a href="mailto:help@flipdish.com">{translate('Contact_us')}</a>
        {` ${translate('for_help')}.`}
      </Typography>

      <PaperContainer fluid className={classes.tableWrapper}>
        <Hidden smDown>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th">{<Translate id="Host" />}</TableCell>
                <TableCell component="th">{<Translate id="Record_type" />}</TableCell>
                <TableCell component="th">{<Translate id="Target" />}</TableCell>
                <TableCell component="th">{<Translate id="Status" />}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRecords.map((record, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{record.Host}</TableCell>
                    <TableCell>{record.Type}</TableCell>
                    <TableCell>{record.Target}</TableCell>
                    <TableCell>
                      {record.Status ? (
                        <CheckIcon style={{ fill: '#1dc798' }} />
                      ) : (
                        <CancelIcon color="error" />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={4}>
                  <Grid container justifyContent="flex-end">
                    <LoadingButton
                      fdKey="refresh_records"
                      color="primary"
                      onClick={getDNSStatus}
                      loading={loading}
                    >
                      <RefreshIcon />
                      {<Translate id="Refresh" />}
                    </LoadingButton>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Hidden>
        <Hidden smUp>
          {visibleRecords.map((record, index) => {
            return (
              <div key={index}>
                <div className={classes.mobileCell}>
                  <Typography variant="caption" component="p">
                    {<Translate id="Host" />}
                  </Typography>
                  {record.Host}
                </div>
                <div className={classes.mobileCell}>
                  <Typography variant="caption" component="p">
                    {<Translate id="Record_type" />}
                  </Typography>
                  {record.Type}
                </div>
                <div className={classes.mobileCell}>
                  <Typography variant="caption" component="p">
                    {<Translate id="Target" />}
                  </Typography>
                  {record.Target}
                </div>
                <div className={classes.mobileCell}>
                  <Typography variant="caption" component="p">
                    {<Translate id="Status" />}
                  </Typography>
                  {record.Status ? (
                    <CheckIcon style={{ fill: '#1dc798' }} />
                  ) : (
                    <CancelIcon color="error" />
                  )}
                </div>
                <Divider />
              </div>
            );
          })}
          <div className={classes.mobileCell}>
            <Button fdKey="refresh_records" color="primary" onClick={getDNSStatus}>
              <RefreshIcon />
              <Translate id="Refresh" />
            </Button>
          </div>
        </Hidden>
      </PaperContainer>
    </>
  );
};
type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  loading: loadingSelector(state),
  translate: getTranslate(state.locale),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(DnsRecords);
