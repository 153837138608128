import React from 'react';

import DollarOutlined from '@mui/icons-material/AttachMoneyOutlined';
import DeliveryIcon from '@mui/icons-material/DirectionsBike';
import MenusIcon from '@mui/icons-material/FastfoodOutlined';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { getTranslate, Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import Tag from '@fd/ui/atoms/Tag';
import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import ListItemLink from '@fd/ui/List/ListItemLink';

import PercentageIcon from '../../../icons/PercentageIcon';
import { flagService } from '../../../services/flagService';

const linksCombiner = (
  appId: string,
  translate: TranslateFunction,
  isFreeDeliveryFeeVoucherEnabled: boolean, // TODO - remove when freeDeliveryFeeVoucher is fully released
  isMenuItemVoucherEnabled: boolean // TODO - remove when menuItemVoucher is fully released
) => {
  return [
    {
      route: `/${appId}/vouchers/new/percentage`,
      icon: <PercentageIcon />,
      title: translate('Percentage_discount') as string,
      description: translate(
        'Certain_percentage_off_on_an_order_or_on_orders_above_certain_amount'
      ) as string,
      dataFd: 'btn-percentage-discount',
    },
    {
      route: `/${appId}/vouchers/new/lump`,
      icon: <DollarOutlined />,
      title: translate('Lump_discount') as string,
      description: translate(
        'Certain_amount_off_on_an_order_or_on_orders_above_certain_amount'
      ) as string,
      dataFd: 'btn-lump-discount',
    },
    isMenuItemVoucherEnabled && {
      route: `/${appId}/vouchers/new/menuItem`,
      icon: <MenusIcon />,
      title: (
        <>
          {translate('Menu_item') as string}
          <Tag label="Beta" />
        </>
      ),
      description: translate('Menu_item_voucher_description') as string,
      dataFd: 'btn-menu-item',
    },
    isFreeDeliveryFeeVoucherEnabled && {
      route: `/${appId}/vouchers/new/freeDelivery`,
      icon: <DeliveryIcon />,
      title: (
        <>
          {translate('Free_delivery_fee') as string}
          <Tag label="Beta" />
        </>
      ),
      description: translate(
        'Free_delivery_fee_on_an_order_or_on_orders_above_a_certain_amount'
      ) as string,
      dataFd: 'btn-free-delivery-fee',
    },
  ];
};
const linksSelector = createSelector(
  [
    (state) => state.currentApp.AppId!,
    (state) => getTranslate(state.locale),
    (state) => flagService.isFlagOn(state, 'freeDeliveryFeeVoucher'), // TODO - remove when freeDeliveryFeeVoucher is fully released
    (state) => flagService.isFlagOn(state, 'menuItemVoucher'), // TODO - remove when menuItemVoucher is fully released
  ],
  linksCombiner
);

const ListItemLinkStyled = withStyles((theme: Theme) => ({
  divider: {
    borderBottom: `none`,
    position: 'relative',
    '&:after': {
      content: '""',
      background: theme.palette.divider,
      position: 'absolute',
      bottom: 0,
      left: '56px',
      right: 0,
      height: '1px',
    },
  },
}))(ListItemLink) as typeof ListItemLink;

const ListItemIconStyled = withStyles(() => ({
  root: {
    justifyContent: 'center',
  },
}))(ListItemIcon) as typeof ListItemIcon;

const ListItemTextStyled = withStyles((theme: Theme) => ({
  primary: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}))(ListItemText) as typeof ListItemText;

type MappedState = ReturnType<typeof mapStateToProps>;

const AddVoucher: React.FC<React.PropsWithChildren<MappedState>> = (props: MappedState) => {
  const { links, currentApp } = props;

  // TODO - Remove when freeDeliveryFeeVoucher and menuItemVoucher are fully released and use links instead
  const featuredLinks = links.filter((link) => link !== false) as any;

  return (
    <PageLayout
      toParent={`/${currentApp.AppId}/vouchers`}
      documentTitle="Add_new_voucher"
      title={<Translate id="Add_new_voucher" />}
    >
      <PaperContainer fluid>
        <Box pt={1} pb={1}>
          <List disablePadding>
            {featuredLinks.map((link, index) => (
              <ListItemLinkStyled
                key={index}
                to={link.route}
                fdKey={link.dataFd}
                divider={index + 1 < featuredLinks.length}
              >
                <ListItemIconStyled>{link.icon}</ListItemIconStyled>
                <ListItemTextStyled
                  primary={link.title}
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                  secondary={link.description}
                  secondaryTypographyProps={{ variant: 'caption', component: 'p' }}
                />
                <ListItemSecondaryAction>
                  <KeyboardArrowRight color="action" />
                </ListItemSecondaryAction>
              </ListItemLinkStyled>
            ))}
          </List>
        </Box>
      </PaperContainer>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  links: linksSelector(state),
  currentApp: state.currentApp,
});

export default connect(mapStateToProps)(AddVoucher);
