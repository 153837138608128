import React from 'react';

import { Translate } from 'react-localize-redux';

import { formikValidate } from '../../../../helpers/validation';
import ColorPicker from '../../../../ui/ColorPicker';
import FormItemLayout from '../../../../ui/Forms/FormItemLayout';
import FormItem, { RenderProps } from '../../components/FormItem';

const validateColour = (value: string) => {
  return formikValidate.hexString(value) || formikValidate.required(value);
};

const WebAndAppsButtonColourField = () => {
  return (
    <FormItemLayout label={<Translate id="Web_&_apps_button_colour" />}>
      <FormItem name="webAndAppsButtonColour" validate={validateColour}>
        {({ field: { name, value }, form: { setFieldValue }, error }: RenderProps) => (
          <ColorPicker
            value={value || '#000000'}
            error={error}
            fdKey="colour"
            compatibleColour="#fff"
            style={{ paddingRight: 16 }}
            onChange={(value: string) => {
              setFieldValue(name, value);
            }}
          />
        )}
      </FormItem>
    </FormItemLayout>
  );
};

export default WebAndAppsButtonColourField;
