import * as FlipdishApi from '@flipdish/api-client-typescript';

import { accountConstants } from '../constants/account.constants';
import { CookiesPreferences } from '../helpers/gdpr';
import { timeZoneDefinitions } from '../helpers/timeZones';
import { passwordValidationApiErrors } from '../helpers/validation';
import { notifyError, notifySaved } from '../layouts/Notify/actions';
import { accountService } from '../services/account.service';
import { getFlipdishAuthorizationToken } from '../services/auth.service';
import { googleAnalytics } from '../services/google_analytics';
import { closeConnection } from '../services/signalr';
import { languageActions } from './language.actions';
import { action, actionError } from './utils';

function setupSurvey(account) {
  return (dispatch) => {
    dispatch({ type: accountConstants.SETUP_SURVEY, account });
  };
}

function setTimeZoneBasedOnLatLng(latitude: number, longitude: number) {
  return (dispatch) => {
    accountService.getIanaTimeZoneFromGmaps(latitude, longitude).then((timeZoneId) => {
      dispatch(update({ TimeZoneInfoId: timeZoneId }));
    });
  };
}

// #region getSupportedCountries
const { GET_SUPPORTED_COUNTRIES } = accountConstants;

export type GetSupCountries = ReturnType<typeof getSupCountries>;
export const getSupCountries = (props) => action(GET_SUPPORTED_COUNTRIES, props);

function getSupportedCountries(throwError = false) {
  return async (dispatch: ThunkDispatch) => {
    try {
      const supportedCountries = await accountService.getSupCountries();
      dispatch(getSupCountries(supportedCountries));
    } catch (error) {
      if (throwError) {
        throw error;
      }
    }
  };
}
// #endregion

// #region update
const { UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE } = accountConstants;

export type UpdateRequest = ReturnType<typeof updateRequest>;
export const updateRequest = (props: FlipdishApi.AccountDetailBase) =>
  action(UPDATE_REQUEST, props);

export type UpdateSuccess = ReturnType<typeof updateSuccess>;
export const updateSuccess = (props: FlipdishApi.AccountDetailBase) =>
  action(UPDATE_SUCCESS, props);

export type UpdateFailure = ReturnType<typeof updateFailure>;
export const updateFailure = (props: FlipdishApi.AccountDetailBase, error: Error) =>
  actionError(UPDATE_FAILURE, props, error);

function update(props: FlipdishApi.AccountDetailBase) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(updateRequest(props));
      await accountService.updateAccount(props);
      dispatch(updateSuccess(props));
      dispatch(getAccountDetails());
    } catch (error) {
      dispatch(updateFailure(props, error));
      throw error;
    }
  };
}
//#endregion

function updatePassword(passwordChange: FlipdishApi.ChangePasswordModel) {
  return (dispatch) => {
    dispatch(request());
    return accountService.updateAccountPassword(passwordChange).then(
      () => {
        dispatch(success());
        dispatch(getAccountDetails());
      },
      (error) => {
        const message = error.body?.Message;
        dispatch(failure(message));
        if (passwordValidationApiErrors.includes(error.body?.Message)) {
          dispatch(notifyError({ message: error.body?.Message, translate: true }));
        } else {
          dispatch(notifyError({ message: 'Something_went_wrong', translate: true }));
        }
      }
    );
  };

  function request() {
    return { type: accountConstants.UPDATE_PASSWORD_REQUEST };
  }
  function success() {
    return { type: accountConstants.UPDATE_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: accountConstants.UPDATE_PASSWORD_FAILURE, error };
  }
}

// #region getAccountDetails
const { GET_ACCOUNT_REQUEST, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_FAILURE } = accountConstants;

export const getAccountDetailsRequest = () => ({
  type: GET_ACCOUNT_REQUEST,
});

export const getAccountDetailsSuccess = (details: FlipdishApi.AccountDetail) => ({
  type: GET_ACCOUNT_SUCCESS,
  payload: details,
});

export const getAccountDetailsFailure = (error: Error) => ({
  type: GET_ACCOUNT_FAILURE,
  payload: error,
});

export function getAccountDetails(throwError = false) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(getAccountDetailsRequest());

      const account = await accountService.getAccountDetails();
      dispatch(getAccountDetailsSuccess(account));

      if (account.Language) {
        dispatch(languageActions.setLanguage(account.Language));
      }

      return account;
    } catch (error) {
      dispatch(getAccountDetailsFailure(error));
      if (throwError) {
        return Promise.reject(error);
      }
    }
  };
}
// #endregion

// #region requestPasswordChangePin
function requestPasswordChangePin(email) {
  return (dispatch) => {
    dispatch(request());
    return accountService.requestPasswordChangePin(email).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: accountConstants.REQUEST_PIN_REQUEST, email };
  }

  function success(response) {
    return {
      type: accountConstants.REQUEST_PIN_SUCCESS,
      payload: response,
    };
  }

  function failure(error) {
    return { type: accountConstants.REQUEST_PIN_FAILURE, error };
  }
}
// #end region

// #region loginWithPin
const { LOGIN_SSO_REQUEST, LOGIN_SSO_SUCCESS, LOGIN_SSO_FAILURE } = accountConstants;

export type LoginSsoRequest = ReturnType<typeof loginSsoRequest>;
export const loginSsoRequest = (email: string) => action(LOGIN_SSO_REQUEST, email);

export type LoginSsoRequesSuccess = ReturnType<typeof loginSsoSuccess>;
export const loginSsoSuccess = (email: string) => action(LOGIN_SSO_SUCCESS, email);

export type LoginSsoRequesFailure = ReturnType<typeof loginSsoFailure>;
export const loginSsoFailure = (email: string, error: Error) =>
  actionError(LOGIN_SSO_FAILURE, email, error);

export function loginSso(email: string, token?: string) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(loginSsoRequest(email));
      const account = await accountService.loginSso(email, token);
      dispatch(loginSsoSuccess(account.email));
      await dispatch(getAccountDetails());
    } catch (error) {
      dispatch(notifyError({ message: 'Something_went_wrong', translate: true }));
      dispatch(loginSsoFailure(email, error));
      throw error;
    }
  };
}

const { LOGIN_AUTH0_FAILURE } = accountConstants;

export const loginAuth0Failure = (error: Error) => actionError(LOGIN_AUTH0_FAILURE, error, null);

export function loginAuth0(code: string) {
  return async (dispatch: ThunkDispatch) => {
    try {
      await getFlipdishAuthorizationToken(code);
      await dispatch(getAccountDetails());
    } catch (error) {
      dispatch(notifyError({ message: 'Something_went_wrong', translate: true }));
      dispatch(loginAuth0Failure(error));
      throw error;
    }
  };
}
// #endregion

// #region loginCreatePassword
const { CREATE_PASSWORD_REQUEST, CREATE_PASSWORD_SUCCESS, CREATE_PASSWORD_FAILURE } =
  accountConstants;

export type LoginCreatePasswordRequest = ReturnType<typeof loginCreatePasswordRequest>;
export const loginCreatePasswordRequest = (tokenId: string) =>
  action(CREATE_PASSWORD_REQUEST, tokenId);

export type LoginCreatePasswordSuccess = ReturnType<typeof loginCreatePasswordSuccess>;
export const loginCreatePasswordSuccess = (tokenId: string) =>
  action(CREATE_PASSWORD_SUCCESS, tokenId);

export type LoginCreatePasswordFailure = ReturnType<typeof loginCreatePasswordFailure>;
export const loginCreatePasswordFailure = (tokenId: string, error: Error) =>
  actionError(CREATE_PASSWORD_FAILURE, tokenId, error);

export function loginCreatePassword(password: string, token: string, tokenId: string) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(loginCreatePasswordRequest(tokenId));

      await accountService.createPassword(password, token, tokenId);
      dispatch(loginCreatePasswordSuccess(tokenId));
    } catch (error) {
      dispatch(loginCreatePasswordFailure(tokenId, error));
      throw error;
    }
  };
}
// #endregion

// #region create password with pin
const {
  CREATE_PASSWORD_WITH_PIN_REQUEST,
  CREATE_PASSWORD_WITH_PIN_SUCCESS,
  CREATE_PASSWORD_WITH_PIN_FAILURE,
} = accountConstants;

export type CreatePasswordWithPinRequest = ReturnType<typeof createPasswordWithPinRequest>;
export const createPasswordWithPinRequest = (props: FlipdishApi.ChangePasswordModel) =>
  action(CREATE_PASSWORD_WITH_PIN_REQUEST, props);

export type CreatePasswordWithPinSuccess = ReturnType<typeof createPasswordWithPinSuccess>;
export const createPasswordWithPinSuccess = (props: FlipdishApi.ChangePasswordModel) =>
  action(CREATE_PASSWORD_WITH_PIN_SUCCESS, props);

export type CreatePasswordWithPinFailure = ReturnType<typeof createPasswordWithPinFailure>;
export const createPasswordWithPinFailure = (
  props: FlipdishApi.ChangePasswordModel,
  error: Error
) => actionError(CREATE_PASSWORD_WITH_PIN_FAILURE, props, error);

function createPasswordWithPin(props) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(createPasswordWithPinRequest(props));
      await accountService.createPasswordWithPin(
        props.Pin,
        props.NewPassword,
        props.RecaptchaToken
      );
      dispatch(createPasswordWithPinSuccess(props));
      dispatch(notifySaved());
      dispatch(getAccountDetails());
    } catch (error) {
      dispatch(createPasswordWithPinFailure(props, error));
      if (error.body?.ErrorCode === 99) {
        dispatch(notifyError({ message: 'Invalid_code' }));
      } else if (passwordValidationApiErrors.includes(error.body?.Message)) {
        dispatch(notifyError({ message: error.body?.Message, translate: true }));
      } else {
        dispatch(notifyError({ message: 'Something_went_wrong' }));
      }
    }
  };
}
//#endregion

// #region logout
const { LOGOUT_FAILURE } = accountConstants;

export type LogoutFailure = ReturnType<typeof logoutFailure>;
export const logoutFailure = (error: Error) => actionError(LOGOUT_FAILURE, undefined, error);

async function redirectToAuth0ForLogout() {
  // For the full list of paramters and how logout works, see https://auth0.com/docs/authenticate/login/logout/log-users-out-of-auth0
  const logoutUrl = window.location.origin;
  const { Auth0Domain, Auth0ClientId } = await accountService.getAuth0Config();
  const oidcLogout = `https://${Auth0Domain}/oidc/logout?post_logout_redirect_uri=${logoutUrl}&client_id=${Auth0ClientId}`;
  console.log(`Logging out of Auth0 with redirect to ${oidcLogout}`);
  window.location.assign(oidcLogout);
}

export function logout() {
  return async (dispatch: ThunkDispatch) => {
    try {
      closeConnection();
      await accountService.logout();
      await redirectToAuth0ForLogout();
    } catch (error) {
      dispatch(logoutFailure(error));
      throw error;
    }
  };
}
// #endregion

function skipSignupStep(stepEnum: FlipdishApi.SignupStep.ActionEnum) {
  return (dispatch) => {
    dispatch(request());
    accountService.skipSignupStep(stepEnum).then(
      () => {
        dispatch(success());
        dispatch(getAccountDetails());
        googleAnalytics.sendSuccessfulSignUp();
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: accountConstants.SKIP_SIGNUP_STEP_REQUEST };
  }
  function success() {
    return { type: accountConstants.SKIP_SIGNUP_STEP_SUCCESS };
  }
  function failure(error) {
    return { type: accountConstants.SKIP_SIGNUP_STEP_FAILURE, error };
  }
}

function answerSignupQuestion(answerId: number) {
  return (dispatch) => {
    dispatch(request());
    accountService.answerSignupQuestion(answerId).then(
      () => {
        dispatch(success());
        dispatch(getAccountDetails());
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: accountConstants.ANSWER_SIGNUP_QUESTION_REQUEST };
  }
  function success() {
    return { type: accountConstants.ANSWER_SIGNUP_QUESTION_SUCCESS };
  }
  function failure(error) {
    return { type: accountConstants.ANSWER_SIGNUP_QUESTION_FAILURE, error };
  }
}

function getLocalisedTimeZones() {
  return (dispatch) => {
    dispatch(request());
    accountService.getLocalisedTimeZones().then(
      (data) => {
        dispatch(success(data));
      },
      () => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: accountConstants.GET_LOCALIZED_TIMEZONES_REQUEST };
  }
  function success(timeZones) {
    return {
      type: accountConstants.GET_LOCALIZED_TIMEZONES_SUCCESS,
      timeZones,
    };
  }
  function failure() {
    return {
      type: accountConstants.GET_LOCALIZED_TIMEZONES_FAILURE,
      timeZones: timeZoneDefinitions,
    };
  }
}

//#region GDPR

export const setGdprSelection = (preferences: CookiesPreferences | undefined) => ({
  type: 'ACCOUNT_SET_GDPR',
  payload: preferences,
});

//#endregion

export const accountActions = {
  answerSignupQuestion,
  createPassword: loginCreatePassword,
  createPasswordWithPin,
  getAccountDetails,
  getLocalisedTimeZones,
  getSupportedCountries,
  logout,
  requestPasswordChangePin,
  setGdprSelection,
  setTimeZoneBasedOnLatLng,
  setupSurvey,
  skipSignupStep,
  update,
  updatePassword,
};
